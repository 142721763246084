import styled from 'styled-components';

const Button = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
`;

export default Button;
