import React from 'react';
import styled from 'styled-components';

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Table = styled.table`
width: auto;
border-collapse: collapse;
margin:10px;
`;

const TableRow = styled.tr`
border-bottom: 1px solid #ddd;
&:hover {
  background: #E0E4F3;
  border-radius:20px;
}
`;

const TableCell = styled.td`
text-align: left;
padding: 8px;
padding:15px;
color:#9FA2B4;
`;


const List = ({ loading, items, onClick}) => {

  return (
    <TableContainer>
      {loading ? (
        <>Loading...</>
      ) : (
        <Table>
          <tbody>
            {items.length > 0 ? (
              items.map((item) => (
                <TableRow key={item._id}>
                  <TableCell onClick={()=>onClick(item)}>{item.name}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="6">No items available</TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
      )}
    </TableContainer>
  );
};

export default List;
