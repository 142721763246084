import React from 'react'
import styled from 'styled-components';


const Main = styled.div`
  margin: 20px;
  display: flex;
  background:white;
  flex-direction: column;
  height:100%;
  border-radius:4px;
`;

const Btn = styled.button`
  background-color: #6495ED;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left:20px;
  height:40px;
`;

const FieldContainer = styled.div`
  flex: 0 0 calc(50% - 20px); /* Two fields per row with some space */
  flex-wrap: wrap;
`;

const Field = styled.div`
  margin: 30px 10px 10px 30px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding:10px;
  align-self: flex-start;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;


const VendorForm = ({handleSubmit, formData,setFormData,title}) => {

  return (
    <>

    <Main>
        <h2 style={{margin:'30px'}} >{title}</h2>
        <form style={{display:'flex',flexDirection:'column'}} onSubmit={handleSubmit}>
            <div style={{display:'flex'}}>
          <FieldContainer>
            <Field>
              <Label>Name</Label>
              <Input type="text" name="name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })}/>
            </Field>
            <Field>
              <Label>Email</Label>
              <Input type="text" name="email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })}/>
            </Field>
            <Field>
              <Label>Phone Number</Label>
              <Input type="text" name="phone" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })}/>
            </Field>
            <Field>
              <Label>Type Of Vendor</Label>
              <Input type="text" name="type_of_vendor" value={formData.type_of_vendor} onChange={(e) => setFormData({ ...formData, type_of_vendor: e.target.value })} />
            </Field>
            <Field>
              <Label>Address</Label>
              <Input type="text" name="address" value={formData.address.addressLine} 
              onChange={(e) => setFormData({
                  ...formData,
                  address: { ...formData.address, addressLine: e.target.value }
                })} />
            </Field>
            <Field>
              <Label>Province</Label>
              <Input type="text" name="province" value={formData.address.province}  onChange={(e) => setFormData({
                  ...formData,
                  address: { ...formData.address, province: e.target.value }
                })} />
            </Field>
          </FieldContainer>
          <FieldContainer>
              <Field>
              <Label>City</Label>
              <Input type="text" name="city" value={formData.address.city}  onChange={(e) => setFormData({
                  ...formData,
                  address: { ...formData.address, city: e.target.value }
                })} />
            </Field>
            <Field>
              <Label>Postal Code</Label>
              <Input type="text" name="postal_code" value={formData.address.postalCode}  onChange={(e) => setFormData({
                  ...formData,
                  address: { ...formData.address, postalCode: e.target.value }
                })} />
            </Field>
          </FieldContainer>
          </div>
          <div>
          <Btn type="submit">Submit</Btn>
          </div>
        </form>
        </Main>
        </>
  )
}

export default VendorForm
