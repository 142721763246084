import axios from 'axios';

const saveInvoicePDF = async (pdfFile, folderId, auth) => {
    try {
        const formData = new FormData();
        formData.append('file', pdfFile); // pdfFile is the file object
        
        // Pass folderId as a string in the URL
        const response = await axios.post(
          `/api/v1/folders/${folderId}/upload-invoice`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`, // Do not set Content-Type here
            },
          }
        );

        console.log('Invoice PDF uploaded successfully:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error uploading invoice PDF:', error);
        throw error;
    }
};

export default saveInvoicePDF;
