import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import SearchBar from '../../components/admin/userPageComponents/SearchBar';
import UserForm from '../../components/admin/forms/UserForm';
import Layout from '../../components/Layout/Layout';
import toast from "react-hot-toast";
import profile from "../../assets/man.jpg";
import { Modal } from 'antd';
import { useAuth } from '../../context/auth';

const MainContainer = styled.div`
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  width: 300px;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  background: white;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: auto;
    height: auto;
  }
`;

const RightContainer = styled.div`
  display: flex;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  background: white;
  flex-grow: 1;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: auto;
    height: auto;
  }
`;

const Btn = styled.button`
  background-color: #6495ED;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left: 20px;
  height: 40px;
`;

const TableContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  width: auto;
  border-collapse: collapse;
  margin: 10px;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  &:hover {
    background: #E0E4F3;
    border-radius: 20px;
  }
`;

const TableCell = styled.td`
  text-align: left;
  padding: 8px;
  padding: 15px;
  color: #9FA2B4;
`;

const FormContainer = styled.div`
  display:flex;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    flex-direction:column;
  }
`;

const FieldContainer = styled.div`
  flex: 0 0 calc(50% - 20px); /* Two fields per row with some space */
  flex-wrap: wrap;
`;

const Field = styled.div`
  margin: 30px 10px 10px 30px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding:10px;
  align-self: flex-start;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

const Users = () => {
  const [auth] = useAuth();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [addBtn, setAddBtn] = useState(true);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [query, setQuery] = useState('');
  
    const [formData, setFormData] = useState({
        name: '',
        address:'',
        phone:'',
        work_phone:'',
        email: '',
        work_email:'',
        sin:'',
        branch_locations: [],
        user_code:'',
        role_id:'',
        password:'',
        commission_rate:38,
        mileage_commission_rate:90,
        dob:'',
        license:'',

        // Add other fields as needed
      });

      const [updateFormData, setUpdateFormData] = useState({
        name: '',
        address:'',
        phone:'',
        work_phone:'',
        email: '',
        work_email:'',
        sin:'',
        branch_locations: [],
        user_code:'',
        role_id:'',
        password:'',
        commission_rate:38,
        mileage_commission_rate:90,
        dob:'',
        license:'',
        });
    
      const handleSubmit = async (e) => {
        const url = `/api/v1/users`;
        e.preventDefault();

        try {
            const res = await axios.post(url, {
             formData
            },  {
              headers: {
                'Authorization': `Bearer ${auth?.token}`,
              }
            });
            if (res && res.data.success) {
              setUsers([res.data.user, ...users]);
              setFormData({
                name: '',
                address:'',
                phone:'',
                work_phone:'',
                email: '',
                work_email:'',
                sin:'',
                branch_locations: [],
                user_code:'',
                role_id:'',
                password:'',
                commission_rate:"",
                mileage_commission_rate:"",
                dob:'',
                license:'',
              })
              toast.success(res.data && res.data.message);
            } else {
              toast.error(JSON.stringify(res.data.message));
            }
          } catch (error) {
            console.log(error);
            toast.error("Something went wrong");
          }
      };

    const fetchUsers = async () => {
      try {
          const { data } = await axios.get('/api/v1/users', {
              headers: {
                  'Authorization': `Bearer ${auth?.token}`,
              }
          });
          if (data.success) {
              const filteredUsers = data.users.filter(user => user.status === 0);
              setUsers(filteredUsers);
              setLoading(false);
          }
      } catch (error) {
          toast.error('Error fetching users');
          console.error(error);
      }
  };
  

    const handleSearch = async (event) => {
        setLoading(true)
        event.preventDefault();
        try {
          const {data} = await axios.get(`/api/v1/users/search/${query}`,  {
            headers: {
              'Authorization': `Bearer ${auth?.token}`,
            }
          });
          if(data.success){
            setUsers([data.user, ...users.filter(user => user._id !== data.user._id)]);
            setLoading(false);
            toast.success('user found');
          }else{
            toast.error(data.message);
          }
        } catch (error) {
          setLoading(false)
          const status = error.response.status;
          if (status === 404) {
            toast.error('user not found');
          } 
        }
        
      };

      const handleUpdate = async (e) => {
        e.preventDefault();
    
        const url = `/api/v1/users/${selectedUser._id}`;
    
        // Ensure all fields are present
        console.log("Update Payload:", updateFormData);
    
        try {
            const res = await axios.put(
                url,
                updateFormData, // Corrected to send flat object
                {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                }
            );
    
            if (res && res.data.success) {
                const updatedUser = res.data.user;
                const updatedUsers = users.map((user) =>
                    user._id === updatedUser._id ? updatedUser : user
                );
                setUsers(updatedUsers);
    
                setUpdateFormData({
                    name: "",
                    address: "",
                    phone: "",
                    work_phone: "",
                    email: "",
                    work_email: "",
                    sin: "",
                    branch_locations: [],
                    user_code: "",
                    role_id: "",
                    password: "",
                    commission_rate: 0.0,
                    mileage_commission_rate: 0.0,
                    dob: "",
                    license: "",
                });
                setSelectedUser(res.data.user);
                toast.success(res.data.message);
            } else {
                toast.error(JSON.stringify(res.data.message));
            }
        } catch (error) {
            console.error(error);
            if (error.response?.data?.message) {
                toast.error(JSON.stringify(error.response.data.message));
            } else {
                toast.error("Something went wrong");
            }
        }
    };
    
      const handleDeactivate = async (userId) => {
        const confirmed = window.confirm("Are you sure you want to deactivate this user?");
        if (!confirmed) return;
    
        try {
          const url = `/api/v1/users/${selectedUser._id}`;
            const res = await axios.put(
                url,
                { status: 1 }, // Updating status to 1
                {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                }
            );
    
            if (res && res.data.success) {
                const updatedUser = res.data.user;
                const updatedUsers = users.map((user) =>
                    user._id === updatedUser._id ? updatedUser : user
                );
                setUsers(updatedUsers);
                toast.success(`User ${updatedUser.name} has been deactivated.`);
                fetchUsers();
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to deactivate user.");
        }
    };
    

    const onSelectUser = (user) => {
        setSelectedUser(null);
        setSelectedUser(user);
        setAddBtn(false);
        setUpdateFormData({
            name:user.name,
            address:user.address,
            phone:user.phone,
            work_phone:user.work_phone,
            email:user.email,
            work_email:user.work_email,
            sin:user.sin,
            branch_locations: [],
            user_code:user.user_code,
            role_id:user.role_id._id,
            password:'',
            commission_rate:user.commission_rate,
            mileage_commission_rate:user.mileage_commission_rate,
            dob:user.dob.substring(0, 10),
            license:user.license,

        })
    };

    const url = `/api/v1/auth/forgot-password`;

  // form function
  const handleResetPassword = async (e) => {
    const confirmed = window.confirm(
      "Are you sure you want to reset this password"
    );
    if (!confirmed) return;
    try {
      const email = selectedUser.work_email; 
      const answer = selectedUser.answer; // replace with actual answer from user's profile
      const res = await axios.post(url, {
        email,
        answer,
      });
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);
  
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };


    const handleAddButtonClick = () => {
        setAddBtn(true);
        setSelectedUser(null);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <Layout>
            <MainContainer>
                <LeftContainer>
                    <div style={{ display: 'flex', padding: '20px', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '30px' }}>Users</div>
                        <Btn onClick={handleAddButtonClick}>Add</Btn>
                    </div>
                    <SearchBar handleSearch={handleSearch} query={query} setQuery={setQuery}  />
                    {loading ? (<>Loading...</>):(<>
                        <TableContainer>
                        <Table>
                            <tbody>
                                {users.map((u) => (
                                    <TableRow key={u._id}>
                                        <TableCell
                                            onClick={() => onSelectUser(u)}
                                        >
                                            {u.name}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </tbody>
                        </Table>
                    </TableContainer>
                    </>)}
                </LeftContainer>
                <RightContainer>
                    {addBtn ? (
                        <UserForm handleSubmit={handleSubmit} formData={formData} setFormData={setFormData} title={"Create New User"} />
                    ) : (
                        <>
                        {/* <UserForm user={selectedUser} onDelete={handleDelete} onUpdate={setUpdateBtn} /> */}
                        <div style={{display:'flex',padding:'20px',justifyContent:'space-between',alignItems:'center'}}>
                           <div style={{display:'flex',alignItems:'center',fontSize:'30px'}}>
                              <img style={{borderRadius:'50%', width:'70px' ,height:'70px'}} src={profile} alt="Avatar"/>
                              <div style={{marginLeft:'20px'}}>{selectedUser.name}</div>
                           </div>
                           <div>
                              <Btn onClick={()=>handleDeactivate(selectedUser._id)}>Deactivate</Btn>
                              <Btn onClick={()=>setIsModalOpen(true)}>Update</Btn>
                              <Btn onClick={()=>handleResetPassword(selectedUser._id)}>Reset Password</Btn>
                              <Modal open={isModalOpen} onCancel={()=>setIsModalOpen(false)} footer={null}>
                                   <UserForm title={`Update User`} handleSubmit={handleUpdate} formData={updateFormData} setFormData={setUpdateFormData} />
                              </Modal>
                           </div>
                        </div>
                       <FormContainer>
                          <FieldContainer>
                                <Field>
                                    <Label>Name</Label>
                                    {selectedUser.name}<br/>
                                    {selectedUser.address}<br/>                                    
                                </Field>

                                <Field>
                                    <Label>Phone #</Label>
                                    {selectedUser.phone}
                                </Field>
                                 <Field>
                                    <Label>Work Phone</Label>                                    {selectedUser.work_phone}
                               </Field>
                               <Field>
                                    <Label>Personal Email</Label>
                                    {selectedUser.email}
                              </Field>
                              <Field>
                                    <Label>Work Email</Label>
                                    {selectedUser.work_email}
                             </Field>
                             <Field>
                                    <Label>SIN</Label>
                                    {selectedUser.sin}
                             </Field>
                           </FieldContainer>
                           <FieldContainer>
                                <Field>
                                    <Label>User Code</Label>
                                    {selectedUser.user_code}
                              </Field>
                              < Field>
                                    <Label>Role</Label>
                                  {selectedUser.role_id.name}
                             </Field>
                              <Field>
                                    <Label>Commission Rate</Label>
                                    {selectedUser.commission_rate}
                             </Field>
                              <Field>
                                    <Label>Mileage Commission Rate</Label>
                                    {selectedUser.mileage_commission_rate} 
                             </Field>
                              <Field>
                                    <Label>Date of birth</Label>
                                    {selectedUser.dob.substring(0, 10)}
                             </Field>
                              <Field>
                                    <Label>License #</Label>
                                    {selectedUser.license}
                             </Field>
                             </FieldContainer>
                        </FormContainer>
                        </>
                    )}
                </RightContainer>
            </MainContainer>
        </Layout>
    );
};

export default Users;
