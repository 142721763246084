import React from 'react';

const ReportsIcon = () => {
  return (
    <div>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4107 0.910826C13.1667 1.1549 13.1667 1.54774 13.1667 2.33341V13.1667C13.1667 13.9524 13.1667 14.3453 13.4107 14.5893C13.6548 14.8334 14.0477 14.8334 14.8333 14.8334C15.619 14.8334 16.0118 14.8334 16.2559 14.5893C16.5 14.3453 16.5 13.9524 16.5 13.1667V2.33341C16.5 1.54774 16.5 1.1549 16.2559 0.910826C16.0118 0.666748 15.619 0.666748 14.8333 0.666748C14.0477 0.666748 13.6548 0.666748 13.4107 0.910826Z" fill="white"/>
            <path d="M7.33333 4.83341C7.33333 4.04774 7.33333 3.6549 7.57741 3.41083C7.82149 3.16675 8.21433 3.16675 9 3.16675C9.78567 3.16675 10.1785 3.16675 10.4226 3.41083C10.6667 3.6549 10.6667 4.04774 10.6667 4.83341V13.1667C10.6667 13.9524 10.6667 14.3453 10.4226 14.5893C10.1785 14.8334 9.78567 14.8334 9 14.8334C8.21433 14.8334 7.82149 14.8334 7.57741 14.5893C7.33333 14.3453 7.33333 13.9524 7.33333 13.1667V4.83341Z" fill="white"/>
            <path d="M1.74408 6.74416C1.5 6.98824 1.5 7.38107 1.5 8.16675V13.1667C1.5 13.9524 1.5 14.3453 1.74408 14.5893C1.98816 14.8334 2.38099 14.8334 3.16667 14.8334C3.95234 14.8334 4.34518 14.8334 4.58926 14.5893C4.83333 14.3453 4.83333 13.9524 4.83333 13.1667V8.16675C4.83333 7.38107 4.83333 6.98824 4.58926 6.74416C4.34518 6.50008 3.95234 6.50008 3.16667 6.50008C2.38099 6.50008 1.98816 6.50008 1.74408 6.74416Z" fill="white"/>
            <path d="M1.5 16.7084C1.15482 16.7084 0.875 16.9882 0.875 17.3334C0.875 17.6786 1.15482 17.9584 1.5 17.9584H16.5C16.8452 17.9584 17.125 17.6786 17.125 17.3334C17.125 16.9882 16.8452 16.7084 16.5 16.7084H1.5Z" fill="white"/>
        </svg>
    </div>
  );
};

export default ReportsIcon;