import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: #fff;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: #fff;
`;

const Label = styled.div``;

const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  margin-right: 10px; /* Add margin to create space */
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0069d9;
  }
`;

const CncBtn = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  margin-left: 10px; /* Add margin to create space */
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
  &:disabled {
    background-color: #c3e6cb;
    cursor: not-allowed;
  }
`;

const AddReserveForm = ({ reserve, onSave, onClose }) => {
  const [formData, setFormData] = useState({
    lineOfReserve: "",
    limits: "",
    recommendedReserve: "",
  });

  // Pre-fill the form when editing
  useEffect(() => {
    if (reserve) {
      setFormData({
        lineOfReserve: reserve.lineOfReserve || "",
        limits: reserve.limits || "",
        recommendedReserve: reserve.recommendedReserve || "",
      });
    }
  }, [reserve]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    if (onSave) {
      onSave(formData); // Pass updated data to parent
    }
    if (onClose) {
      onClose(); // Close the modal after saving
    }
  };

  const handleCancel = () => {
    console.log("Cancel Btn clicked"); // Debugging log
    if (onClose) {
      onClose(); // Call the onClose function to close the modal
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Label>Line of Reserve:</Label>
        <input
          type="text"
          name="lineOfReserve"
          value={formData.lineOfReserve}
          onChange={handleChange}
          required
        />

        <Label>Limits:</Label>
        <input
          type="number"
          name="limits"
          value={formData.limits}
          onChange={handleChange}
          required
        />

        <Label>Recommended Reserve:</Label>
        <input
          type="number"
          name="recommendedReserve"
          value={formData.recommendedReserve}
          onChange={handleChange}
          required
        />

        <ButtonContainer>
          <div>
            <Btn type="submit">Save</Btn>
          </div>
          <div>
            <CncBtn type="button" onClick={handleCancel}>
              Cancel
            </CncBtn>
          </div>
        </ButtonContainer>
      </Container>
    </form>
  );
};

export default AddReserveForm;
