import React from 'react'
import styled from 'styled-components';

const Btn = styled.button`
  background-color: #6495ED;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left:20px;
  height:40px;
`;

const FieldContainer = styled.div`
  flex: 0 0 calc(50% - 20px); /* Two fields per row with some space */
  flex-wrap: wrap;
`;

const Field = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding:10px;
  align-self: flex-start;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

const LocationForm = ({ handleSubmit, formData, setFormData, title }) => {
   
  return (
    <FieldContainer>
        <h2>{title}</h2>
        <form onSubmit={handleSubmit}>
            <Field>
              <Label>Location Name</Label>
              <Input type="text" name="location_name" value={formData.location_name} onChange={(e) => setFormData({ ...formData, location_name: e.target.value })} />
            </Field>
            <Field>
              <Label>Address</Label>
              <Input type="text" name="address" value={formData.address} onChange={(e) => setFormData({ ...formData, address: e.target.value })} />
            </Field>
            <Field>
              <Label>Manager</Label>
              <Input type="text" name="manager_id" value={formData.manager_id} onChange={(e) => setFormData({ ...formData, manager_id: e.target.value })} />
            </Field>
            <Field>
              <Label>Office Name</Label>
              <Input type="text" name="office_name" value={formData.office_name} onChange={(e) => setFormData({ ...formData, office_name: e.target.value })} />
            </Field>
          <div>
          <Btn type="submit">Submit</Btn>
          </div>
        </form>
        </FieldContainer>
  )
}

export default LocationForm
