import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../common/Button';
import toast from 'react-hot-toast';
import axios from 'axios';
import * as Yup from 'yup';
import { useAuth } from '../../../context/auth';


const BtnContainer = styled.div`
  margin: 30px;
  display: flex;
  justify-content:space-between;
`;
const FieldContainer = styled.div`
  flex: 0 0 calc(50% - 20px); /* Two fields per row with some space */
  flex-wrap: wrap;
`;

const Field = styled.div`
  margin: 30px 10px 10px 30px;
  display: flex;
  flex-direction: column;
`;

const SelectInput = styled.select`
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
`;


const Option = styled.option`
  font-size: 14px;
`;


const ExaminerSelector = ({formData, setFormData, setStep}) => {

    const [examiners,setExaminers]=useState([]);
    const [loading, setLoading]=useState(true)
    const [errors, setErrors] = useState({});
    const [auth] = useAuth();

    // Define Yup validation schema
const ValidationSchema = Yup.object().shape({
  examinerId: Yup.string().required('Examiner is required'),
});

    const getExaminers= async()=>{
      if (formData.clients.length > 0) {
        const clientIds = formData.clients.map(client => client._id);
        try {
          const response = await axios.post('/api/v1/clients/contacts/examiners', { clientIds }, {
            headers: {
              'Authorization': `Bearer ${auth?.token}`,
            }
          });

          if(response.data.success){
            setExaminers(response.data.examiners);
            setLoading(false)
          }
        } catch (error) {
          console.error('Error fetching examiners:', error);
          toast.error('something went wrong fetching exminers');
        }
      }

    }

    useEffect(()=>{
      getExaminers();
    },[])

    const handleNext = async() => {
      try {
        await ValidationSchema.validate(formData, { abortEarly: false });
        setStep((prevStep) => prevStep + 1);
      } catch (err) {
        const validationErrors = {};
        err?.inner?.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      }
    };
  
    const handlePrevious = () => {
      setStep((prevStep) => prevStep - 1);
    };

   

  return (
    <>
        <h2>Add Examiner</h2>
          <FieldContainer>
          <Field>
            <SelectInput 
              id="examinerId" 
              name="examinerId" 
              value={formData.examinerId} 
              onChange={(e) => setFormData({ ...formData, examinerId: e.target.value })}>
             <Option value="">Select an examiner</Option>
             {examiners.map(examiner => (
             <Option 
             key={examiner._id} 
             value={examiner._id}>
              {examiner.name}
              </Option>
            ))}
             </SelectInput>
             {errors.examinerId && <div>{errors.examinerId}</div>}
            </Field>
          </FieldContainer>
          <BtnContainer>
          <Button type="button" onClick={handlePrevious}>
            Previous
          </Button>
          <Button type="button" onClick={handleNext}>
              Next
            </Button>
          </BtnContainer>
        </>
  )
}

export default ExaminerSelector
