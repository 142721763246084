import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import deleteIcon from "../../../assets/delete.png";
import writeIcon from "../../../assets/Write.png";
import toast from "react-hot-toast";
import { useAuth } from "../../../context/auth";

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 15px;
  position: relative;
  text-align: left;
  background: #e0e4f3;
  color: #444444;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  letter-spacing: 0.20000000298023224px;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
  text-align: left;
  padding: 8px;
  padding: 15px;
  white-space: nowrap;
  color: #9fa2b4;
`;

const ActionIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${deleteIcon}); // Use the delete icon image
  background-size: cover;
  cursor: pointer;
`;

const EditIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${writeIcon}); // Use the delete icon image
  background-size: cover;
  cursor: pointer;
`;

const TemplateList = ({ templates, setTemplates }) => {
  const [auth] = useAuth();

  const handleDeleteClick = async (id) => {
    // Handle delete action
    try {
      await axios.delete(`/api/v1/templates/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setTemplates((prevTemplates) =>
        prevTemplates.filter((template) => template._id !== id)
      );
      toast.success("template deleted successfully");
    } catch (error) {
      toast.error("some thing went wromf");
    }
    console.log("Delete clicked for id:", id);
  };
  const handleEditClick = (id) => {
    // Handle delete action
    console.log("Delete clicked for id:", id);
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <TableHeader style={{ width: "90%" }}>Template Name</TableHeader>
              <TableHeader>File Name</TableHeader>
              <TableHeader>Action</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {templates.map((template) => (
              <TableRow key={template._id}>
                <TableCell component="th" scope="row">
                  {template.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {template.file.fileName}
                </TableCell>
                <TableCell align="right">
                  {/* <EditIcon onClick={()=>handleEditClick(template._id)}/> */}
                  <ActionIcon onClick={() => handleDeleteClick(template._id)} />
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TemplateList;
