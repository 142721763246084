import React from 'react';
import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

const StyledModal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 8px;
    width: auto;
  }
`;

const Modal = ({ open, onClose, children }) => (
  <StyledModal open={open} onCancel={onClose} footer={null}>
    {children}
  </StyledModal>
);

export default Modal;
