// SearchBar.js
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import searchIcon from '../../../assets/search.png';
import toast from "react-hot-toast";


const SearchBox = styled.div`
  display: flex;
  height:40px;
  width:auto;
`;

const InputSearch = styled.input`
  width: 180px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  padding: 10px;
`;

const SeacrchBtn = styled.button`
width: 40px;
margin-left:5px;
margin-right:5px;
background-image: url(${searchIcon}); 
background-repeat: no-repeat;
background-size: contain;
cursor: pointer;
border:none;

`;
const SearchBar = ({handleSearch,query, setQuery}) => {
  
    const handleChange = (event) => {
      setQuery(event.target.value);
    };

    return (
      <form onSubmit={handleSearch}>
        <SearchBox>
          <InputSearch type="text" value={query} onChange={handleChange} placeholder="Search for user" />
          <SeacrchBtn type="submit"/>
        </SearchBox>
      </form>
    );
  };

  export default SearchBar