// import React, { useState,useEffect } from 'react';
// import styled from 'styled-components';
// import toast from 'react-hot-toast';
// import axios from 'axios';

// const FieldContainer = styled.div`
//   flex: 0 0 calc(50% - 20px); /* Two fields per row with some space */
//   flex-wrap: wrap;
// `;

// const Field = styled.div`
//   margin: 30px 10px 10px 30px;
//   display: flex;
//   flex-direction: column;
// `;

// const Label = styled.label`
//   padding:10px;
//   align-self: flex-start;
// `;

// const Input = styled.input`
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 6px;
//   outline: none;
// `;

// const SelectInput = styled.select`
//   padding: 8px;
//   font-size: 14px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   outline: none;
// `;

// const Option = styled.option`
//   font-size: 14px;
// `;

// const BrokerSelector = ({formData, setFormData, setStep}) => {

//     const [brokers,setBrokers]=useState([{_id:'3232',name:'asdas'}]);
//     const [loading, setLoading]=useState(true)

//     const getBrokers= async()=>{
//         try {
//           const response = await axios.get('/api/v1/clients/');
//           if(response.data.success){
//             setBrokers(response.data.clients);
//             setLoading(false)
//           }
//         } catch (error) {
//           console.error('Error fetching brokers:', error);
//           toast.error('something went wrong fetching exminers');
//         }

//     }

//     useEffect(()=>{
//       getBrokers();
//     },[])

//     const handlePrevious = () => {
//       setStep((prevStep) => prevStep - 1);
//     };

//   return (
//     <>
//         <h2>Add Broker</h2>
//           <FieldContainer>
//           <Field>
//             <SelectInput
//               id="brokerId"
//               name="brokerId"
//               value={formData.brokerId}
//               onChange={(e) => setFormData({ ...formData, brokerId: e.target.value })}>
//              <Option value="">Select a broker</Option>
//              {brokers.map(broker => (
//              <Option
//              key={broker._id}
//              value={broker._id}>
//               {broker.name}
//               </Option>
//             ))}
//              </SelectInput>
//             </Field>
//           </FieldContainer>

//         </>
//   )
// }

// export default BrokerSelector

import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import { useAuth } from "../../../context/auth";

const BtnContainer = styled.div`
  margin: 30px;
  display: flex;
  justify-content: space-between;
`;

const ClientSelector = ({ formData, setFormData, setStep }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [client, setClient] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [auth] = useAuth();

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (searchQuery) {
      axios
        .get(`/api/v1/clients/search/${searchQuery}?includeContacts=false`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((response) => {
          setClient(response.data.clients[0]); // Assuming you need the first match
          if (
            response.data.clients[0]?.rate !== undefined &&
            formData.clients.length === 0
          ) {
          }
        })
        .catch((error) =>
          toast.error(error?.response?.data?.message || "Error during search")
        );
    } else {
      setClient(null);
    }
  }, [searchQuery, formData.clients]);
  

  const handleAddClient = () => {
    if (selectedClient) {
      setFormData({
        ...formData,
        brokerId: selectedClient,
      });
      setSelectedClient(null);
    }
  };

  const handleRemoveClient = () => {
    setFormData({
      ...formData,
      brokerId: "",
    });
  };

  return (
    <div>
      <h2>Add Broker</h2>
      <TextField
        label="Search Broker"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
      />
      {client && (
        <List>
          <ListItem onClick={() => setSelectedClient(client)}>
            <ListItemText primary={client.name} />
          </ListItem>
        </List>
      )}
      <Dialog open={!!selectedClient} onClose={() => setSelectedClient(null)}>
        <DialogTitle>Add Broker</DialogTitle>
        <DialogContent>
          {selectedClient && <Typography>{selectedClient.name}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedClient(null)}>Cancel</Button>
          <Button onClick={handleAddClient} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <List>
        {formData.brokerId ? (
          <>
            <ListItem>
              <ListItemText primary={`Broker: ${formData.brokerId.name}`} />
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleRemoveClient()}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          </>
        ) : (
          <>no broker selected</>
        )}
      </List>
      {errors.clients && <div>{errors.clients}</div>}
    </div>
  );
};

export default ClientSelector;
