import React,{useState} from 'react';
import styled from 'styled-components';
import Layout from '../../components/Layout/Layout';
import ActivityCodes from '../../components/admin/ActivityCodes';
import LossTypes from '../../components/admin/LossTypes';
import ClaimTypes from '../../components/admin/ClaimTypes';
import PolicyTypes from '../../components/admin/PolicyTypes';


const Container = styled.div`
  display: flex;
  flex-grow:1;
  @media only screen and (max-width: 768px) {
    flex-direction:column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  min-height:100vh;
  width:300px;
  margin:10px;
  padding:20px;
  border-radius:8px;
  background:white;
  flex-direction:column;
  @media only screen and (max-width: 768px) {
    width:auto;
  }
`;


const RightContainer = styled.div`
  display: flex;
  padding:20px;
  margin:10px;
  flex-direction:column;
  background:white;
  border-radius:8px;
  flex-grow:1;
`;
const TableContainer = styled.div`
  width:auto;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  width: auto;
  border-collapse: collapse;
  margin:10px;
`;

const TableRow = styled.tr`
    border-bottom: 1px solid #ddd;
    &:hover {
      background: #E0E4F3;
      border-radius:20px;
    }
`;


const TableCell = styled.td`
  text-align: left;
  padding: 8px;
  padding:15px;
  color:#9FA2B4;

`;





const SystemFeilds = () => {

  const [selectedLink, setSelectedLink] = useState('activity-codes');
     
  return (
    <Layout>
    <Container>
      <LeftContainer>
      <div style={{display:'flex',padding:'20px',justifyContent:'space-between'}}>
      <div style={{display:'flex',alignItems:'center',fontSize:'30px'}}>Feilds</div>
      </div>
          <TableContainer>
            <Table>
          <tbody>
            <TableRow >
              <TableCell onClick={() => setSelectedLink('activity-codes')} component="th" scope="row">Activity Code</TableCell>
            </TableRow>
            <TableRow >
              <TableCell onClick={() => setSelectedLink('loss-types')} component="th" scope="row">Type of Loss</TableCell>
            </TableRow>
            <TableRow >
              <TableCell onClick={() => setSelectedLink('claim-types')} component="th" scope="row">Type of Claims</TableCell>
            </TableRow>
            <TableRow >
              <TableCell onClick={() => setSelectedLink('policy-types')}component="th" scope="row">Type of Policy</TableCell>
            </TableRow>

          </tbody>
        </Table>
          </TableContainer>
      </LeftContainer>
      <RightContainer>
        {selectedLink === 'activity-codes' &&  <ActivityCodes/>}
        {selectedLink === 'loss-types' &&  <LossTypes/>}
        {selectedLink === 'claim-types' &&  <ClaimTypes/>}
        {selectedLink === 'policy-types' &&  <PolicyTypes/>}
      </RightContainer>
        
    </Container>
    </Layout>
  )
}

export default SystemFeilds

