import React, { useState } from 'react';
import styled from 'styled-components';
import useRoles from '../../../hooks/useRoles';
import useLocations from '../../../hooks/useLocations';

const Btn = styled.button`
  background-color: #6495ED;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left: 20px;
  height: 40px;
`;

const FieldContainer = styled.div`
  flex: 0 0 calc(50% - 20px);
  flex-wrap: wrap;
`;

const Field = styled.div`
  margin: 30px 10px 10px 30px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding: 10px;
  align-self: flex-start;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

const SelectInput = styled.select`
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
`;

const Option = styled.option`
  font-size: 14px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: #6495ED;
  font-size: 1.5em;
  cursor: pointer;
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

const UserForm = ({ handleSubmit, formData, setFormData, title }) => {
  const roles = useRoles();
  const locations = useLocations();
  const [branchLocations, setBranchLocations] = useState([{ id: 0, value: formData.branch_locations || "" }]);

  const handleBranchChange = (index, value) => {
    const newBranchLocations = [...branchLocations];
    newBranchLocations[index].value = value;
    setBranchLocations(newBranchLocations);
    setFormData({
      ...formData,
      branch_locations: newBranchLocations.map(branch => branch.value),
    });
  };

  const addBranchLocation = () => {
    setBranchLocations([...branchLocations, { id: branchLocations.length, value: "" }]);
  };

  const removeBranchLocation = (index) => {
    const newBranchLocations = branchLocations.filter((_, i) => i !== index);
    setBranchLocations(newBranchLocations);
    setFormData({
      ...formData,
      branch_locations: newBranchLocations.map(branch => branch.value),
    });
  };

  return (
    <>
      <h2>{title}</h2>
      <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
        <div style={{ display: 'flex' }}>
          <FieldContainer>
            <Field>
              <Label htmlFor="role">Role</Label>
              <SelectInput
                id="role_id"
                name="role_id"
                value={formData.role_id}
                onChange={(e) => setFormData({ ...formData, role_id: e.target.value })}
              >
                <Option value="">Select Role</Option>
                {roles.map(role => (
                  <Option key={role._id} value={role._id}>{role.name}</Option>
                ))}
              </SelectInput>
            </Field>
            <Field>
              <Label>Name</Label>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
            </Field>
            <Field>
              <Label>Address</Label>
              <Input
                type="text"
                name="address"
                value={formData.address}
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
              />
            </Field>
            <Field>
              <Label>Phone #</Label>
              <Input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              />
            </Field>
            <Field>
              <Label>Work Phone</Label>
              <Input
                type="text"
                name="work_phone"
                value={formData.work_phone}
                onChange={(e) => setFormData({ ...formData, work_phone: e.target.value })}
              />
            </Field>
            <Field>
              <Label>Personal Email</Label>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
            </Field>
            <Field>
              <Label>Work Email</Label>
              <Input
                type="email"
                name="work_email"
                value={formData.work_email}
                onChange={(e) => setFormData({ ...formData, work_email: e.target.value })}
              />
            </Field>
           
            {branchLocations.map((branch, index) => (
              <Field key={branch.id} style={{ display: 'flex', alignItems: 'center' }}>
                <Label>Branch Location {index + 1}</Label>
                <SelectInput
                  value={branch.value}
                  onChange={(e) => handleBranchChange(index, e.target.value)}
                  required
                >
                  <Option value="">Select Location</Option>
                  {locations.map(location => (
                    <Option key={location._id} value={location._id}>{location.location_name}</Option>
                  ))}
                </SelectInput>
                {branchLocations.length > 1 && (
                  <IconButton onClick={() => removeBranchLocation(index)}>-</IconButton>
                )}
                {index === branchLocations.length - 1 && (
                  <IconButton onClick={addBranchLocation}>+</IconButton>
                )}
              </Field>
            ))}
          </FieldContainer>
          <FieldContainer>
            <Field>
              <Label>User Code</Label>
              <Input
                type="text"
                name="user_code"
                value={formData.user_code}
                onChange={(e) => setFormData({ ...formData, user_code: e.target.value })}
              />
            </Field>
            <Field>
              <Label>Commission Rate</Label>
              <Input
                type="number"
                name="commission_rate"
                value={formData.commission_rate}
                onChange={(e) => setFormData({ ...formData, commission_rate: e.target.value })}
              />
            </Field>
            <Field>
              <Label>Mileage Commission Rate</Label>
              <Input
                type="number"
                name="mileage_commission_rate"
                value={formData.mileage_commission_rate}
                onChange={(e) => setFormData({ ...formData, mileage_commission_rate: e.target.value })}
              />
            </Field>
            <Field>
              <Label>Date of Birth</Label>
              <Input
                type="date"
                name="dob"
                value={formData.dob}
                onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
              />
            </Field>
            <Field>
              <Label>License #</Label>
              <Input
                type="text"
                name="license"
                value={formData.license}
                onChange={(e) => setFormData({ ...formData, license: e.target.value })}
              />
            </Field>
            <Field>
              <Label>SIN</Label>
              <Input
                type="text"
                name="sin"
                value={formData.sin}
                onChange={(e) => setFormData({ ...formData, sin: e.target.value })}
              />
            </Field>
          
            </FieldContainer>
        </div>
        <div>
          <Btn type="submit">Submit</Btn>
        </div>
      </form>
    </>
  );
};

export default UserForm;
