import React from 'react';

const ClaimsIcon = () => {
  return (
    <div>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7657 13.751C13.9116 13.6373 14.0438 13.505 14.3084 13.2405L17.6062 9.94262C17.6859 9.86289 17.6495 9.72557 17.5429 9.68861C17.1537 9.55356 16.6473 9.3 16.1736 8.82632C15.6999 8.35264 15.4464 7.84626 15.3113 7.45699C15.2744 7.35047 15.137 7.31398 15.0573 7.39371L11.7595 10.6915C11.4949 10.9561 11.3627 11.0883 11.2489 11.2342C11.1147 11.4062 10.9997 11.5924 10.9058 11.7893C10.8263 11.9563 10.7671 12.1337 10.6488 12.4886L10.4959 12.9472L10.2529 13.6764L10.025 14.3602C9.96674 14.5348 10.0122 14.7274 10.1424 14.8575C10.2726 14.9877 10.4651 15.0332 10.6398 14.975L11.3235 14.7471L12.0527 14.504L12.5113 14.3511C12.8662 14.2328 13.0437 14.1737 13.2106 14.0941C13.4076 14.0002 13.5937 13.8852 13.7657 13.751Z" fill="white"/>
            <path d="M18.6388 8.91007C19.3426 8.2062 19.3426 7.06501 18.6388 6.36115C17.9349 5.65729 16.7937 5.65729 16.0898 6.36115L15.9838 6.46717C15.8815 6.56948 15.8352 6.71239 15.8607 6.8548C15.8768 6.94439 15.9066 7.07536 15.9608 7.23165C16.0693 7.5442 16.274 7.95449 16.6597 8.34018C17.0454 8.72588 17.4557 8.93065 17.7683 9.03909C17.9246 9.09331 18.0555 9.12312 18.1451 9.1392C18.2875 9.16476 18.4304 9.1184 18.5327 9.01609L18.6388 8.91007Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.47631 2.64306C2.5 3.61937 2.5 5.19072 2.5 8.33341V11.6667C2.5 14.8094 2.5 16.3808 3.47631 17.3571C4.45262 18.3334 6.02397 18.3334 9.16667 18.3334H10.8333C13.976 18.3334 15.5474 18.3334 16.5237 17.3571C17.4843 16.3965 17.4997 14.8597 17.5 11.8168L15.1514 14.1654C14.9266 14.3904 14.7425 14.5746 14.5346 14.7368C14.2909 14.9268 14.0273 15.0897 13.7484 15.2227C13.5104 15.3361 13.2632 15.4184 12.9615 15.5189L11.0351 16.161C10.4113 16.3689 9.7235 16.2066 9.25852 15.7416C8.79355 15.2766 8.6312 14.5889 8.83914 13.965L9.06705 13.2813L9.46298 12.0935L9.48124 12.0387C9.58171 11.7369 9.66399 11.4898 9.77745 11.2517C9.91037 10.9728 10.0733 10.7092 10.2633 10.4656C10.4255 10.2576 10.6098 10.0735 10.8347 9.84867L14.1734 6.50998L15.1 5.58345L15.206 5.47743C15.8022 4.88126 16.5836 4.58326 17.365 4.58342C17.2389 3.72462 16.9951 3.11448 16.5237 2.64306C15.5474 1.66675 13.976 1.66675 10.8333 1.66675H9.16667C6.02397 1.66675 4.45262 1.66675 3.47631 2.64306ZM6.04167 7.50008C6.04167 7.1549 6.32149 6.87508 6.66667 6.87508H12.0833C12.4285 6.87508 12.7083 7.1549 12.7083 7.50008C12.7083 7.84526 12.4285 8.12508 12.0833 8.12508H6.66667C6.32149 8.12508 6.04167 7.84526 6.04167 7.50008ZM6.04167 10.8334C6.04167 10.4882 6.32149 10.2084 6.66667 10.2084H8.75C9.09518 10.2084 9.375 10.4882 9.375 10.8334C9.375 11.1786 9.09518 11.4584 8.75 11.4584H6.66667C6.32149 11.4584 6.04167 11.1786 6.04167 10.8334ZM6.04167 14.1667C6.04167 13.8216 6.32149 13.5417 6.66667 13.5417H7.91667C8.26184 13.5417 8.54167 13.8216 8.54167 14.1667C8.54167 14.5119 8.26184 14.7917 7.91667 14.7917H6.66667C6.32149 14.7917 6.04167 14.5119 6.04167 14.1667Z" fill="white"/>
        </svg>
    </div>
  );
};

export default ClaimsIcon;
