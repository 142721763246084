import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;

const Label = styled.label`
  margin-top: 10px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 80px;
`;

const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0069d9;
  }
`;

const CncBtn = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
  &:disabled {
    background-color: #c3e6cb;
    cursor: not-allowed;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const RequestPaymentForm = ({ reserve, onSave, onClose }) => {
  const [formData, setFormData] = useState({
    reserveId: reserve.id,
    party: "",
    amountRequested: "",
    notes: "",
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.post(
      `/api/v1/reserves/${reserve.claimId}/${reserve.id}/payments`,
      formData
    );
    const newPayment = response.data;
    onSave(newPayment); // Pass the new payment back to parent
  } catch (error) {
    console.error("Failed to request payment:", error);
  }
};

  

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Label htmlFor="party">Party:</Label>
        <Input
          id="party"
          type="text"
          name="party"
          value={formData.party}
          onChange={handleChange}
          required
          aria-label="Enter party name"
        />
        <Label htmlFor="amountRequested">Amount Requested:</Label>
        <Input
          id="amountRequested"
          type="number"
          name="amountRequested"
          value={formData.amountRequested}
          onChange={handleChange}
          required
          aria-label="Enter amount requested"
        />
        <Label htmlFor="notes">Notes:</Label>
        <TextArea
          id="notes"
          name="notes"
          value={formData.notes}
          onChange={handleChange}
          aria-label="Enter notes"
        ></TextArea>
        {error && <Error>{error}</Error>}
        <ButtonContainer>
          <Btn type="submit">Request Payment</Btn>
          <CncBtn type="button" onClick={onClose}>
            Cancel
          </CncBtn>
        </ButtonContainer>
      </Container>
    </form>
  );
};

export default RequestPaymentForm;
