import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../common/Button";
import { TextField } from "@mui/material";
import { Col, Row } from "reactstrap";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";
import { useLocation, useNavigate } from 'react-router-dom';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Label = styled.label`
  margin: 5px 0;
`;

const AddTaskForm = ({ formData, setFormData, handleSubmit, title }) => {
  const [auth] = useAuth();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const taskData = location.state?.taskData; // Retrieve taskData from navigation state
  
  // Initialize formData with taskData only if formData is not set
  useEffect(() => {
    if (taskData && (!formData || Object.keys(formData).length === 0)) {
      setFormData({
        dueDate: taskData.dueDate || "",
        description: taskData.description || "",
        userCode: taskData.userCode || "",
      });
      // Clear location.state after initializing formData to avoid repetition
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [taskData, formData, setFormData, navigate, location.pathname]);

  // Handle input change for form data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Fetch users from the API
  const fetchUsers = async () => {
    try {
      const { data } = await axios.get(`/api/v1/users`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (data.success) {
        setUsers(data.users);
      }
    } catch (error) {
      toast.error("Error fetching users");
      console.error(error);
    }
  };

  // Handle user selection
  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setFormData((prevData) => ({
      ...prevData,
      userCode: user?.user_code,
    }));
  };

  // Filter users based on the search query
  useEffect(() => {
    if (searchQuery) {
      const filtered = users.filter((user) =>
        user.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }
  }, [searchQuery, users]);

  // Fetch users when the component mounts
  useEffect(() => {
    fetchUsers();
  }, []);

  // Utility function to chunk array for display
  const chunkArray = (array, chunkSize) => {
    const results = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      results.push(array.slice(i, i + chunkSize));
    }
    return results;
  };

  // Reset form data
  const resetForm = () => {
    setFormData({
      dueDate: "",
      description: "",
      userCode: "auth.user.user_code",
    });
    setFilteredUsers([]);
    setSearchQuery("");
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e, resetForm)}>
      <Row>
        <Col md={6}>
          <div className="d-flex align-items-center gap-3 w-100">
            <TextField
              label="Search User"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              fullWidth
            />
          </div>
        </Col>
      </Row>

      <Row className="my-3">
        <Col md={12}>
          {filteredUsers.length > 0 ? (
            <div>
              {chunkArray(filteredUsers, 4).map((userRow, rowIndex) => (
                <Row key={rowIndex} className="mb-2">
                  {userRow.map((user) => (
                    <Col md={6} key={user.id} className="mb-2">
                      <div
                        className="border p-2"
                        onClick={() => handleUserSelect(user)}
                        style={{ cursor: "pointer" }}
                      >
                        {user.name}
                      </div>
                    </Col>
                  ))}
                </Row>
              ))}
            </div>
          ) : (
            searchQuery && <p>No users found</p>
          )}
        </Col>
      </Row>

      <h2>{title}</h2>

      <Label>User Code</Label>
      <Input
        type="text"
        name="userCode"
        value={formData.userCode || auth.user.user_code}
        disabled
      />

      <Label>Due Date</Label>
      <Input
        type="date"
        name="dueDate"
        value={formData.dueDate}
        onChange={handleChange}
        required
      />
      
      <Label>Description</Label>
      <Input
        type="text"
        name="description"
        value={formData.description}
        onChange={handleChange}
        required
      />

      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default AddTaskForm;
