import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../common/Button";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { Col, Row } from "reactstrap";
import toast from "react-hot-toast";
import { TextField, IconButton, MenuItem, Select } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Label = styled.label`
  margin: 5px 0;
`;

const AddPartyForm = ({ formData, setFormData, handleSubmit, title }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [auth] = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  const initializeFormData = () => ({
    name: "",
    company: "",
    claimRole: "",
    phone: [{ number: "", label: "Cell" }],  // Add label to each phone number
    email: [{ address: "", label: "Primary" }],  // Add label to each email
    address: "",
    city: "",
    province: "",
    postal_code: ""
  });

  useEffect(() => {
    setFormData(initializeFormData());
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const chunkArray = (array, chunkSize) => {
    const results = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      results.push(array.slice(i, i + chunkSize));
    }
    return results;
  };

  const handleDynamicChange = (e, index, field, subfield) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const updatedField = [...prevData[field]];
      updatedField[index][subfield] = value;
      return { ...prevData, [field]: updatedField };
    });
  };
  
  const addField = (field) => {
    const newItem = field === "phone" ? { label: "Cell", number: "" } : { label: "Primary", address: "" };
    setFormData((prevData) => ({
      ...prevData,
      [field]: [...prevData[field], newItem],
    }));
  };
  

  const removeField = (index, field) => {
    setFormData((prevData) => {
      const updatedField = [...prevData[field]];
      updatedField.splice(index, 1);
      return { ...prevData, [field]: updatedField };
    });
  };
  

  const fetchAllUsers = async () => {
    try {
      const { data } = await axios.get(`/api/v1/searchParties`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      console.log(data, "data")
      if (data.success) {
        setAllUsers([...data.results.users, ...data.results.contacts]);
      }
    } catch (error) {
      toast.error("Error fetching users");
      console.error(error);
    }
  };

  // Handle user selection
  const handleUserSelect = (user) => {
    console.log("User selected:", user.user_code);
    setSelectedUser(user);
    setFormData((prevData) => ({
      ...prevData,
      name: user?.name || "",
      company: user?.company || "",
      claimRole: user?.role|| "",
      phone: Array.isArray(user?.phone) ? user.phone : [{ number: user.phone || "", label: "Cell" }],
      email: Array.isArray(user?.email) ? user.email : [{ address: user.email || "", label: "Primary" }],
      address: user?.address || "",
      city: user?.city || "",
      province: user?.province || "",
      postal_code: user?.postal_code || "",
    }));
    
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query) {
      const filtered = allUsers.filter((user) =>
        user.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const resetForm = () => {
    setFormData({
      name: "",
      company:"",
      claimRole: "",
      phone: "",
      email: "",
      address: "",
      city: "",
      province: "",
      postal_code: "",
      userCode: ""
    });
    setFilteredUsers([]);
    setSearchQuery("");
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e, resetForm)}>
      <Row>
        <Col md={6}>
          <div className="d-flex align-items-center gap-3 w-100">
            <TextField
              label="Search User"
              value={searchQuery}
              onChange={handleSearchChange}
              fullWidth
            />
          </div>
        </Col>
      </Row>

      <Row className="my-3">
        <Col md={12}>
          {filteredUsers.length > 0 ? (
            <div>
              {chunkArray(filteredUsers, 4).map((userRow, rowIndex) => (
                <Row key={rowIndex} className="mb-2">
                  {userRow.map((user) => (
                    <Col md={6} key={user.id} className="mb-2">
                      <div
                        className="border p-2"
                        onClick={() => handleUserSelect(user)}
                        style={{ cursor: "pointer" }}
                      >
                        {user.name}
                      </div>
                    </Col>
                  ))}
                </Row>
              ))}
            </div>
          ) : (
            searchQuery && <p>No users found</p>
          )}
        </Col>
      </Row>

      <h2>{title}</h2>
      <Label>Name</Label>
      <Input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
      />

      {/* Dynamic Phone Fields with Label Dropdown */}
      <Label>Company</Label>
      <Input
        type="text"
        name="company"
        value={formData.company}
        onChange={handleChange}
        
      />

      {/* Dynamic Phone Fields with Label Dropdown */}
      <Label>Phone</Label>
      {Array.isArray(formData.phone) && formData.phone.map((phoneObj, index) => (
  <div key={index} className="d-flex align-items-center mb-2">
    <Select
      value={phoneObj.label}
      onChange={(e) => handleDynamicChange(e, index, "phone", "label")}
      style={{ marginRight: "10px" }}
    >
      <MenuItem value="Cell">Cell</MenuItem>
      <MenuItem value="Home">Home</MenuItem>
      <MenuItem value="Work">Work</MenuItem>
      
    </Select>
    <Input
      type="text"
      value={phoneObj.number}
      onChange={(e) => handleDynamicChange(e, index, "phone", "number")}
    />
    <IconButton onClick={() => removeField(index, "phone")} disabled={formData.phone.length === 1}>
      <Remove />
    </IconButton>
    {index === formData.phone.length - 1 && (
      <IconButton onClick={() => addField("phone")}>
        <Add />
      </IconButton>
    )}
  </div>
))}

{/* Dynamic Email Fields with Label Dropdown */}
<Label>Email</Label>
{Array.isArray(formData.email) && formData.email.map((emailObj, index) => (
  <div key={index} className="d-flex align-items-center mb-2">
    <Select
      value={emailObj.label}
      onChange={(e) => handleDynamicChange(e, index, "email", "label")}
      style={{ marginRight: "10px" }}
    >
      <MenuItem value="Primary">Primary</MenuItem>
      <MenuItem value="Secondary">Secondary</MenuItem>
      
    </Select>
    <Input
      type="email"
      value={emailObj.address}
      onChange={(e) => handleDynamicChange(e, index, "email", "address")}
    />
    <IconButton onClick={() => removeField(index, "email")} disabled={formData.email.length === 1}>
      <Remove />
    </IconButton>
    {index === formData.email.length - 1 && (
      <IconButton onClick={() => addField("email")}>
        <Add />
      </IconButton>
    )}
  </div>
))}


      <Label>Claim Role</Label>
      <Input
        type="text"
        name="claimRole"
        value={formData.claimRole}
        onChange={handleChange}
        required
      />
      <Label>Address</Label>
      <Input
        type="text"
        name="address"
        value={formData.address}
        onChange={handleChange}
      />
      <Label>City</Label>
      <Input
        type="text"
        name="city"
        value={formData.city}
        onChange={handleChange}
      />
      <Label>Province</Label>
      <Input
        type="text"
        name="province"
        value={formData.province}
        onChange={handleChange}
      />
      <Label>Postal Code</Label>
      <Input
        type="text"
        name="postal_code"
        value={formData.postal_code}
        onChange={handleChange}
      />
      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default AddPartyForm;
