import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { Col, Row } from "reactstrap";
import mapsIcon from "../../assets/mapsicon.png"; // Importing the icon
import ClaimDetailsForm from "./claimDetailsForm"; // Import the form component

const Btn = styled.button`
  padding: 3px 3px;
  margin-left: 500px;
  margin-top: 5px;
  background-color: #337ab7;
  border-radius: 3px;
  color: #fff;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #2e6da4;
  }
    &:focus {
    outline: none;
  }
    &:active {
    background-color: #286090;
  }
    &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  `;

// Styled components
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

const FieldContainer = styled.div`
  flex: 0 0 calc(50% - 10px); /* Two fields per row with some space */
  flex-wrap: wrap;
  margin: 5px;
`;

const Field = styled.div`
  margin: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const Value = styled.div`
  margin-left: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
`;

// Custom styled icon button
const IconButton = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
`;

// Component Definition
const Details = ({ claim, claimId }) => {
  const [auth] = useAuth();
  const [clients, setClients] = useState([]);
  const [claimTypes, setClaimTypes] = useState([]);
  const [lossTypes, setLossTypes] = useState([]);
  const [adjusters, setAdjusters] = useState([]);
  const [examiners, setExaminers] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // State to toggle form view

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get("/api/v1/clients", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setClients(response.data.clients);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

      const fetchAdjusters = async () => {
        try {
          const response = await axios.get("/api/v1/users/adjusters", {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          });
          setAdjusters(response.data.adjusters); // Assuming the API response has a key "adjusters"
        } catch (error) {
          console.error("Error fetching adjusters:", error);
        }
      };
  
    const fetchClaimTypes = async () => {
      try {
        const response = await axios.get("/api/v1/claim-types", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setClaimTypes(response.data.claimTypes);
      } catch (error) {
        console.error("Error fetching claim types:", error);
      }
    };

    const fetchLossTypes = async () => {
      try {
        const response = await axios.get("/api/v1/loss-types", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setLossTypes(response.data.lossTypes);
      } catch (error) {
        console.error("Error fetching loss types:", error);
      }
    };

    const fetchExaminers = async () => {
      const clientIds = claim.clients.map((item) => item.client._id);
      try {
        const response = await axios.post(
          "/api/v1/clients/contacts/examiners",
          { clientIds },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        setExaminers(response.data.examiners);
      } catch (error) {
        console.error("Error fetching examiners:", error);
      }
    };

    fetchClients();
    fetchAdjusters();
    fetchClaimTypes();
    fetchLossTypes();
    fetchExaminers();
  }, [auth?.token, claim.clients]);

  // Helper function to get display name from array by ID
  const getDisplayNameById = (list, id) => list.find((item) => item._id === id)?.name || "N/A";
  const getDescriptionById = (list, id) => list.find((item) => item._id === id)?.description || "N/A";

  // Function to open Google Maps with the loss address
  const openMap = () => {
    const address = `${claim.lossAddress || ""} ${claim.lossCity || ""}, ${claim.lossProvince || ""} ${claim.lossPostalCode || ""}`;
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    window.open(url, "_blank");
  };

  const handleUpdateClick = () => {
    setIsEditing(true); // Open the form
  };

  const handleFormClose = () => {
    setIsEditing(false); // Close the form
  };
  
  return (
    <Row>
      <Col>
        <h1>Claim Details</h1>
      </Col>
      <Col>
        {!isEditing && <Btn onClick={handleUpdateClick}>Update</Btn>}
      </Col>
      <Col md={12}>
      {isEditing ? (
          <ClaimDetailsForm
            claim={claim}
          />
        ) : (
          <>
            <Row className="d-flex align-items-center">
              <Col md={6}>
                <Field>
                  <Label>Our File #</Label>
                  <Value>{claim.fileNumber || "N/A"}</Value>
                </Field>
              </Col>
              <Col md={6}>
              <Field>
                  <Label>
                    Loss Address
                    <IconButton src={mapsIcon} alt="Open in Maps" onClick={openMap} />
                  </Label>
                  <Value>{claim.lossAddress || "N/A"}</Value>
                  <Value>{`${claim.lossCity || "N/A"}, ${claim.lossProvince || "N/A"} ${claim.lossPostalCode || "N/A"}`}</Value>
                </Field>
              </Col>
            </Row>
            <Divider />
            <Row>
              {claim.clients?.length > 0 ? (
                claim.clients.map((clientItem, index) => (
                  <Col md={6} key={clientItem.client._id}>
                    <Field>
                      <Label>Client {index + 1}</Label>
                      <Value>{getDisplayNameById(clients, clientItem.client._id)}</Value>
                      <Value style={{ marginLeft: "50px" }}>
                        Claim #: {clientItem.claimNumber || "N/A"}
                      </Value>
                      <Value style={{ marginLeft: "50px" }}>
                        Share % {clientItem.sharePercentage || "N/A"}
                      </Value>
                    </Field>
                  </Col>
                ))
              ) : (
                <Col md={6}>
                  <Field>
                    <Label>Clients</Label>
                    <Value>N/A</Value>
                  </Field>
                </Col>
              )}
            </Row>
            <Divider />
            <Row>
              <Col md={6}>
                <Field>
                  <Label>Lead Examiner</Label>
                  <Value>{getDisplayNameById(examiners, claim.examinerId?._id)}</Value>
                </Field>
              </Col>
              <Col md={6}>
                <Field>
                  <Label>Adjuster</Label>
                  <Value>{getDisplayNameById(adjusters, claim.adjusterId?._id)}</Value>
                </Field>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col md={6}>
                <Field>
                  <Label>Date of Loss</Label>
                  <Value>{claim.dateOfLoss ? new Date(claim.dateOfLoss).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : "N/A"}</Value>
                </Field>
              </Col>
              <Col md={6}>
                <Field>
                  <Label>Proscription Date</Label>
                  <Value>{claim.proscriptionDate ? new Date(claim.proscriptionDate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : "N/A"}</Value>
                </Field>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col md={6}>
                <Field>
                  <Label>Claim Type</Label>
                  <Value>{getDescriptionById(claimTypes, claim.typeOfClaimId?._id)}</Value>
                </Field>
              </Col>
              <Col md={6}>
                <Field>
                  <Label>Loss Type</Label>
                  <Value>{getDescriptionById(lossTypes, claim.typeOfLossId?._id)}</Value>
                </Field>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col md={6}>
                <Field>
                  <Label>Loss Detail</Label>
                  <Value>{claim.lossDetail || "N/A"}</Value>
                </Field>
              </Col>
              <Divider />
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default Details;