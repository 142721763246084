import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import sorter from "../../assets/sorter.png";
import deleteIcon from "../../assets/delete.png";
import writeIcon from "../../assets/Write.png";
import Layout from "../../components/Layout/Layout";
import useLocations from "../../hooks/useLocations";
import TemplateForm from "../../components/admin/templatesPageComponents/TemplateForm";
import TemplateList from "../../components/admin/templatesPageComponents/TemplateList";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";
import { Col, Row } from "reactstrap";
import FillBtn from "../../shared/Buttons/FillBtn";

const Container = styled.div`
  display: flex;
  background: white;
  padding: 20px;
  border-radius: 8px;
  flex-direction: column;
  flex-grow: 1;
`;

const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left: 20px;
`;

const FilterDropdown = styled.select`
  padding: 8px;
  width: 200px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
`;

const Option = styled.option`
  font-size: 14px;
`;

const Templates = () => {
  const [auth] = useAuth();
  const locations = useLocations();
  const [locationId, setLocationId] = useState("");
  const [templates, setTemplates] = useState([]);
  const [selectedAddBtn, setSelectedAddBtn] = useState(false);

  const handleLocationChange = (e) => {
    setLocationId(e.target.value);
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(`/api/v1/templates/${locationId}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setTemplates(response.data);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    fetchTemplates();
  }, [locationId]);

  return (
    <Layout>
      <Container>
        <Row>
          <Col md={12}>
            <Row className="d-flex align-items-center">
              <Col md={9}>
                <HeaderContainer>
                  <div
                    style={{
                      fontSize: "25px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Templates
                  </div>
                </HeaderContainer>
              </Col>
              <Col md={3} className="text-end" >
                <FillBtn
                  handleOnClick={() => setSelectedAddBtn(true)}
                  className="w-100"
                  text="Add"
                />
              </Col>
            </Row>
          </Col>

          <Row style={{ padding: "10px" }}>
            <Col md={12}>
              <div style={{ padding: "5px" }}>Location</div>
              <FilterDropdown
                id="location_id"
                name="location_id"
                value={locationId}
                onChange={handleLocationChange}
              >
                <Option value="">Select location</Option>
                {locations.map((loc) => (
                  <Option key={loc._id} value={loc._id}>
                    {loc.location_name}
                  </Option>
                ))}
              </FilterDropdown>
            </Col>

            <Col md={12} className="my-2">
              {selectedAddBtn ? (
                <div>
                  <TemplateForm
                    locationId={locationId}
                    onClose={setSelectedAddBtn}
                    setTemplates={setTemplates}
                  />
                </div>
              ) : (
                <>
                  <TemplateList
                    templates={templates}
                    setTemplates={setTemplates}
                  />
                </>
              )}
            </Col>
          </Row>
        </Row>
      </Container>
    </Layout>
  );
};

export default Templates;
