import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import AddReserveForm from "./AddReserveForm";
import RequestPaymentForm from "./RequestPaymentForm";
import ReceivePaymentForm from "./ReceivePaymentForm";
import Modal from "./Modal";
import { useAuth } from "../../context/auth";
import { FaEllipsisV } from "react-icons/fa";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const AddButton = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  margin-left: 20px;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  &:hover {
    background-color: #0069d9;
  }
`;

const CopyButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  margin-right: 100px;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
  &:disabled {
    background-color: #c3e6cb;
    cursor: not-allowed;
  }
`;

const Button = styled.button`
  background-color: transparent;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 5px 5px;
  cursor: pointer;
  &:hover {
    background-color: #0069d9;
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`;

const Table = styled.table`
  width: 100%;
`;

const Table2 = styled.table`
  width: 90%;
`;

const TableHeader = styled.th`
  padding: 15px;
  text-align: left;
  background: #e0e4f3;
  color: #444444;
  font-size: 15px;
  font-weight: 400;
`;

const TableCell = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  color: #9fa2b4;
`;

const TableCell2 = styled.td`
  padding: 12px;
  text-align: left;
  color: #9fa2b4;
`;

const ClaimReservesPage = ({ claimId }) => {
  const { auth } = useAuth();
  const [reserves, setReserves] = useState([]);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isRequestPaymentFormOpen, setIsRequestPaymentFormOpen] =
    useState(false);
  const [isReceivePaymentFormOpen, setIsReceivePaymentFormOpen] =
    useState(false);
  const [selectedReserve, setSelectedReserve] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [expandedReserves, setExpandedReserves] = useState({});
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [formData, setFormData] = useState({
    reserveId: "",
    userCode: "",
    lineOfReserve: "",
    limits: "",
    recommendedReserve: "",
    paymentsRequested: "",
    paymentId: Date.now(),
    dateRequested: new Date().toISOString(),
    party: "",
    amountRequested: "",
    amountPaid: "",
    notes: "",
    status: "",
    dateReceived: "",
    lossPaidToDate: "",
    outstandingReserve: "",
    payment_id: "",
  });


  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  const formatCurrency = (value) =>
    currencyFormatter.format(isNaN(value) || value === null ? 0 : value);

  useEffect(() => {
    console.log("isAddFormOpen changed:", isAddFormOpen);
  }, [isAddFormOpen]);

  useEffect(() => {
    if (claimId) fetchReserves();
  }, [claimId]);

  const fetchReserves = async () => {
    try {
      const response = await axios.get(`/api/v1/reserves/${claimId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      const updatedReserves = response.data.map((reserve) => {
        const lossPaid = reserve.payments.reduce(
          (sum, payment) => sum + (payment.amountPaid || 0),
          0
        );
        return { ...reserve, lossPaid }; // Include the calculated lossPaid
      });

      console.log("Fetched Reserves with Loss Paid:", updatedReserves);
      setReserves(updatedReserves);
    } catch (error) {
      console.error("Error fetching reserves:", error);
      setError("Failed to fetch reserves.");
    }
  };

  const calculateTotals = () => {
    const totals = {
      recommendedReserve: 0,
      lossPaid: 0,
      paymentsRequested: 0,
      outstandingReserve: 0,
    };

    reserves.forEach((reserve) => {
      totals.recommendedReserve += reserve.recommendedReserve || 0;
      totals.lossPaid += reserve.lossPaid || 0;
      totals.paymentsRequested += reserve.paymentsRequested || 0;
      totals.outstandingReserve += reserve.outstandingReserve || 0;
    });

    return totals;
  };

  const addReserve = async (newReserve) => {
    try {
      await axios.post(
        `/api/v1/reserves/${claimId}`,
        newReserve,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`, // Pass the token directly here
          },
        }
      );
      await fetchReserves();
    } catch (err) {
      console.error("Error adding reserve:", err.response?.data || err.message);
      setError("Failed to add reserve. Please ensure you are authorized.");
    }
  };
  

  const deleteReserve = async (reserveId) => {
    try {
      await axios.delete(`/api/v1/reserves/${claimId}/${reserveId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`, // Add the token here
        },
      });
      setReserves((prev) => prev.filter((reserve) => reserve.id !== reserveId));
    } catch (error) {
      console.error("Failed to delete reserve:", error.response?.data || error.message);
    }
  };
  

  const deletePayment = async (reserveId, paymentId) => {
    try {
      await axios.delete(
        `/api/v1/reserves/${claimId}/${reserveId}/payments/${paymentId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`, // Add the token here
          },
        }
      );
      setReserves((prev) =>
        prev.map((reserve) =>
          reserve.id === reserveId
            ? {
                ...reserve,
                payments: reserve.payments.filter(
                  (payment) => payment._id !== paymentId
                ),
                lossPaid: reserve.payments
                  .filter((payment) => payment._id !== paymentId)
                  .reduce((sum, payment) => sum + (payment.amountPaid || 0), 0),
              }
            : reserve
        )
      );
    } catch (error) {
      console.error("Failed to delete payment:", error.response?.data || error.message);
    }
  };
  

  const toggleExpandReserve = (reserveId) => {
    setExpandedReserves((prev) => ({
      ...prev,
      [reserveId]: !prev[reserveId],
    }));
  };

  const toggleDropdown = (id) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSubmit = async (e, action, shouldResetForm = false) => {
    e.preventDefault();
  
    try {
      if (action === "addReserve") {
        await axios.post(`/api/v1/reserves/${claimId}`, formData, {
          headers: {
            Authorization: `Bearer ${auth?.token}`, // Add the token here
          },
        });
      } else if (action === "requestPayment") {
        await axios.post(
          `/api/v1/reserves/${claimId}/${formData.reserveId}/payments`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`, // Add the token here
            },
          }
        );
      } else if (action === "receivePayment") {
        await axios.put(
          `/api/v1/reserves/${claimId}/${formData.reserveId}/payments/${formData.paymentId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`, // Add the token here
            },
          }
        );
      }
      fetchReserves();
      if (shouldResetForm) setFormData({});
      setIsAddFormOpen(false);
      setIsRequestPaymentFormOpen(false);
      setIsReceivePaymentFormOpen(false);
    } catch (err) {
      console.error("Error submitting form:", err.response?.data || err.message);
      setError("Failed to submit the form. Please ensure you are authorized.");
    }
  };
  

  const totals = calculateTotals();

  const copyTableContent = () => {
    try {
      // Select the table body of the main table
      const tableBody = document.querySelector("#mainTable tbody");
      if (!tableBody) {
        console.error("Main table body not found");
        return;
      }

      // Extract rows from the table excluding the headings and last two columns
      const rows = Array.from(tableBody.querySelectorAll("tr"))
        .map((row) => {
          const cells = Array.from(row.querySelectorAll("td"))
            .slice(0, -2) // Exclude the last two columns
            .map((cell) => cell.innerText.trim())
            .join("\t"); // Use tabs to separate columns
          return cells;
        })
        .join("\n"); // Use new lines to separate rows

      // Create a temporary textarea to copy text
      const textarea = document.createElement("textarea");
      textarea.style.fontFamily = "Arial";
      textarea.style.fontSize = "11px";
      textarea.value = rows;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      console.log("Main table content copied successfully!");
      setShowCopiedMessage(true); // Show copied message
      setTimeout(() => setShowCopiedMessage(false), 2000); // Hide message after 2 seconds
    } catch (error) {
      console.error("Failed to copy main table content:", error);
    }
  };

  const clearReserves = async () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to clear reserves? This will update all recommended reserves and outstanding reserves."
    );
  
    if (!isConfirmed) {
      console.log("Clear Reserves action cancelled.");
      return;
    }
  
    try {
      // Update reserves locally in state
      const updatedReserves = reserves.map((reserve) => {
        const newRecommendedReserve =
          reserve.lossPaid + reserve.paymentsRequested;
        const newOutstandingReserve = newRecommendedReserve - reserve.lossPaid;
  
        return {
          ...reserve,
          recommendedReserve: newRecommendedReserve,
          outstandingReserve: newOutstandingReserve,
        };
      });
  
      setReserves(updatedReserves);
  
      // Optionally, update reserves on the backend
      for (const reserve of updatedReserves) {
        await axios.put(
          `/api/v1/reserves/${reserve.claimId}/${reserve.id}`,
          {
            recommendedReserve: reserve.recommendedReserve,
            outstandingReserve: reserve.outstandingReserve,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`, // Include token directly here
            },
          }
        );
      }
  
      console.log("Reserves cleared and updated.");
    } catch (error) {
      console.error("Failed to clear reserves:", error.response?.data || error.message);
      setError("Failed to clear reserves.");
    }
  };
  

  return (
    <div
      className="reserve-page"
      style={{
        width: "100%" /* Ensure full width */,
        display: "flex",
        flexDirection: "column",
        margin: "0 auto" /* Optional: Center content horizontally */,
      }}
    >
      <h1>Reserves</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <AddButton
          onClick={() => {
            console.log("Add Reserve button clicked");
            setSelectedReserve(null);
            setIsAddFormOpen(true); // Trigger modal opening
            console.log("isAddFormOpen:", isAddFormOpen); // Log current state
          }}
        >
          Add Reserve
        </AddButton>

        <CopyButton onClick={copyTableContent}>Copy Data</CopyButton>
      </div>
      {showCopiedMessage && (
        <div style={{ marginTop: "10px", color: "green" }}>
          Table content copied!
        </div>
      )}
      <TableContainer>
        <Table id="mainTable">
          <thead>
            <tr>
              <TableHeader>Line of Reserve</TableHeader>
              <TableHeader>Limits</TableHeader>
              <TableHeader>Recommended Reserve</TableHeader>
              <TableHeader>Loss Paid</TableHeader>
              <TableHeader>Payments Requested</TableHeader>
              <TableHeader>Outstanding Reserve</TableHeader>
              <TableHeader>Actions</TableHeader>
            </tr>
          </thead>
          <tbody>
            {reserves.map((reserve) => (
              <React.Fragment key={reserve.id}>
                <tr>
                  <TableCell>{reserve.lineOfReserve}</TableCell>
                  <TableCell>{formatCurrency(reserve.limits)}</TableCell>
                  <TableCell>
                    {formatCurrency(reserve.recommendedReserve)}
                  </TableCell>
                  <TableCell>{formatCurrency(reserve.lossPaid)}</TableCell>
                  <TableCell>
                    {formatCurrency(reserve.paymentsRequested)}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(reserve.outstandingReserve)}
                  </TableCell>
                  <TableCell>
                    <Dropdown
                      isOpen={!!dropdownOpen[reserve.id]}
                      toggle={() => toggleDropdown(reserve.id)}
                      direction="down"
                    >
                      <DropdownToggle className="bg-transparent border">
                        <FaEllipsisV color="black" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setSelectedReserve(reserve); // Set the selected reserve
                            setIsRequestPaymentFormOpen(true); // Open the modal
                          }}
                        >
                          Request Payment
                        </DropdownItem>

                        <DropdownItem
                          onClick={() => {
                            setSelectedReserve(reserve); // Pass the reserve to be edited
                            setIsAddFormOpen(true); // Open the modal
                          }}
                        >
                          Edit
                        </DropdownItem>

                        <DropdownItem onClick={() => deleteReserve(reserve.id)}>
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                  <TableCell style={{ borderBottom: "0px" }}>
                    <Button onClick={() => toggleExpandReserve(reserve.id)}>
                      {expandedReserves[reserve.id] ? "-" : "+"}
                    </Button>
                  </TableCell>
                </tr>

                {expandedReserves[reserve.id] && (
                  <tr>
                    <td colSpan="7">
                      <Table2>
                        <thead>
                          <tr style={{ borderBottom: "3px" }}>
                            <TableHeader>Date Requested</TableHeader>
                            <TableHeader>Party</TableHeader>
                            <TableHeader>Amount Requested</TableHeader>
                            <TableHeader>Amount Paid</TableHeader>
                            <TableHeader>Date Received</TableHeader>
                            <TableHeader>Notes</TableHeader>
                            <TableHeader>Actions</TableHeader>
                          </tr>
                        </thead>
                        <tbody>
                          {reserve.payments.map((payment) => (
                            <tr key={payment._id}>
                              <TableCell2>
                                {payment.dateRequested
                                  ? new Date(payment.dateRequested)
                                      .toISOString()
                                      .split("T")[0]
                                  : "N/A"}
                              </TableCell2>
                              <TableCell2>{payment.party}</TableCell2>
                              <TableCell2>
                                {formatCurrency(payment.amountRequested)}
                              </TableCell2>
                              <TableCell2>
                                {formatCurrency(payment.amountPaid)}
                              </TableCell2>
                              <TableCell2>
                                {payment.dateRequested
                                  ? new Date(payment.dateReceived)
                                      .toISOString()
                                      .split("T")[0]
                                  : "N/A"}
                              </TableCell2>
                              <TableCell2>{payment.notes}</TableCell2>
                              <TableCell2>
                                <Dropdown
                                  isOpen={!!dropdownOpen[payment._id]}
                                  toggle={() => toggleDropdown(payment._id)}
                                  direction="down"
                                >
                                  <DropdownToggle className="bg-transparent border">
                                    <FaEllipsisV color="black" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => {
                                        console.log(
                                          "Clicked Payment:",
                                          payment._id
                                        ); // Debugging
                                        setSelectedReserve(reserve);
                                        setSelectedPayment(payment);
                                        setIsReceivePaymentFormOpen(true);
                                      }}
                                    >
                                      Receive Payment
                                    </DropdownItem>

                                    <DropdownItem>Edit</DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        deletePayment(reserve.id, payment._id)
                                      }
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </TableCell2>
                            </tr>
                          ))}
                        </tbody>
                      </Table2>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
            {/* Single Totals Row */}
            <tr>
              <TableCell>
                <strong>TOTALS</strong>
              </TableCell>
              <TableCell /> {/* Empty for Limits */}
              <TableCell>
                <strong>{formatCurrency(totals.recommendedReserve)}</strong>
              </TableCell>
              <TableCell>
                <strong>{formatCurrency(totals.lossPaid)}</strong>
              </TableCell>
              <TableCell>
                <strong>{formatCurrency(totals.paymentsRequested)}</strong>
              </TableCell>
              <TableCell>
                <strong>{formatCurrency(totals.outstandingReserve)}</strong>
              </TableCell>
              <TableCell /> {/* Empty for Actions */}
            </tr>
          </tbody>
        </Table>
      </TableContainer>

      {isAddFormOpen && (
        <Modal onClose={() => setIsAddFormOpen(false)}>
  <AddReserveForm
    reserve={selectedReserve} // Pass the selected reserve for editing
        onClose={() => {
      setIsAddFormOpen(false);
      fetchReserves(); //
    }}
    onSave={async (formData) => {
      try {
        if (selectedReserve) {
          // Edit existing reserve
          const updatedReserve = {
            ...selectedReserve,
            ...formData,
          };

          // Update backend
          await axios.put(
            `/api/v1/reserves/${claimId}/${selectedReserve.id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`, // Include token directly here
              },
            }
          );

          // Update local state
          setReserves((prevReserves) =>
            prevReserves.map((reserve) =>
              reserve.id === selectedReserve.id ? updatedReserve : reserve
            )
          );
        } else {
          // Add new reserve
          await addReserve(formData);
        }

        setSelectedReserve(null); // Reset selected reserve
        setIsAddFormOpen(false); // Close the modal
      } catch (error) {
        console.error("Failed to save reserve:", error.response?.data || error.message);
        setError("Failed to save reserve. Please try again.");
      }
    }}
  />
</Modal>

      )}
      {isRequestPaymentFormOpen && selectedReserve && (
       <Modal
       onClose={() => {
         setIsRequestPaymentFormOpen(false);
         fetchReserves(); // Fetch reserves when the modal is closed
       }}
     >
       <RequestPaymentForm
         reserve={selectedReserve}
         onClose={() => {
           setIsRequestPaymentFormOpen(false);
           fetchReserves(); // Fetch reserves when the form's onClose is triggered
         }}
         onSave={async (newPayment) => {
           try {
             // Optimistic update: add payment to state immediately for responsiveness
             setReserves((prevReserves) =>
               prevReserves.map((reserve) =>
                 reserve.id === selectedReserve.id
                   ? {
                       ...reserve,
                       payments: [...reserve.payments, newPayment],
                       paymentsRequested:
                         reserve.paymentsRequested + newPayment.amountRequested,
                     }
                   : reserve
               )
             );
     
             // Re-fetch reserves to ensure data is accurate
             await fetchReserves();
           } catch (error) {
             console.error(
               "Failed to update reserves after payment:",
               error
             );
           }
           setIsRequestPaymentFormOpen(false);
         }}
       />
     </Modal>
     
      )}
      {isReceivePaymentFormOpen && selectedReserve && selectedPayment && (
  <Modal
    onClose={() => {
      setIsReceivePaymentFormOpen(false);
      fetchReserves(); // Fetch reserves when the modal is closed
    }}
  >
    <ReceivePaymentForm
      reserve={selectedReserve}
      payment={selectedPayment}
      onClose={() => {
        setIsReceivePaymentFormOpen(false);
        fetchReserves(); // Fetch reserves when the form's onClose is triggered
      }}
      onSave={(updatedPayment) => {
        setReserves((prevReserves) =>
          prevReserves.map((reserve) =>
            reserve.id === selectedReserve.id
              ? {
                  ...reserve,
                  payments: reserve.payments.map((payment) =>
                    payment._id === updatedPayment._id
                      ? updatedPayment
                      : payment
                  ),
                  lossPaid: reserve.payments.reduce(
                    (sum, payment) =>
                      payment._id === updatedPayment._id
                        ? sum + updatedPayment.amountPaid
                        : sum + (payment.amountPaid || 0),
                    0
                  ),
                }
              : reserve
          )
        );
        setIsReceivePaymentFormOpen(false);
        fetchReserves(); // Ensure reserves are re-fetched after save
      }}
    />
  </Modal>
)}

      <button
        style={{
          marginTop: "10px",
          backgroundColor: "#FF6347",
          color: "white",
          width: "150px",
          border: "none",
          padding: "10px 20px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={clearReserves}
      >
        Clear Reserves
      </button>
    </div>
  );
};

export default ClaimReservesPage;
