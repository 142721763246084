import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../common/Button';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  margin: 5px 0;
`;

const Textarea = styled.textarea`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Allow vertical resizing */
`;

const AddNoteForm = ({ formData, setFormData, handleSubmit, title }) => {
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h2>{title}</h2>
          
<Label>Description</Label>
      <Textarea
        name="description"
        value={formData.description}
        onChange={handleChange}
        rows={5} // Default height for the textarea
        placeholder="Enter description here..."
      />
      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default AddNoteForm;
