import React from 'react';

const AdminIcon = () => {
  return (
    <div>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="5.00008" r="3.33333" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.75 18.3333C12.375 18.3333 11.6876 18.3333 11.2604 17.9062C10.8333 17.4791 10.8333 16.7916 10.8333 15.4167C10.8333 14.0417 10.8333 13.3543 11.2604 12.9271C11.6876 12.5 12.375 12.5 13.75 12.5C15.1249 12.5 15.8124 12.5 16.2395 12.9271C16.6666 13.3543 16.6666 14.0417 16.6666 15.4167C16.6666 16.7916 16.6666 17.4791 16.2395 17.9062C15.8124 18.3333 15.1249 18.3333 13.75 18.3333ZM15.39 14.7882C15.5798 14.5983 15.5798 14.2906 15.39 14.1007C15.2002 13.9109 14.8924 13.9109 14.7025 14.1007L13.1018 15.7014L12.7974 15.397C12.6076 15.2072 12.2998 15.2072 12.11 15.397C11.9201 15.5868 11.9201 15.8946 12.11 16.0845L12.7581 16.7326C12.9479 16.9225 13.2557 16.9225 13.4456 16.7326L15.39 14.7882Z" fill="white"/>
            <path d="M12.8456 11.2561C12.0039 10.9868 11.0333 10.8333 10 10.8333C6.77836 10.8333 4.16669 12.3256 4.16669 14.1666C4.16669 15.9504 6.61872 17.4069 9.7018 17.4956C9.67917 17.3904 9.6617 17.2859 9.64791 17.1833C9.58318 16.7019 9.58327 16.1137 9.58336 15.4882V15.3449C9.58327 14.7195 9.58318 14.1313 9.64791 13.6498C9.71993 13.1141 9.89209 12.5277 10.3766 12.0432C10.8612 11.5586 11.4476 11.3865 11.9833 11.3144C12.2432 11.2795 12.5344 11.2634 12.8456 11.2561Z" fill="white"/>
        </svg>
    </div>
  );
};

export default AdminIcon;