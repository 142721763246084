import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../../context/auth";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 100%;
`;

const ModalTitle = styled.h2`
  margin-top: 0;
  font-size: 20px;
`;

const TemplateSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const TemplateExportModal = ({ isOpen, onClose, onExport, locationId }) => {
    const [auth] = useAuth();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  useEffect(() => {
    if (isOpen && locationId) {
      fetchTemplatesByLocation();
    }
  }, [isOpen, locationId]);

  const fetchTemplatesByLocation = async () => {
    try {
      const response = await axios.get(`/api/v1/templates/location/${locationId}`, {
        headers: { Authorization: `Bearer ${auth?.token}` }, // Ensure `auth` is defined in your context
      });
      if (response.status === 200) {
        setTemplates(response.data);
      } else {
        console.error("Failed to fetch templates:", response.data.message);
        setTemplates([]); // Clear templates if there's an error
      }
    } catch (error) {
      console.error("Error fetching templates by location", error);
      setTemplates([]);
    }
  };

  const handleTemplateSelection = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const handleExport = () => {
    if (selectedTemplate) {
      onExport(selectedTemplate);
    } else {
      alert("Please select a template.");
    }
  };

  if (!isOpen) return null; // Only render if isOpen is true

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Select a Template</ModalTitle>
        <TemplateSelect onChange={handleTemplateSelection} value={selectedTemplate}>
          <option value="">Choose a template...</option>
          {templates.map((template) => (
            <option key={template._id} value={template._id}>
              {template.name}
            </option>
          ))}
        </TemplateSelect>
        <ButtonContainer>
          <button onClick={handleExport}>Export</button>
          <button onClick={onClose} style={{ background: "grey" }}>Cancel</button>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default TemplateExportModal;
