import React from "react";

const Modal = ({ children, onClose }) => {
  // Inline styles
  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    content: {
      position: "relative",
      background: "white",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      width: "90%",
      maxWidth: "500px",
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "transparent",
      border: "none",
      fontSize: "24px",
      fontWeight: "bold",
      color: "#333",
      cursor: "pointer",
    },
    closeButtonHover: {
      color: "#ff0000", // Use hover state dynamically if needed
    },
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.content}>
        <button
          style={styles.closeButton}
          onMouseOver={(e) => (e.target.style.color = styles.closeButtonHover.color)}
          onMouseOut={(e) => (e.target.style.color = styles.closeButton.color)}
          onClick={onClose}
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
