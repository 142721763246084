// components/ReportsPage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Layout from '../../components/Layout/Layout';

const ReportsPage = () => {
  const [claimsByClient, setClaimsByClient] = useState([]);
  const [invoicedByUser, setInvoicedByUser] = useState([]);
  const [overdueTasksByUser, setOverdueTasksByUser] = useState([]);
  const [uninvoicedTimeExpensesByUser, setUninvoicedTimeExpensesByUser] = useState([]);
  const [openClaimsByUser, setOpenClaimsByUser] = useState([]);

  useEffect(() => {
    fetchReportData();
  }, []);

  const fetchReportData = async () => {
    try {
      const claimsResponse = await axios.get('/api/reports/claims-by-client');
      setClaimsByClient(claimsResponse.data);

      const invoicedResponse = await axios.get('/api/reports/invoiced-by-user');
      setInvoicedByUser(invoicedResponse.data);

      const tasksResponse = await axios.get('/api/reports/overdue-tasks-by-user');
      setOverdueTasksByUser(tasksResponse.data);

      const timeExpensesResponse = await axios.get('/api/reports/uninvoiced-time-expenses-by-user');
      setUninvoicedTimeExpensesByUser(timeExpensesResponse.data);

      const openClaimsResponse = await axios.get('/api/reports/open-claims-by-user');
      setOpenClaimsByUser(openClaimsResponse.data);
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  };

  return (
          <Layout title="Reports - User">
            <div>
      <h1>Reports</h1>
      <section>
        <h2>Claims by Client</h2>
        {/* Render Claims by Client Report */}
        <pre>{JSON.stringify(claimsByClient, null, 2)}</pre>
      </section>
      <section>
        <h2>Invoiced by User</h2>
        {/* Render Invoiced by User Report */}
        <pre>{JSON.stringify(invoicedByUser, null, 2)}</pre>
      </section>
      <section>
        <h2>Overdue Tasks by User</h2>
        {/* Render Overdue Tasks by User Report */}
        <pre>{JSON.stringify(overdueTasksByUser, null, 2)}</pre>
      </section>
      <section>
        <h2>Uninvoiced Time and Expenses by User</h2>
        {/* Render Uninvoiced Time and Expenses Report */}
        <pre>{JSON.stringify(uninvoicedTimeExpensesByUser, null, 2)}</pre>
      </section>
      <section>
        <h2>Open Claims by User</h2>
        {/* Render Open Claims by User Report */}
        <pre>{JSON.stringify(openClaimsByUser, null, 2)}</pre>
      </section>
    </div>
      
      </Layout>
  );
};

export default ReportsPage;
