import React, { useState } from "react";
import Sidebar from "./sidebar";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import styled from "styled-components";
import ChangePassword from "./changePassword";
import UpdateProfile from "./updateProfile";
import { useAuth } from "../../context/auth";
import { Modal, Dropdown } from "react-bootstrap";
import { FaCog } from "react-icons/fa";
import logo from '../../assets/logo2.png';

// Main container holding sidebar and content
const AppContainer = styled.div`
  display: flex;
  min-height: 100vh;
`;

// Sidebar container styled to be static (fixed)
const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background-color: #040848;
  z-index: 1000;
`;

// Main content area styled for scrolling
const MainContainer = styled.main`
  background: #eeeeee;
  margin-left: 250px; /* Matches the width of the SidebarContainer */
  padding: 20px;
  padding-top: 50px; /* Matches the height of the Header */
  overflow-y: auto;
  width: calc(100% - 250px);
`;

// Header styled to be inside the main content container
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eeeeee;
  position: fixed;
  border-bottom: 1px solid #ddd;
  width: calc(100% - 250px); /* Adjust to account for the sidebar */
  left: 250px; /* Offset for the sidebar */
  top: 0;
  z-index: 1100;
  padding: 5px 5px; /* Reduced padding to make the header smaller */
`;

const Logo = styled.div`
  font-size: 1.4rem; /* Reduced font size */
  font-weight: bold;
  font-family: arial;
  color: #314c5f;
  display: flex; /* Optional, ensures layout flexibility */
  align-items: center; /* Aligns image with text if present */
  
  img {
    max-height: 20px; /* Restrict image height */
    height: auto; /* Maintain aspect ratio */
    width: auto; /* Optional, ensures no stretching */
  }
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.span`
  font-size: 1.5rem; /* Reduced font size */
  font-weight: 500;
  color: #040848;
  margin-right: 15px;
  white-space: nowrap; /* Prevent text wrapping */
`;

const GearDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background: none;
    border: none;
    padding: 0;
    color: #040848;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

const Layout = ({ children, title, description, keywords, author }) => {
  const [auth] = useAuth();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState(false);

  const handleOpenChangePasswordModal = () => setShowChangePasswordModal(true);
  const handleCloseChangePasswordModal = () => setShowChangePasswordModal(false);

  const handleOpenUpdateProfileModal = () => setShowUpdateProfileModal(true);
  const handleCloseUpdateProfileModal = () => setShowUpdateProfileModal(false);

  return (
    <AppContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContainer>
        <Header>
          <Logo><img src={logo} alt='logo2' /> Group</Logo>
          <UserContainer>
            <UserName>{auth?.user?.name || "User"}</UserName>
            <GearDropdown>
              <Dropdown.Toggle id="dropdown-basic" as="span">
                <FaCog />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleOpenUpdateProfileModal}>Update Profile</Dropdown.Item>
                <Dropdown.Item onClick={handleOpenChangePasswordModal}>Change Password</Dropdown.Item>
              </Dropdown.Menu>
            </GearDropdown>
          </UserContainer>
        </Header>
        <Toaster />
        {children}

        {/* Modal for Change Password */}
        <Modal show={showChangePasswordModal} onHide={handleCloseChangePasswordModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ChangePassword />
          </Modal.Body>
        </Modal>

        {/* Modal for Update Profile */}
        <Modal show={showUpdateProfileModal} onHide={handleCloseUpdateProfileModal} centered>
  <Modal.Header closeButton>
    <Modal.Title>Update Profile</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <UpdateProfile userId={auth?.user?._id} /> {/* Dynamically pass userId */}
  </Modal.Body>
</Modal>

      </MainContainer>
    </AppContainer>
  );
};

Layout.defaultProps = {
  title: "",
  description: "",
  keywords: "mern,react,node,mongodb",
  author: "Ameer Hamza",
};

export default Layout;
