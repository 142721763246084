import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { FaEllipsisV } from "react-icons/fa";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { useAuth } from "../../context/auth";
import { useDropzone } from "react-dropzone";
import TemplateExportModal from "./TemplateExportModal"; // adjust the path if necessary
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

// Styled components
const Container = styled.div`
  padding: 20px;
  width: 100%;
  margin: auto;
`;

const Btn = styled.button`
  padding: 8px;
  font-size: 14px;
  border: none;
  background: blue;
  color: white;
  border-radius: 4px;
`;

const Table = styled.table`
  width: 100%; /* Ensures the table takes full width of its container */
  border-collapse: collapse;

  th,
  td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
    border-bottom: 3px solid #ddd;
  }

  thead {
    position: sticky;
    top: 0; /* Optional: Make the header sticky if there's overflow */
    background-color: #f4f4f4;
  }
`;

const ActionsDropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover div {
    display: block;
  }
`;

const Dropzone = styled.div`
  border: 2px dashed #888;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
`;

const FolderActions = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  select,
  button {
    padding: 8px;
    font-size: 14px;
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 150px;

  button {
    width: 100%;
    padding: 10px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;

    &:hover {
      background-color: #ddd;
    }
  }
`;

const DocumentManagement = ({ claimId, locationId }) => {
  const [auth] = useAuth();
  const [folders, setFolders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [newFolderName, setNewFolderName] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [loadingFolders, setLoadingFolders] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [targetFolder, setTargetFolder] = useState("");
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
  const [menuDropdownOpen, setMenuDropdownOpen] = useState(false); //
  const toggleMoveModal = () => setIsMoveModalOpen(!isMoveModalOpen);

  const openTemplateModal = () => setIsTemplateModalOpen(true);

  // Function to close the modal

  useEffect(() => {
    fetchFolders();
  }, [claimId]);

  useEffect(() => {
    if (selectedFolder) {
      fetchDocuments(selectedFolder);
    }
  }, [selectedFolder]);

  const closeTemplateModal = () => {
    setIsTemplateModalOpen(false);
    setSelectedTemplate(null); // Reset the selection when closed
  };
  const toggleDropdown = (id) => {
    console.log(`Toggling dropdown for ID: ${id}`);
    setDropdownOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const toggleMenuDropdown = () => {
    setMenuDropdownOpen((prevState) => !prevState);
  };
  // Function to handle template export
  const handleTemplateExport = async (selectedTemplate) => {
    if (!selectedTemplate) {
      alert("Please select a template.");
      return;
    }

    try {
      const response = await axios.post(
        `/api/v1/templates/${selectedTemplate}/export`,
        { claimId },
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );

      if (response.data.success) {
        alert("Template exported successfully.");
        closeTemplateModal();
      } else {
        console.error("Template export failed:", response.data.message);
        alert("Failed to export the template.");
      }
    } catch (error) {
      console.error("Error exporting template:", error);
      alert("An error occurred during template export.");
    }
  };

  const retrieveDocumentUrl = async (documentId) => {
    console.log(`Attempting to retrieve document URL for ID: ${documentId}`);
    const route = `/api/v1/documents/${documentId}/retrieve`;
    try {
      const response = await axios.get(route, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });

      if (response.data.success && response.data.url) {
        console.log(
          `Document URL retrieved successfully: ${response.data.url}`
        );
        return response.data.url;
      } else {
        console.warn("Document not found:", response.data.message);
        return null;
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.warn(`Document not found (404) for ID: ${documentId}`);
      } else {
        console.error("Error retrieving document URL:", error);
      }
      return null;
    }
  };

  const openDocument = async (documentId) => {
    const documentUrl = await retrieveDocumentUrl(documentId);
    if (documentUrl) {
      // Open the document in a new tab
      window.open(documentUrl, "_blank");
    } else {
      console.error("Document URL could not be retrieved.");
    }
  };
 
  const onDrop = async (acceptedFiles) => {
    if (!selectedFolder) {
      alert("Please select a folder.");
      return;
    }

    const formData = new FormData();
  
    for (const file of acceptedFiles) {
      try {
        // Use file's native type if available; fallback to `application/octet-stream`
        const fileType = file.type || "application/octet-stream";
  
        console.log(`Uploading file: ${file.name}, Type: ${fileType}`);
        formData.append("files", file);
        formData.append("type", fileType);
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error);
        continue;
      }
    }
  
    try {
      const response = await axios.post(
        `/api/v1/folders/${selectedFolder}/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Upload successful:", response.data);
      // Optionally refresh documents after upload
      if (selectedFolder) {
        fetchDocuments(selectedFolder);
      }
    } catch (error) {
      console.error(
        "Error uploading documents:",
        error.response?.data || error
      );
      alert(
        `Error: ${error.response?.data?.message || "An error occurred during the upload."}`
      );
    }
  };  
  
  

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const createFolder = async () => {
    // Prompt user for the folder name
    const folderName = prompt("Enter the new folder name:");

    // Exit if the user cancels the prompt or leaves it blank
    if (!folderName) {
      console.log("Folder creation cancelled or empty name provided.");
      return;
    }

    try {
      // Proceed with folder creation
      const response = await axios.post(
        "/api/v1/folders/create",
        { name: folderName, claimId },
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );

      // Update state with the newly created folder
      setFolders((prevFolders) => [...prevFolders, response.data.folder]);
      setSelectedFolder(response.data.folder._id);
    } catch (error) {
      console.error("Error creating folder", error);
    }
  };

  const deleteFolder = async (folderId) => {
    try {
      await axios.delete(`/api/v1/folders/${folderId}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      setFolders(folders.filter((folder) => folder._id !== folderId));
      if (selectedFolder === folderId) setSelectedFolder(null);
    } catch (error) {
      console.error("Error deleting folder", error);
      alert("Failed to delete folder. Please try again.");
    }
  };
  

  const fetchFolders = async () => {
    setLoadingFolders(true);
    try {
      const response = await axios.get(`/api/v1/folders/claim/${claimId}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
  
      const fetchedFolders = response.data.folders;
      if (fetchedFolders.length > 0 && !selectedFolder) {
        setFolders(fetchedFolders);
        setSelectedFolder(fetchedFolders[0]._id); // Set the first folder as default
      }
    } catch (error) {
      console.error("Error fetching folders", error);
    } finally {
      setLoadingFolders(false);
    }
  };
  

  const fetchDocuments = async (folderId) => {
    if (!folderId) {
      console.error("fetchDocuments called with an undefined folderId");
      return;
    }
    setLoadingDocuments(true);
    try {
      const response = await axios.get(
        `/api/v1/folders/${folderId}/documents`,
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );
      setDocuments(response.data.documents);
    } catch (error) {
      console.error("Error fetching documents", error);
    } finally {
      setLoadingDocuments(false);
    }
  };

  const deleteDocument = async (documentId) => {
    try {
      await axios.delete(`/api/v1/documents/${documentId}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      setDocuments((prevDocs) => prevDocs.filter((doc) => doc._id !== documentId));
      alert("Document deleted successfully.");
    } catch (error) {
      console.error("Error deleting document", error);
      alert("Failed to delete document. Please try again.");
    }
  };
  
  const handleEmailSelected = async () => {
    if (selectedDocuments.length === 0) {
      alert("Please select at least one document to email.");
      return;
    }
  
    try {
      const response = await axios.post(
        "/api/v1/documents/email",
        { documentIds: selectedDocuments },
        { headers: { Authorization: `Bearer ${auth?.token}` } }
      );
  
      if (response.data.success) {
        alert("Email sent successfully.");
      } else {
        alert("Failed to send email.");
      }
    } catch (error) {
      console.error("Error emailing documents:", error);
      alert("An error occurred while emailing documents.");
    }
  };
  

  
  const handleEditDocument = async (documentId) => {
    if (!documentId) {
      alert("Document ID is required to edit the document.");
      console.error("Missing documentId");
      return;
    }
  
    try {
      // Fetch the edit URL for the document
      const editUrl = await fetchEditUrl(documentId);
      if (editUrl) {
        // Attempt to open the document using IT Hit Doc Opener
        try {
          const docOpenerUrl = `ithit:?edit&url=${encodeURIComponent(editUrl)}`;
          console.log("Generated IT Hit URL:", docOpenerUrl);
          window.open(docOpenerUrl, "_self");
        } catch (error) {
          console.warn("IT Hit Doc Opener is not available. Falling back to raw URL.");
          window.open(editUrl, "_blank"); // Fallback to opening in a new tab
        }
      } else {
        alert("Document URL could not be retrieved.");
      }
    } catch (error) {
      if (error.response?.status === 404) {
        alert("Document not found. Please verify the document ID.");
      } else {
        console.error("Error editing document:", error);
        alert("An error occurred while trying to edit the document.");
      }
    }
  };
  

  const fetchEditUrl = async (documentId) => {
    console.log("Fetching edit URL for documentId:", documentId);
    try {
      const response = await axios.get(`/api/v1/documents/${documentId}/edit-url`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      return response.data.url;
    } catch (error) {
      console.error("Error fetching edit URL:", error);
      throw error;
    }
  };
  


  
  
  const moveDocuments = async (targetFolderId) => {
    try {
      const response = await axios.put(
        "/api/v1/documents/move",
        { documentIds: selectedDocuments, targetFolderId },
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );

      if (response.status === 200) {
        alert("Documents moved successfully.");
        setDocuments((prevDocs) =>
          prevDocs.filter((doc) => !selectedDocuments.includes(doc._id))
        );
        setSelectedDocuments([]);
      } else {
        alert("Failed to move documents.");
      }
    } catch (error) {
      console.error("Error moving documents:", error);
      alert("An error occurred while moving the documents.");
    }
  };

  const toggleSelectAll = () => {
    const newSelectionState = !selectAll;
    setSelectAll(newSelectionState);

    if (newSelectionState) {
      setSelectedDocuments(documents.map((doc) => doc._id));
    } else {
      setSelectedDocuments([]);
    }
  };

  const toggleDocumentSelection = (docId) => {
    setSelectedDocuments((prev) =>
      prev.includes(docId)
        ? prev.filter((id) => id !== docId)
        : [...prev, docId]
    );
  };

  const handleDocumentSelection = (documentId) => {
    setSelectedDocuments((prev) =>
      prev.includes(documentId)
        ? prev.filter((id) => id !== documentId)
        : [...prev, documentId]
    );
  };

  const handleDownloadSelected = async () => {
    if (selectedDocuments.length === 0) {
      alert("Please select at least one document to download.");
      return;
    }
  
    try {
      // Iterate through selected document IDs
      for (const documentId of selectedDocuments) {
        const response = await axios.get(
          `/api/v1/documents/${documentId}/retrieves`,
          {
            headers: { Authorization: `Bearer ${auth?.token}` },
            responseType: "blob", // Important for downloading binary data
          }
        );
  
        if (response.status === 200) {
          // Create a URL for the file blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
  
          // Use the document name or default name for download
          link.download = response.headers["content-disposition"]
            ? response.headers["content-disposition"]
                .split("filename=")[1]
                .replace(/"/g, "")
            : `Document_${documentId}.pdf`;
  
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
  
          // Revoke the blob URL to free up memory
          window.URL.revokeObjectURL(url);
        } else {
          console.error(`Failed to download document ID: ${documentId}`);
          alert(`Failed to download document: ${documentId}`);
        }
      }
    } catch (error) {
      console.error("Error during document download:", error);
      alert("An error occurred while attempting to download the documents.");
    }
  };
  

  // Function to open the Image Modal
  const openImageModal = () => {
    if (!documents.length) {
      alert("No documents available to display.");
      return;
    }
    setIsImageModalOpen(true);
  };

  // Function to close the Image Modal
  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  // Action handlers for dropdown options

  return (
   
    <Container>
      <h1>Documents</h1>

      <Dropzone {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag & drop some files here, or click to select files</p>
      </Dropzone>

      <FolderActions>
        {folders.length === 0 ? (
          <p>Loading folders...</p>
        ) : (
          <select
            value={selectedFolder || ""}
            onChange={(e) => setSelectedFolder(e.target.value)}
          >
            {folders.map((folder) => (
              <option key={folder._id} value={folder._id}>
                {folder.name}
              </option>
            ))}
          </select>
        )}
         <Dropdown isOpen={menuDropdownOpen} toggle={toggleMenuDropdown}>
        <DropdownToggle caret>Menu</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => console.log("Create Folder")}>
            Create New Folder
          </DropdownItem>
          <DropdownItem onClick={() => console.log("Export Template")}>
            Create Document
          </DropdownItem>
          <DropdownItem onClick={deleteFolder}>
            Delete Folder
          </DropdownItem>
          <DropdownItem
  onClick={handleDownloadSelected}
  disabled={selectedDocuments.length === 0}
>
  Download Selected
</DropdownItem>
          <DropdownItem 
                onClick={() => {
                  if (selectedDocuments.length === 0) {
                    alert("Please select at least one document to move.");
                  } else {
                    toggleMoveModal();
                  }
                }}
                disabled={selectedDocuments.length === 0}
              >
                Move Documents
             
          </DropdownItem>
          <DropdownItem onClick={openImageModal} disabled={!documents.length}>
          Open Image Viewer</DropdownItem>
          <DropdownItem
  onClick={handleEmailSelected}
  disabled={selectedDocuments.length === 0}
>
  Email Selected
</DropdownItem>
        </DropdownMenu>

      </Dropdown>

      </FolderActions>
      {/* Document Table */}
      {selectedFolder ? (
         <div
         className="documents"
         style={{
           width: "100%", /* Ensure full width */
           display: "flex",
           flexDirection: "column",
           margin: "0 auto", /* Optional: Center content horizontally */
         }}
       >
        <Table>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                />
              </th>
              <th>Description</th>
              <th>Create Date</th>
              <th>Modified Date</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc) => (
              <tr key={doc._id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedDocuments.includes(doc._id)}
                    onChange={() => toggleDocumentSelection(doc._id)}
                  />
                </td>
                <td>{doc.name}</td>
                <td>{doc.createdAt ? format(new Date(doc.createdAt), "yyyy-MM-dd") : "N/A"}</td>
<td>{doc.modifiedAt ? format(new Date(doc.modifiedAt), "yyyy-MM-dd") : "N/A"}</td>
                <td>{doc.type}</td>
                <td>
                  <Dropdown
                    isOpen={dropdownOpen[doc._id] || false}
                    toggle={() => doc._id && toggleDropdown(doc._id)}
                  >
                    <DropdownToggle className="bg-transparent border">
                      <FaEllipsisV color="black" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => handleEditDocument(doc._id)}
                      >
                        Edit Document
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this document?"
                            )
                          ) {
                            if (doc.documentId) {
                              deleteDocument(doc.documentId);
                            } else {
                              console.error(
                                "Document ID is undefined for:",
                                doc
                              );
                            }
                          }
                        }}
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table></div>
      ) : (
        <p>Please select a folder to view documents.</p>
      )}
      <TemplateExportModal
        isOpen={isTemplateModalOpen}
        onClose={closeTemplateModal}
        onExport={handleTemplateExport}
        locationId={locationId}
      />

      

      {/* Move Documents Modal */}
      <Modal isOpen={isMoveModalOpen} toggle={toggleMoveModal}>
        <ModalHeader toggle={toggleMoveModal}>Move Documents</ModalHeader>
        <ModalBody>
          <p>Select a folder to move the selected documents:</p>
          <select
            value={targetFolder}
            onChange={(e) => setTargetFolder(e.target.value)}
            style={{ width: "100%", padding: "8px" }}
          >
            <option value="" disabled>
              Select a folder
            </option>
            {folders.map((folder) => (
              <option key={folder._id} value={folder._id}>
                {folder.name}
              </option>
            ))}
          </select>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              if (!targetFolder) {
                alert("Please select a folder.");
              } else {
                await moveDocuments(targetFolder);
                toggleMoveModal();
              }
            }}
          >
            Move
          </Button>
          <Button color="secondary" onClick={toggleMoveModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
    
  );
};

export default DocumentManagement;
