import React from 'react';
import styled from 'styled-components';

const MainContainer = styled.div`
  display: flex;
  background: white;
  padding:10px;
  margin:30px;
  border-radius:8px;
  flex-direction:column;
  flex-grow:1;
`;


const NoPage = () => {
  return (
      <>
      <MainContainer>
      </MainContainer>
      </>
  );
};

export default NoPage;