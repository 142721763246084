import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import usePolicyTypes from '../../../hooks/usePolicyTypes';
import * as Yup from 'yup';
import Button from '../../common/Button';
import { FaRegBuilding } from 'react-icons/fa';


const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width:100%;
  height:100%;
`;

const BtnContainer = styled.div`
  margin: 30px;
  display: flex;
  justify-content:space-between;
`;

const FieldContainer = styled.div`
  flex: 0 0 calc(50% - 20px); /* Two fields per row with some space */
  flex-wrap: wrap;
`;

const Field = styled.div`
  margin: 30px 10px 10px 30px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding:10px;
  align-self: flex-start;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

const SelectInput = styled.select`
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
`;


const Option = styled.option`
  font-size: 14px;
`;


const PolicyDetails = ({formData, setFormData, setStep}) => {

  const policytypes=usePolicyTypes()
    // Define Yup validation schema
   const ValidationSchema = Yup.object().shape({
    policyDetails: Yup.object().shape({
      policyNumber: Yup.string().required('Policy number is required'),
      typeOfPolicyId: Yup.string().required('Type of policy is required'),
      name: Yup.string().required('Name is required'),
      deductible: Yup.number().required('Deductible is required'),
      address: Yup.string().required('Address is required'),
      city: Yup.string().required('city is required'),
      province: Yup.string().required('Province is required'),
      postalCode: Yup.string().required('postal code is required'),
      effectiveDate: Yup.date().required('Effective date is required'),
      expiryDate: Yup.date().required('Exipry date is required')
  }),
});

const [errors, setErrors] = useState({});

const handleNext = async() => {
  try {
    await ValidationSchema.validate(formData, { abortEarly: false });
    setStep((prevStep) => prevStep + 1);
  } catch (err) {
    const validationErrors = {};
    err?.inner?.forEach((error) => {
      validationErrors[error.path] = error.message;
    });
    setErrors(validationErrors);
  }
};

const handlePrevious = () => {
  setStep((prevStep) => prevStep - 1);
};


useEffect(() => {
  if (formData.policyDetails.effectiveDate) {
    const date = new Date(formData.policyDetails.effectiveDate);
    date.setFullYear(date.getFullYear() + 1);
    setFormData(prevFormData => ({
      ...prevFormData,
      policyDetails: {
        ...prevFormData.policyDetails,
        expiryDate: date.toISOString().split("T")[0],
      },
    }));
  }
}, [formData.policyDetails.effectiveDate]);


  return (
    <>
        <h2>Policy Details</h2>
        <Container>
          <FieldContainer>
          <Field>
              <Label>Name</Label>
              <Input 
                type="text" 
                name="name" 
                value={formData.policyDetails.name} 
                onChange={(e) => setFormData({
                    ...formData,
                    policyDetails: { ...formData.policyDetails, name: e.target.value }
                  })} 
                />
                {errors && (
                  <div>
                     {errors['policyDetails.name'] && (
                    <div>{errors['policyDetails.name']}</div>
                     )}
                  </div>
                  )}
            </Field>
            <Field>
              <Label>Phone Number</Label>
              <Input 
                type="text" 
                name="PhoneNumber" 
                value={formData.policyDetails.phone} 
                onChange={(e) => setFormData({
                    ...formData,
                    policyDetails: { ...formData.policyDetails, phone: e.target.value }
                  })} 
                />
                {errors && (
                  <div>
                     {errors['policyDetails.phone'] && (
                    <div>{errors['policyDetails.phone']}</div>
                     )}
                  </div>
                  )}
            </Field>
            <Field>
              <Label>Policy #</Label>
              <Input 
                type="text" 
                name="policyNumber" 
                value={formData.policyDetails.policyNumber} 
                onChange={(e) => setFormData({
                    ...formData,
                    policyDetails: { ...formData.policyDetails, policyNumber: e.target.value }
                  })} />
                  {errors && (
                  <div>
                     {errors['policyDetails.policyNumber'] && (
                    <div>{errors['policyDetails.policyNumber']}</div>
                     )}
                  </div>
                  )}
                  
            </Field>
            <Field>
            <Label htmlFor="typeOfPolicyId">Policy Type</Label>
            <SelectInput 
              id="typeOfPolicyId" 
              name="typeOfPolicyId" 
              value={formData.policyDetails.typeOfPolicyId} 
              onChange={(e) => setFormData({
                ...formData,
                policyDetails: { ...formData.policyDetails, typeOfPolicyId: e.target.value }
              })} 
              >
             <Option value="">Select Policy type</Option>
             {policytypes?.map(policyType => (
             <Option 
             key={policyType._id} 
             value={policyType._id}>
              {policyType.description}
              </Option>
            ))}
             </SelectInput>
             {errors && (
                  <div>
                     {errors['policyDetails.typeOfPolicyId'] && (
                    <div>{errors['policyDetails.typeOfPolicyId']}</div>
                     )}
                  </div>
                  )}
            </Field>
                        <Field>
              <Label>Deductible</Label>
              <Input 
                type="number" 
                name="deductible" 
                value={formData.policyDetails.deductible} 
                onChange={(e) => setFormData({
                    ...formData,
                    policyDetails: { ...formData.policyDetails, deductible: e.target.value }
                  })} 
                />
                {errors && (
                  <div>
                     {errors['policyDetails.deductible'] && (
                    <div>{errors['policyDetails.deductible']}</div>
                     )}
                  </div>
                  )}
            </Field>
            <Field>
              <Label>Effective Date</Label>
              <Input 
                type="date" 
                name="effectiveDate" 
                value={formData.policyDetails.effectiveDate} 
                onChange={(e) => {
                  const selectedDate = new Date(e.target.value);
                  const currentDate = new Date();
                  if (selectedDate <= currentDate) {
                    setFormData({
                      ...formData,
                      policyDetails: { ...formData.policyDetails, effectiveDate: e.target.value }
                    });
                  } else {
                    setErrors({...errors, 'policyDetails.effectiveDate': 'Effective date cannot be a future date'});
                  }
                }} 
                max={new Date().toISOString().split("T")[0]}
              />  
              {errors && (
                <div>
                  {errors['policyDetails.effectiveDate'] && (
                    <div>{errors['policyDetails.effectiveDate']}</div>
                  )}
                </div>
              )}
            </Field>
          </FieldContainer>
          <FieldContainer>
          <Field>
              <Label>Expiry Date</Label>
              <Input 
                type="date" 
                name="expiryDate" 
                value={formData.policyDetails.expiryDate} 
                onChange={(e) => setFormData({
                    ...formData,
                    policyDetails: { ...formData.policyDetails, expiryDate: e.target.value }
                  })} 
                />  
                {errors && (
                  <div>
                     {errors['policyDetails.expiryDate'] && (
                    <div>{errors['policyDetails.expiryDate']}</div>
                     )}
                  </div>
                  )}
            </Field>
            <Field>
              <Label>Address</Label>
              <Input 
                type="text" 
                name="address" 
                value={formData.policyDetails.address} 
                onChange={(e) => setFormData({
                    ...formData,
                    policyDetails: { ...formData.policyDetails, address: e.target.value }
                  })} />
                  {errors && (
                  <div>
                     {errors['policyDetails.address'] && (
                    <div>{errors['policyDetails.address']}</div>
                     )}
                  </div>
                  )}
            </Field>
            <Field>
              <Label>City</Label>
              <Input 
                type="text" 
                name="city" 
                value={formData.policyDetails.city} 
                onChange={(e) => setFormData({
                    ...formData,
                    policyDetails: { ...formData.policyDetails, city: e.target.value }
                  })} />
                  {errors && (
                  <div>
                     {errors['policyDetails.city'] && (
                    <div>{errors['policyDetails.city']}</div>
                     )}
                  </div>
                  )}
            </Field>
            <Field>
              <Label>Province</Label>
              <Input 
                type="text" 
                name="province" 
                value={formData.policyDetails.province} 
                onChange={(e) => setFormData({
                    ...formData,
                    policyDetails: { ...formData.policyDetails, province: e.target.value }
                  })} 
                />
                {errors && (
                  <div>
                     {errors['policyDetails.province'] && (
                    <div>{errors['policyDetails.province']}</div>
                     )}
                  </div>
                  )}
            </Field>
            <Field>
              <Label>Postal Code</Label>
              <Input 
                type="text" 
                name="postalCode" 
                value={formData.policyDetails.postalCode} 
                onChange={(e) => setFormData({
                    ...formData,
                    policyDetails: { ...formData.policyDetails, postalCode: e.target.value }
                  })} 
                />
                {errors && (
                  <div>
                     {errors['policyDetails.postalCode'] && (
                    <div>{errors['policyDetails.postalCode']}</div>
                     )}
                  </div>
                  )}
            </Field>
          </FieldContainer>
          </Container>
          <BtnContainer>
      <Button type="button" onClick={handlePrevious}>
        Previous
      </Button>
      <Button type="button" onClick={handleNext}>
        Next
      </Button>
      </BtnContainer>
        </>
  )
}

export default PolicyDetails
