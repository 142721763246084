import React from "react";
import styled from "styled-components";

const FieldContainer = styled.div`
  flex: 0 0 calc(50% - 20px); /* Two fields per row with some space */
  flex-wrap: wrap;
`;

const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left: 20px;
  height: 40px;
`;

const Field = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding: 10px;
  align-self: flex-start;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

const RoleForm = ({ handleSubmit, formData, setFormData, title }) => {
  return (
    <FieldContainer>
      <h2>{title}</h2>
      <form onSubmit={handleSubmit}>
        <Field>
          <Label>Enter Role</Label>
          <Input
            type="text"
            name="role"
            value={formData.role}
            onChange={(e) => setFormData({ ...formData, role: e.target.value })}
          />
        </Field>
        <div>
          <Btn type="submit">Submit</Btn>
        </div>
      </form>
    </FieldContainer>
  );
};

export default RoleForm;
