import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "../common/Modal";
import { FaEllipsisV } from "react-icons/fa";
import AddPartyForm from "./AddPartyForm";
import { useAuth } from "../../context/auth";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import ConfirmationModal from "../../shared/Confirmation";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: auto; /* Allow the container to grow as needed */
`;


const HeaderContainer = styled.div`
  height: 5vh;
  border-radius: 4px 4px 0 0;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  overflow: visible; /* Allow the container to expand as content grows */
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

// Styled components to mimic table structure
const Table = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.div`
  display: table-row;
`;

const TableHeader = styled.div`
  display: table-cell;
  border-radius: 4px 4px 0 0;
  padding: 10px;
  background-color: #f5f5f5;
  font-weight: bold;
  text-align: left;
  border-bottom: 4px solid #ddd;
`;

const TableCell = styled.div`
  display: table-cell;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

// Button styled component
const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left: 20px;
  width: 100px;
  &:hover {
    background-color: #0069d9;
  }
`;

const Parties = ({ claimId, claimData }) => {
  const [auth, setAuth] = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [parties, setParties] = useState([]);
  const [selectedParty, setSelectedParty] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    claimRole: "",
    phone: [], // Default to empty array
    email: [], // Default to empty array
    address: "",
    city: "",
    province: "",
    postal_code: "",
  });
  
  const openEmail = (emailAddress) => {
    if (!claimData) {
      toast.error("Claim data is not available.");
      return;    }      const subject = `Insured: ${claimData.policyDetails.name} / Claim Number: ${claimData.clients[0].claimNumber} / File Number: ${claimData.fileNumber}`;
    const body = generateEmailBody(claimData);  
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const generateEmailBody = (claimData) => `
  Insured: ${claimData.policyDetails.name}
  Mailing Address: ${claimData.policyDetails.address.addressLine}, ${claimData.policyDetails.address.city}, ${claimData.policyDetails.address.province}, ${claimData.policyDetails.address.postalCode}
  Loss Address: ${claimData.lossAddress}, ${claimData.lossCity}, ${claimData.lossProvince}, ${claimData.lossPostalCode}
  Insurer: ${claimData.clients[0].client.name}
  Policy Number: ${claimData.policyDetails.policyNumber}
  Claim #: ${claimData.clients[0].claimNumber}
  Our File #: ${claimData.fileNumber}
`;

  useEffect(() => {
    fetchParties();
  }, [claimId]);
  const [openDropdown, setOpenDropdown] = useState(null);

  const openConfirmationModal = (id) => {
    setSelectedParty(id);
    setIsConfirmationModalOpen(true);
  };

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const ViewPartyModal = ({ isOpen, onClose, party }) => (
    <Modal open={isOpen} onClose={onClose}>
  <h5>Party Details</h5>
  
  {party ? (
    <><Row style={{ borderBottom: "1px solid #ccc" }}><Col>
      <p>
        <strong>Name:</strong> 
      </p></Col><Col>
      <p>{party.name}</p>
      <p>{party.address}</p>
      <p>{party.city}, {party.province} {party.postal_code}
      </p></Col></Row><Row><Col>
      <p>
        <strong>Claim Role:</strong></p></Col> <Col><p>{party.claimRole}</p></Col>
      </Row>
      <p>
        <strong>Company:</strong> {party.company}
      </p>
      {/* Display multiple emails with labels */}
      <Row><Col>
      <p>
        <strong>Email:</strong>
      </p></Col><Col>
      {Array.isArray(party.email) ? (
        party.email.map((emailObj, index) => (
          <p
            key={index}
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => openEmail(emailObj.address)}
          >
            {emailObj.label}: {emailObj.address}
          </p>
        ))
      ) : (
        <p
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => openEmail(party.email)}
        >
          {party.email}
        </p>
      )}</Col></Row>
      {/* Display multiple phones with labels */}
      <p>
        <strong>Phone:</strong>
      </p>
      {Array.isArray(party.phone) ? (
        party.phone.map((phoneObj, index) => (
          <p key={index}>
            {phoneObj.label}: {phoneObj.number}
          </p>
        ))
      ) : (
        <p>{party.phone}</p>
      )}
    </>
  ) : (
    <p>Party details are not available.</p>
  )}
</Modal>

  );

  const fetchParties = async () => {
    try {
      const { data } = await axios.get(`/api/v1/parties?claim_id=${claimId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      if (data.success) {
        setParties(data?.parties1);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while getting the parties");
    }
  };

  const handleAddContact = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `/api/v1/parties`,
        {
          ...formData,
          claim_id: claimId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
  
      if (data.success) {
        toast.success("Party created successfully");
        setFormData({
          name: "",
          company: "",
          claimRole: "",
          phone: [],
          email: [],
          address: "",
          city: "",
          province: "",
          postal_code: "",
        });
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Error creating party.");
    }
  };
  

  const handleEditClick = (party) => {
    setSelectedParty(party);
    setFormData({
      name: party?.name || "",
      company: party?.company || "",
      phone: Array.isArray(party?.phone) ? party.phone : [{ number: party.phone || "", label: "Cell" }],
      email: Array.isArray(party?.email) ? party.email : [{ address: party.email || "", label: "Primary" }],
      address: party?.address || "",
      city: party?.city || "",
      province: party?.province || "",
      claimRole: party?.claimRole || "",
      postal_code: party?.postal_code || "",
    });
    setIsModalOpen(true);
  };
  
  const handleDeleteClick = async () => {
    if (!selectedParty) return;
    try {
      const { data } = await axios.delete(`/api/v1/parties/${selectedParty}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (data.success) {
        fetchParties();
        toast.success("Party deleted successfully");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while deleting the party");
    }
    setIsConfirmationModalOpen(false);
  };

  const handleUpdateContact = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `/api/v1/parties/${selectedParty._id}`,
        { ...formData },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (data.success) {
        fetchParties();
        setSelectedParty(null);
        setIsModalOpen(false);
        setFormData({
          name: "",
          company: "",
          phone: [{ number: "", label: "Cell" }], // Default to empty object
          email: [{ address: "", label: "Primary" }], // Default to empty object
          claimRole: "",
          address: "",
          city: "",
          province: "",
          postal_code: "",
        });
        toast.success("Party updated successfully");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while updating the party");
    }
  };

  const handleViewParty = (party) => {
    setSelectedParty(party);
    setIsViewModalOpen(true);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const filteredParties = parties.filter(
    (party) =>
      party.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      party.company?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      party.claimRole?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  

  return (
    <>
      <Container>
        <HeaderContainer>
          <h5>Parties</h5>
        </HeaderContainer>

        <Btn
          onClick={() => {
            setFormData({
              name: "",
              company: "",
              claimRole: "",
              phone: "",
              email: "",
              address: "",
              city: "",
              province: "",
              postal_code: "",
            });
            setSelectedParty(null);
            setIsModalOpen(true);
          }}
        >
          Add
        </Btn>

        <TableContainer>
          <Table>
            <TableRow>
            <TableHeader>Name</TableHeader>
              <TableHeader>Claim Role</TableHeader>
              <TableHeader>Company</TableHeader>              
              <TableHeader>Email</TableHeader>
              <TableHeader>Phone</TableHeader>
              <TableHeader>Action</TableHeader>
            </TableRow>
            {parties?.map((row, index) => (
              <TableRow key={row.name}>
                   <TableCell
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => handleViewParty(row)}
                >
                  {row.name}
                </TableCell>           
               
                <TableCell>{row.claimRole}</TableCell>
                <TableCell>{row.company}</TableCell>
                <TableCell>
                  {Array.isArray(row.email) ? (
                    row.email.map((emailObj, index) => (
                      <span
                        key={index}
                        style={{ cursor: "pointer", color: "blue" }}
                        onClick={() => openEmail(emailObj.address)}
                      >
                        {emailObj.label}: {emailObj.address}
                        {index < row.email.length - 1 && ", "}
                      </span>
                    ))
                  ) : (
                    <span
                      style={{ cursor: "pointer", color: "blue" }}
                      onClick={() => openEmail(row.email)}
                    >
                      {row.email}
                    </span>
                  )}
                </TableCell>

                <TableCell>
                  {Array.isArray(row.phone)
                    ? row.phone.map((phoneObj, index) => (
                        <span key={index}>
                          {phoneObj.label}: {phoneObj.number}
                          {index < row.phone.length - 1 && ", "}
                        </span>
                      ))
                    : row.phone}
                </TableCell>

                <TableCell>
                  <Dropdown
                    isOpen={openDropdown === index}
                    toggle={() => toggleDropdown(index)}
                    direction="up"
                  >
                    <DropdownToggle className="bg-transparent border">
                      <FaEllipsisV color="black" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleEditClick(row)}>
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => openConfirmationModal(row._id)}
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </TableCell>
              </TableRow>
            ))}
          </Table>

          <ConfirmationModal
            isOpen={isConfirmationModalOpen}
            toggle={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
            title="Confirm Action"
            body="Are you sure you want to delete this party?"
            onConfirm={handleDeleteClick}
          />

<Modal
  open={isModalOpen}
  onClose={() => {
    setIsModalOpen(false);
    fetchParties(); // Call fetchParties when the modal is closed
  }}
>
  <AddPartyForm
    formData={formData}
    setFormData={setFormData}
    handleSubmit={
      selectedParty ? handleUpdateContact : handleAddContact
    }
    title={selectedParty ? "Update Party" : "Add Party"}
  />
</Modal>


          <ViewPartyModal
            isOpen={isViewModalOpen}
            onClose={() => setIsViewModalOpen(false)}
            party={selectedParty}
          />
        </TableContainer>
      </Container>
    </>
  );
};

export default Parties;