import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';
import { useAuth } from '../../../context/auth';

const BtnContainer = styled.div`
  margin: 30px;
  display: flex;
  justify-content: space-between;
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  width: 100%;
  height: 100%;
`;

const FieldContainer = styled.div`
  padding: 20px;
`;

const ClientSelector = ({ formData, setFormData, setStep }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [client, setClient] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [sharePercentage, setSharePercentage] = useState('');
  const [claimNumber, setClaimNumber] = useState('');
  const [rate, setRate] = useState('');
  const [auth] = useAuth();
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);



  // Fetch locations for dropdown
  useEffect(() => {
    axios
      .get(`/api/v1/locations`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        console.log(response.data.locations); // Debug the response
        setLocations(response.data.locations || []); // Fallback to an empty array
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Failed to fetch locations');
      });
  }, [auth]);
  
  

  // Define Yup validation schema
  const ValidationSchemaClient = Yup.object().shape({
    clients: Yup.array().min(1, 'At least one client is required'),
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (searchQuery) {
      axios
        .get(`/api/v1/clients/search/${searchQuery}?includeContacts=false`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((response) => {
          setClient(response.data.clients[0]); // Assuming you need the first match
          if (
            response.data.clients[0]?.rate !== undefined &&
            formData.clients.length === 0
          ) {
            setRate(response.data.clients[0].rate);
          }
        })
        .catch((error) =>
          toast.error(error?.response?.data?.message || "Error during search")
        );
    } else {
      setClient(null);
      setRate("");
    }
  }, [searchQuery, formData.clients]);
  

  const handleAddClient = () => {
    // Validate input fields
    if (
      !selectedClient ||
      !sharePercentage ||
      !claimNumber ||
      !selectedLocation ||
      (formData.clients.length === 0 && !rate) // Only check `rate` for the first client
    ) {
      toast.error('All fields are required');
      return;
    }
  
    // Prepare the new client object
    const newClient = {
      ...selectedClient,
      sharePercentage: parseFloat(sharePercentage),
      claimNumber: claimNumber,
      rate: formData.clients.length === 0 ? parseFloat(rate) : undefined, // Only set rate for the first client
      locationId: selectedLocation._id,
    };
  
        // Ensure total share percentage does not exceed 100%
    const totalPercentage =
      formData?.clients?.reduce((sum, client) => sum + client.sharePercentage, 0) +
      newClient.sharePercentage;
    if (totalPercentage > 100) {
      toast.error('Total share percentage cannot exceed 100%.');
      return;
    }
  
    // Add the new client to the list
    setFormData({
      ...formData,
      clients: [...formData?.clients, newClient],
    });
  
    // Reset the fields for adding another client
    setSelectedClient(null);
    setSharePercentage('');
    setClaimNumber('');
    if (formData.clients.length === 0) {
      setRate(''); // Only reset rate if this was the first client
    }
  };
  
  

  const handleRemoveClient = (clientId) => {
    const updatedClients = formData.clients.filter((client) => client._id !== clientId);
    setFormData({
      ...formData,
      clients: updatedClients,
    });
  };

  const handleNext = async () => {
    try {
      await ValidationSchemaClient.validate(formData, { abortEarly: false });
      setStep((prevStep) => prevStep + 1);
    } catch (err) {
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  return (
    <Container>
      <div>
        <h2>Assign Location</h2>
        <Autocomplete
  options={locations}
  getOptionLabel={(option) => option.location_name || ''}
  value={selectedLocation}
  onChange={(event, newValue) => {
    setSelectedLocation(newValue);
    setFormData((prevData) => ({
      ...prevData,
      locationId: newValue?._id || '', // Assign the selected location's ID
    }));
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Search Location"
      placeholder="Type to search..."
      fullWidth
    />
  )}
/>

        {errors.location && <div>{errors.location}</div>}

        <h2>Add Clients</h2>
        <TextField
          label="Search Client"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          fullWidth
        />
        {client && (
          <List>
            <ListItem onClick={() => setSelectedClient(client)}>
              <ListItemText primary={client.name} />
            </ListItem>
          </List>
        )}
        <Dialog open={!!selectedClient} onClose={() => setSelectedClient(null)}>
          <DialogTitle>Add Client</DialogTitle>
          <DialogContent>
            {selectedClient && <Typography>{selectedClient.name}</Typography>}
            <FieldContainer>
              <TextField
                label="Claim Number"
                type="text"
                value={claimNumber}
                onChange={(e) => setClaimNumber(e.target.value)}
                fullWidth
              />
            </FieldContainer>
            <FieldContainer>
              <TextField
                label="Share Percentage"
                type="number"
                value={sharePercentage}
                onChange={(e) => setSharePercentage(e.target.value)}
                fullWidth
              />
            </FieldContainer>
            {formData.clients.length === 0 && (
              <FieldContainer>
                <TextField
                  label="Rate"
                  type="number"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                  fullWidth
                />
              </FieldContainer>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedClient(null)}>Cancel</Button>
            <Button onClick={handleAddClient} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <List>
          {formData.clients &&
            formData.clients.map((client, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`Client: ${client.name} - Share: ${client.sharePercentage}% Claim Number: ${client.claimNumber}`}
                />
                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveClient(client._id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
        </List>
        {errors.clients && <div>{errors.clients}</div>}
        <BtnContainer>
          <Button type="button" onClick={handleNext}>
            Next
          </Button>
        </BtnContainer>
      </div>
    </Container>
  );
};

export default ClientSelector;