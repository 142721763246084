import React, { useState, useEffect } from "react";
import ClaimsForm from "../../components/claims/UpdateClaimsForm";
import styled from "styled-components";
import Details from "../../components/claims/details";
import PolicyData from "../../components/claims/policyData";
import Tasks from "../../components/claims/tasks";
import Documents from "../../components/claims/documents";
import Reserves from "../../components/claims/reserves";
import TimeExpenses from "../../components/claims/TimeExpenses";
import Notes from "../../components/claims/notes";
import Parties from "../../components/claims/parties";
import sorter from "../../assets/sorter.png";
import searchIcon from "../../assets/search.png";
import hamburger from "../../assets/hamburger.png";
import Layout from "../../components/Layout/Layout";
import toast from "react-hot-toast";
import axios from "axios";
import { Modal } from "antd";
import List from "../../components/common/List";
import SearchBox from "../../components/common/SearchBox";
import Button from "../../components/common/Button";
import { useAuth } from "../../context/auth";
import { useParams } from "react-router-dom";
import PolicyDetailForm from "../../components/claims/policyDetailsForm";
import { Col, Row } from "reactstrap";

const StatusIcon = styled.span`
  display: inline-block;
  width: auto;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: ${(props) => (props.isOpen ? "green" : "red")};
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  align-self: center; // Ensures alignment with the Options button
  margin-left: 10px;
`;

const MainContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: auto;
    height: auto;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const ClaimText = styled.div`
  height: 100px;
  width: auto;
  display: flex;
  font-size: 30px;
  align-items: center;
`;

const Input = styled.input`
  width: 180px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  padding: 10px;
`;

const SeacrchBtn = styled.span`
  width: 40px;
  margin-left: 5px;
  margin-right: 5px;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled.button`
  background-color: #6495ed;
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial;
  border: none;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  height: 30px;
  width: 100px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: absolute;
  top: 35px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownItem = styled.div`
  padding: 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: url(${hamburger});
  width: 44px;
  height: 44px;
  margin: 20px;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const HeadContainer = styled.div`
  padding: 20px;
  position: relative;
  z-index: 1000; /* Ensure it stays above other content */
  width: auto;
  margin: 20px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const SubHeadContainer = styled.div`
  padding: 0px;
  margin: 0px;
  width: auto;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  flex: 0 0 calc(50% - 20px); /* Two items per row with some space */
`;

const Key = styled.span`
  color: rgba(0, 0, 0, 45%);
  font-size: 14px;
`;

const Value = styled.span`
  color: #000000;
`;

const KeyValue = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const DetailsContainer = styled.div`
  padding: 20px;
  margin: 20px;
  flex-grow: 1;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  width: auto;
  border-collapse: collapse;
  margin: 5px;
  padding: 1px;
`;

const TableHeader = styled.th`
  padding: 5px;
  position: relative;
  text-align: left;
  color: #444444;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.20000000298023224px;
`;

const SorterIcon = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 36px;
  margin-left: 5px;
  background-image: url(${sorter}); // Use the sorter icon image
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer; // Add cursor pointer for hover effect
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  &:hover {
    background: #e0e4f3;
    border-radius: 8px;
  }
`;

const TableCell = styled.td`
  text-align: left;
  padding: 8px;
  padding: 7px;
  color: #9fa2b4;
`;

const TitlesContainer = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  justify-content: space-between;
  border-bottom: 4px solid #e0e4f3;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Links = styled.a`
  text-decoration: none;
  cursor: pointer;
  background: ${(props) => (props.isActive ? "#bdd2f9" : "transparent")};
  padding: 8px;
  font-family: arial, sans-serif;
  border-radius: 4px 4px 0px 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.2px;
  transition: background-color 0.3s ease;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    background-color: ${(props) => (props.isActive ? "#bdd2f9" : "#e0e4f3")};
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-grow: ;
`;

const MenuContainer = styled.div`
  flex-direction: column;
  display: flex;
  position: fixed;
  z-index: 1000;
  width: 15%;
  height: 100%; /* Ensure it spans the full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  @media only screen and (max-width: 768px) {
    display: ${(props) => (props.open ? "block" : "none")};
  }
`;


const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 80%;
  height: 80%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box; // Ensures padding is included in total width/height
  overflow-y: auto; // Allows vertical scrolling if content overflows
  overflow-x: hidden; // Hides any horizontal overflow

  @media only screen and (max-width: 768px) {
    width: 90%;
    height: 90%;
  }
`;

const Claims = () => {
  const [claims, setClaims] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState();
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");

  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
  const [auth] = useAuth();
  const { fileNumber } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const [selectedLink, setSelectedLink] = useState("parties");
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const [statusDropdownVisible, setStatusDropdownVisible] = useState(false); // State to control dropdown visibility
  const [formData, setFormData] = useState({
    dateOfLoss: "",
    proscriptionDate: "",
    typeOfClaimId: "",
    typeOfLossId: "",
    lossAddress: "",
    lossCity: "",
    lossProvince: "",
    lossPostalCode: "",
    clients: [], // Initialize as an empty array
    mileage_rate: "",
    cityAuto: "",
    adjusterId: "",
    examinerId: "",
    lossDetail: "",
    policyDetails: {
      policyNumber: "",
      typeOfPolicyId: "",
      effectiveDate: "",
      expiryDate: "",
      deductible: "",
      name: "",
      address: {
        addressLine: "",
        city: "",
        province: "",
        postalCode: "",
      },
    },
    brokerId: "",
    claimStatus: "",
    rate: "",
  });

  // Later, dynamically add `clients` using an update
  // Function to toggle dropdown visibility
  const toggleStatusDropdown = () => {
    setStatusDropdownVisible((prev) => !prev);
  };

const updateClaimStatus = async (newStatus) => {
  if (newStatus === 0) {
    // Show confirmation prompt for closing the claim
    Modal.confirm({
      title: "Are you sure?",
      content: "Do you really want to close this claim? All open tasks will be closed",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.put(
            `/api/v1/claims/${selectedClaim._id}/status`,
            { claimStatus: newStatus },
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
              },
            }
          );

          if (data.success) {
            setSelectedClaim((prev) => ({ ...prev, claimStatus: newStatus }));
            setClaims((prev) =>
              prev.map((claim) =>
                claim._id === selectedClaim._id
                  ? { ...claim, claimStatus: newStatus }
                  : claim
              )
            );
            toast.success(`Claim ${newStatus === 1 ? "re-opened" : "closed"} successfully.`);
          } else {
            toast.error(data.message || "Failed to update claim status.");
          }
        } catch (error) {
          toast.error(error.response?.data?.message || "An unexpected error occurred.");
        } finally {
          setStatusDropdownVisible(false); // Ensure dropdown closes after action
        }
      },
      onCancel: () => {
        // Do nothing, just close the confirmation dialog
        setStatusDropdownVisible(false);
      },
    });
  } else {
    // Directly update status for re-opening
    try {
      const { data } = await axios.put(
        `/api/v1/claims/${selectedClaim._id}/status`,
        { claimStatus: newStatus },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        setSelectedClaim((prev) => ({ ...prev, claimStatus: newStatus }));
        setClaims((prev) =>
          prev.map((claim) =>
            claim._id === selectedClaim._id
              ? { ...claim, claimStatus: newStatus }
              : claim
          )
        );
        toast.success(`Claim re-opened successfully.`);
      } else {
        toast.error(data.message || "Failed to update claim status.");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An unexpected error occurred.");
    } finally {
      setStatusDropdownVisible(false); // Ensure dropdown closes
    }
  }
};

  
  
  
  
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle dropdown visibility
  };

  const handleDropdownAction = (action) => {
    console.log(`Action selected: ${action}`);
    // Add logic for the specific action
    setIsDropdownOpen(false); // Close the dropdown after an action
  };

  const handleUpdateClick = () => {
    setIsModalOpen(true); // Assuming setIsModalOpen is used to show a modal
  };

  const getClaims = async () => {
    try {
      const { data } = await axios.get("/api/v1/claims", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      if (data.success) {
        // Sort claims by file number, excluding the first four numbers and the dash
        const sortedClaims = data.claims.sort((a, b) => {
          const fileNumA = parseInt(a.fileNumber.slice(5), 10); // Start from index 5 to skip "YYYY-"
          const fileNumB = parseInt(b.fileNumber.slice(5), 10);
          return fileNumB - fileNumA;
        });

        // Filter claims by the provided fileNumber
        const matchingClaim = sortedClaims.find(
          (claim) => claim?.fileNumber === fileNumber
        );

        setClaims(sortedClaims);
        setLoading(false);

        // Set the selected claim if there's a matching one
        if (matchingClaim) {
          setSelectedClaim(matchingClaim);
          setSelectedLink("parties");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSubmit = async (e) => {
    const url = "/api/v1/claims";
    e.preventDefault();

    try {
      const res = await axios.post(
        url,
        {
          formData,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (res && res.data.success) {
        console.log(res.data.claim);
        setClaims([res.data.claim, ...claims]);
        setFormData({
          dateOfLoss: "",
          proscriptionDate: "",
          typeOfClaimId: "",
          typeOfLossId: "",
          lossAddress: "",
          lossCity: "",
          lossProvince: "",
          lossPostalCode: "",
          clients: [], // Initialize as an empty array
          mileage_rate: "",
          cityAuto: "",
          adjusterId: "",
          examinerId: "",
          lossDetail: "",
          policyDetails: {
            policyNumber: "",
            typeOfPolicyId: "",
            effectiveDate: "",
            expiryDate: "",
            deductible: "",
            name: "",
            address: {
              addressLine: "",
              city: "",
              province: "",
              postalCode: "",
            },
          },
          brokerId: "",
          claimStatus: "",
          rate: "",
        });

        // Later, dynamically add `clients` using an update

        toast.success(res.data && res.data.message);
      } else {
        toast.error(JSON.stringify(res.data.message));
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSearch = async () => {
    if (query === "") {
      getClaims();
    } else {
      try {
        const { data } = await axios.get(`/api/v1/claims/search/${query}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        if (data.success) {
          console.log("awdaw", data);
          // const foundClaim = data.claim;
          // setClaims([
          //   foundClaim,
          //   ...claims.filter((claim) => claim._id !== foundClaim._id),
          // ]);
          setClaims(data?.claim);
          toast.success(data.message);
        } else {
          console.log(data, "Awdwa");
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error searching claim", error);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleSelectClick = (claim) => {
    setSelectedClaim(claim);
    // setSelectedClaim(claim);
    setSelectedLink("parties");
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle menu state
  };

  useEffect(() => {
    getClaims();
  }, []);

  const handlePolicyModal = () => {
    setIsPolicyModalOpen(true);
  };
  
  const handleUpdateModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Layout title="Claims - User">
      <Row>
        <Col md={12}>
          {loading ? (
            <>Loading...</>
          ) : (
            <>
              <MainContainer>
                <Col md={2}>
                          
                                    
                  <div>
                    <MenuContainer open={isOpen}>
                      <h1>Claims</h1>
                      <SearchBox
                        query={query}
                        setQuery={setQuery}
                        onSearch={handleSearch}
                      />
                      <TableContainer>
                        {loading ? (
                          <>Loading...</>
                        ) : (
                          <Table>
                            <thead>
                              <TableRow>
                                <TableHeader>
                                  Claims<SorterIcon />
                                </TableHeader>
                              </TableRow>
                            </thead>
                            <tbody>
                              {claims.length > 0 ? (
                                claims.map((claim) => (
                                  <TableRow
                                    key={claim._id}
                                    onClick={() => handleSelectClick(claim)}
                                  >
                                    <TableCell component="th" scope="row">
                                      {claim.fileNumber}<br />
                                      <strong>- {claim?.policyDetails?.name}</strong>
                                    </TableCell>
                            
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan="6">
                                    No items available
                                  </TableCell>
                                </TableRow>
                              )}
                            </tbody>
                          </Table>
                        )}
                      </TableContainer>
                    </MenuContainer>
                  </div>
                </Col>

                <Col md={10}>
                  {selectedClaim ? (
                    <>
                      <HeadContainer>
                        <SubHeadContainer>
                          <Item>
                            <KeyValue>
                              <Key>File #:</Key>{" "}
                              <Value>{selectedClaim.fileNumber}</Value>
                            </KeyValue>
                            <KeyValue>
                              <Key>Name:</Key>{" "}
                              <Value>
                                {selectedClaim?.policyDetails?.name}
                              </Value>
                            </KeyValue>
                            <KeyValue>
                              <Key>Date of Loss:</Key>{" "}
                              <Value>
                                {selectedClaim.dateOfLoss
                                  ? new Date(
                                      selectedClaim.dateOfLoss
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })
                                  : "N/A"}
                              </Value>
                            </KeyValue>
                          </Item>
                          <Item>
                            <KeyValue>
                              <Key>Claim #:</Key>{" "}
                              <Value>
                                {selectedClaim.clients[0].claimNumber}
                              </Value>
                            </KeyValue>
                            <KeyValue>
                              <Key>Client:</Key>{" "}
                              <Value>
                                {selectedClaim?.clients[0]?.client.name}
                              </Value>
                            </KeyValue>
                            <KeyValue>
                              <Key>Examiner:</Key>{" "}
                              <Value>{selectedClaim?.examinerId?.name}</Value>
                            </KeyValue>
                          </Item>
                        </SubHeadContainer>
                        <SubHeadContainer>
                        <div>
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown}>Menu</DropdownButton>
      <DropdownMenu open={isDropdownOpen}>
        <DropdownItem onClick={handlePolicyModal}>Update Policy Details</DropdownItem>
        <DropdownItem onClick={handleUpdateModal}>Update Claim Details</DropdownItem>
      </DropdownMenu>
    </DropdownContainer>
    <Modal
      open={isPolicyModalOpen}
      onCancel={() => setIsPolicyModalOpen(false)}
      onClose={() => setIsPolicyModalOpen(false)}
      footer={null}
    >
      <ModalContainer>
        <PolicyDetailForm
          title="Edit Policy Details"
          handleSubmit={handleSubmit}
          formData={formData}
          setFormData={setFormData}
          claim={selectedClaim}
        />
      </ModalContainer>
    </Modal>
    <Modal
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <ModalContainer>
        <ClaimsForm
          title="Edit Claim Details"
          handleSubmit={handleSubmit}
          formData={formData}
          setFormData={setFormData}
          claim={selectedClaim}
        />
      </ModalContainer>
    </Modal>
  </div>
                        {/* Status Icon with Dropdown */}
                        <div style={{ position: "relative" }}>
                          <StatusIcon
                            isOpen={selectedClaim.claimStatus === 1}
                            onClick={toggleStatusDropdown}
                          >
                            {selectedClaim.claimStatus === 1 ? "Open" : "Closed"}
                          </StatusIcon>
                          {statusDropdownVisible && (
                            <DropdownMenu open={statusDropdownVisible}>
                              {selectedClaim.claimStatus === 1 ? (
                                <DropdownItem onClick={() => updateClaimStatus(0)}>
                                  Close Claim
                                </DropdownItem>
                              ) : (
                                <DropdownItem onClick={() => updateClaimStatus(1)}>
                                  Re-open Claim
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          )}
                        </div>
                      </SubHeadContainer>
                        <SubHeadContainer>
                          <Modal
                            open={isModalOpen}
                            onCancel={() => setIsModalOpen(false)}
                            footer={null}
                          >
                            <ModalContainer>
                              <ClaimsForm
                                title="Edit Claim"
                                handleSubmit={handleSubmit}
                                formData={formData}
                                setFormData={setFormData}
                                claim={selectedClaim} // Ensure selectedClaim is defined
                              />
                            </ModalContainer>
                          </Modal>
                        </SubHeadContainer>
                      </HeadContainer>
                      <DetailsContainer>
                        <TitlesContainer>
                          <Links
                            onClick={() => setSelectedLink("policy-data")}
                            isActive={selectedLink === "policy-data"}
                          >
                            Policy Details
                          </Links>
                          <Links
                            onClick={() => setSelectedLink("details")}
                            isActive={selectedLink === "details"}
                          >
                            Claim Details
                          </Links>
                          <Links
                            onClick={() => setSelectedLink("parties")}
                            isActive={selectedLink === "parties"}
                            claimId={selectedClaim._id}
                          >
                            Parties
                          </Links>
                          <Links
                            onClick={() => setSelectedLink("documents")}
                            isActive={selectedLink === "documents"}
                          >
                            Documents
                          </Links>
                          <Links
                            onClick={() => setSelectedLink("tasks")}
                            isActive={selectedLink === "tasks"}
                          >
                            Tasks
                          </Links>
                          <Links
                            onClick={() => setSelectedLink("time-expenses")}
                            isActive={selectedLink === "time-expenses"}
                          >
                            Time & Expenses
                          </Links>
                          <Links
                            onClick={() => setSelectedLink("notes")}
                            isActive={selectedLink === "notes"}
                          >
                            Notes
                          </Links>
                          <Links
                            onClick={() => setSelectedLink("reserves")}
                            isActive={selectedLink === "reserves"}
                          >
                            Reserves
                          </Links>
                        </TitlesContainer>

                        <FormContainer>
                          {selectedLink === "details" && (
                            <Details
                              claim={selectedClaim}
                              locationId={locationId}
                              claimId={selectedClaim._id}
                            />
                          )}
                          {selectedLink === "policy-data" && (
                            <PolicyData claim={selectedClaim} />
                          )}
                          {selectedLink === "parties" && (
                            <Parties
                              claimId={selectedClaim._id}
                              claimData={selectedClaim}
                            />
                          )}
                          {selectedLink === "documents" && (
                            <Documents
                              claimId={selectedClaim._id}
                              locationId={locationId}
                              claimData={selectedClaim}
                            />
                          )}
                          {selectedLink === "tasks" && (
                            <Tasks
                              claimId={selectedClaim._id}
                              userCode={selectedClaim?.adjusterId?.user_code}
                            />
                          )}
                          {selectedLink === "time-expenses" && (
                            <TimeExpenses
                              mileageRate={
                                selectedClaim?.clients?.[0]?.client
                                  ?.mileage_rate
                              }
                              cityAuto={
                                selectedClaim?.clients?.[0]?.client?.cityAuto
                              }
                              rate={selectedClaim?.rate}
                              clients={selectedClaim?.clients?.[0]}
                              claimId={selectedClaim._id}
                              userCode={selectedClaim?.adjusterId?.user_code}
                              setSelectedLink={setSelectedLink}
                            />
                          )}
                          {selectedLink === "notes" && (
                            <Notes claimId={selectedClaim._id} />
                          )}
                          {selectedLink === "reserves" && (
                            <Reserves claimId={selectedClaim._id} />
                          )}
                        </FormContainer>
                      </DetailsContainer>
                    </>
                  ) : (
                    <>no claim selected</>
                  )}
                </Col>
              </MainContainer>
            </>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default Claims;
