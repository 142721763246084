import React from "react";
import axios from "axios";
import "./AdminTable.css";
import toast from "react-hot-toast";
import styled from "styled-components";
import { FaDownload } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useAuth } from "../../context/auth";
import writeIcon from "../../assets/Write.png";
import { IoFolderOpenOutline } from "react-icons/io5";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  Button,
  Col,
  Input,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

const EditIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${writeIcon}); // Use the edit icon image
  background-size: cover;
  cursor: pointer;
`;
const SubText = styled.div`
  color: #000000d9;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
`;
const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UserTemplateList = ({ templates, setTemplates, claimId }) => {
  const [auth] = useAuth();
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [folderId, setFolderId] = useState(null);
  const [allFolders, setAllFolders] = useState([]);
  const [newFolderName, setNewFolderName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // New state for modal toggle

  const navigate = useNavigate();
  const toggleModal = () => setModalOpen(!modalOpen);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getFolders = async () => {
    try {
      const { data } = await axios.get(`/api/v1/getAllResourseDocoment`);
      console.log("folderData", data);
      setAllFolders(data);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong while fetching folders.");
    }
  };

  useEffect(() => {
    getFolders();
  }, []);

  const handleSaveClick = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("name", selectedFile.name);
    formData.append("type", selectedFile.type);
    formData.append("resourcesfolderId", folderId);

    try {
      const { data } = await axios.post(
        `/api/v1/createResourseDocument`,
        formData
      );
      if (data.success) {
        handleFolderOnClick(folderId);
        setSelectedFile(null);
        toggleModal();
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong while uploading the file.");
    }
    setLoading(false);
  };

  const handleFolderOnClick = (folder) => {
    console.log("folderssssssData", folder);
    setFolderId(folder);

    navigate("/userFiles", { state: folder });
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`/api/v1/templates/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setTemplates((prevTemplates) =>
        prevTemplates.filter((template) => template._id !== id)
      );
      toast.success("Template deleted successfully");
    } catch (error) {
      toast.error("Something went wrong while deleting the template.");
    }
  };

  const handleDownloadClick = (url) => {
    window.open(url, "_blank");
  };

  const handleCreateFolder = async () => {
    try {
      const res = await axios.post(`/api/v1/createResourseFloder`, {
        name: newFolderName,
      });
      if (res.data.success) {
        setFolders([res.data.template, ...folders]);
        setNewFolderName("");
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while creating the folder.");
    }
    setLoading(false);
  };

  return (
    <Row>
      <Row className="my-2">
        <Col md={12}>
          <h2 className="my-3">All Folders</h2>
        </Col>
      
      </Row>
      <Row>
        {allFolders?.length > 0 ? (
          allFolders.map((folder) => (
            <Col key={folder._id} md={4} sm={6} xs={12} className="mb-2">
              <div className="border rounded-1 shadow-sm  p-2 cursorPointer">
                <div
                  className="w-100 d-flex align-items-center gap-2   px-2"
                  onClick={() => handleFolderOnClick(folder)}
                >
                  <IoFolderOpenOutline size={40} />
                  <SubText>{folder.name}</SubText>
                </div>
              </div>
            </Col>
          ))
        ) : (
          <p className="p-2">No folders found</p>
        )}
      </Row>
    </Row>
  );
};

export default UserTemplateList;
