import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/auth";

export default function useAdjusters() {
  const [auth] = useAuth();
  const [adjusters, setAdjusters] = useState([]);

  //get loss types
  const getAdjusters = async () => {
    try {
        const response = await axios.get('/api/v1/users/adjusters',  {
          headers: {
            'Authorization': `Bearer ${auth?.token}`,
          }
        });
        if(response.data.success){
          setAdjusters(response.data.adjusters)
        }
    } catch (error) {
      console.log(error);
      
    }
  };

  useEffect(() => {
    getAdjusters();
  }, []);

  return adjusters;
}