import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";
import Modal from "../../components/common/Modal";
import { useAuth } from "../../context/auth";
import FillBtn from "../../shared/Buttons/FillBtn";
import AddAutomationForm from "./addAutomationForm";
import ConfirmationModal from "../../shared/Confirmation";
import { Row, Col } from "reactstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 15px;
  background: #e0e4f3;
  color: #444;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
  padding: 15px;
  color: black;
`;

const NoDataMessage = styled.div`
  text-align: center;
  color: #9fa2b4;
  font-size: 24px;
  margin-top: 30px;
`;

const Btn = styled.button`
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  background: #f7f7f7;
  border: none;
  cursor: pointer;
  &:hover {
    background: #e0e4f3;
  }
`;
const DropdownContainer = styled.div`
  position: relative;
  display: block;
  margin-left: 10px;
  color: black;
  background: none;
  cursor: pointer;
  font-weight: bold; /* or */
font-weight: 800;

`;

const DropdownContent = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right: 0;
`;

const DropdownItem = styled.button`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;


const Automation = ({ client }) => {
  const [tasks, setTasks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formData, setFormData] = useState({ dueDate: "", description: "", status: "", action: "" });
  const [automationId, setAutomationId] = useState(null);
  const [auth] = useAuth();
  const [openDropdownId, setOpenDropdownId] = useState(null);

  // Fetch automation tasks whenever the client changes
  useEffect(() => {
    if (client && client._id) {
      fetchAutomations(client._id);
    }
  }, [client]);

  const fetchAutomations = async (clientId) => {
    try {
      const response = await axios.get(`/api/v1/getAllAutomationTask?clientId=${clientId}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      if (response.data.success) {
        setTasks(response.data.automation);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error fetching automations.");
    }
  };


  const handleAddSubmit = async (event, resetForm) => {
    event.preventDefault();
    if (!formData.dueDate || !formData.description) {
      toast.error("Please fill in all required fields.");
      return;
    }
    try {
      const response = await axios.post(
        `/api/v1/createAutomationTask`,
        { ...formData, clientId: client._id },
        { headers: { Authorization: `Bearer ${auth?.token}` } }
      );
      if (response.data.success) {
        resetForm();
        fetchAutomations(client._id);
        closeModal();
        toast.success("Automation added successfully!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error adding automation.");
    }
  };

  const handleEditSubmit = async (event, resetForm) => {
    event.preventDefault();
    if (!formData.dueDate || !formData.description) {
      toast.error("Please fill in all required fields.");
      return;
    }
    try {
      const response = await axios.put(`/api/v1/updateAutomationTask/${automationId}`, formData, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      if (response.data.success) {
        resetForm();
        fetchAutomations(client._id);
        closeModal();
        toast.success("Automation updated successfully!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error updating automation.");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/api/v1/deleteAutomationTask/${automationId}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      if (response.data.success) {
        fetchAutomations(client._id);
        toast.success("Automation deleted!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error deleting automation.");
    } finally {
      setIsConfirmationModalOpen(false);
      setAutomationId(null);
    }
  };
  const handleOpenConfirmationModal = (id) => {
    setAutomationId(id);
    setIsConfirmationModalOpen(true);
  };
  
  

  const handleEditClick = (row) => {
    setFormData({
      dueDate: row.dueDate || "",
      description: row.description || "",
      status: row.status ? "true" : "false",
      action: row.action || "",
    });
    setAutomationId(row._id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({ dueDate: "", description: "", status: "", action: "" });
    setAutomationId(null);
  };

  const toggleDropdown = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  return (
    <Container>
      <Row className="align-items-center my-2">
        <Col md={9}>
          <h4>Automations for {client?.name || "No Client Selected"}</h4>
        </Col>
        <Col md={3}>
          <FillBtn
            className="w-100"
            text="Add Automation"
            handleOnClick={() => setIsModalOpen(true)}
          />
        </Col>
      </Row>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Due Date</TableHeader>
              <TableHeader>Description</TableHeader>
              <TableHeader>Repeat Task</TableHeader>
              <TableHeader>Actions</TableHeader>

            </TableRow>
          </thead>
          <tbody>
            {tasks.length > 0 ? (
              tasks.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell>{row?.dueDate ?? "No Due Date"}</TableCell>
                  <TableCell>{row?.description ?? "No Description"}</TableCell>
                  <TableCell>{row?.status === true ? "True" : "False"}</TableCell>
                  <TableCell>
    <DropdownContainer>
      <span className="dropdown-btn" onClick={() => toggleDropdown(row._id)}>...</span>
      <DropdownContent isOpen={openDropdownId === row._id}>
        <DropdownItem onClick={() => handleEditClick(row)}>Edit</DropdownItem>
        <DropdownItem onClick={() => handleOpenConfirmationModal(row._id)}>
          Delete
        </DropdownItem>
      </DropdownContent>
    </DropdownContainer>
  </TableCell>

                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <NoDataMessage>No data found for this client.</NoDataMessage>
                </TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
      </TableContainer>
      <Modal open={isModalOpen} onClose={closeModal}>
      <AddAutomationForm
  formData={formData}
  setFormData={setFormData}
  handleSubmit={automationId ? handleEditSubmit : handleAddSubmit}
  title={automationId ? "Edit Automation" : "Add Automation"}
  client={client} // Ensure this is passed
/>
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        toggle={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
        title="Confirm Action"
        body="Are you sure you want to delete this automation?"
        onConfirm={handleDelete}
      />
    </Container>
  );
};

export default Automation;
