import React from "react";
import styled from "styled-components";
import Button from "../../components/common/Button";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Label = styled.label`
  margin: 5px 0;
`;

const AddAutomationForm = ({ formData, setFormData, handleSubmit, title, client, clientId }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData({
      dueDate: "",
      description: "",
      status: "",
      frequency: "",
      clientId: client?._id || "",
    });
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e, resetForm)}>
      <h2>{title}</h2>

      {/* Display Parent Client Information */}
      <Label>Client</Label>
      <p>
        ({client?.name || "N/A"})
      </p>

      {/* Description Field */}
      <Label htmlFor="description">Description</Label>
      <Input
        id="description"
        type="text"
        name="description"
        value={formData.description || ""}
        onChange={handleChange}
        required
        placeholder="Enter task description"
      />

      {/* Number of Days After Opening */}
      <Label htmlFor="dueDate">Number of Days After Opening</Label>
      <Input
        id="dueDate"
        type="number"
        name="dueDate"
        value={formData.dueDate || ""}
        onChange={handleChange}
        required
        min="1"
        placeholder="Enter the number of days"
      />

      {/* Repeat Task Dropdown */}
      <Label htmlFor="status">Does this task repeat?</Label>
      <select
        id="status"
        name="status"
        value={formData.status === "true" ? "true" : formData.status === "false" ? "false" : ""}
        onChange={handleChange}
        required
      >
        <option value="">Select Yes/No</option>
        <option value="true">Yes</option>
        <option value="false">No</option>
      </select>

      {/* Frequency Field (conditionally rendered) */}
      {formData.status === "true" && (
        <>
          <Label htmlFor="frequency">Frequency (in days)</Label>
          <Input
            id="frequency"
            type="number"
            name="frequency"
            value={formData.frequency || ""}
            onChange={handleChange}
            required
            min="1"
            placeholder="Enter the number of days"
          />
        </>
      )}

      {/* Submit Button */}
      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default AddAutomationForm;
