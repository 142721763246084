import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import Details from '../../components/vendors/details';
import Contacts from '../../components/vendors/contacts/Contacts'
import Documents from '../../components/vendors/documents';
import hamburger from '../../assets/hamburger.png';
import Layout from '../../components/Layout/Layout';
import VendorForm from '../../components/vendors/VendorForm';
import toast from 'react-hot-toast';
import axios from 'axios';
import { Modal } from 'antd';
import List from '../../components/common/List';
import SearchBox from '../../components/common/SearchBox';
import Button from '../../components/common/Button';
import { useAuth } from '../../context/auth';


const MainContainer = styled.div`
  display: flex;
  min-height:100vh;
  @media only screen and (max-width: 768px) {
    flex-direction:column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  width:250px;
  padding:20px;
  flex-direction:column;
  @media only screen and (max-width: 768px) {
    width:100%;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction:column;
  @media only screen and (max-width: 768px) {
    width:100%;
    height:auto;
  }
`;


const MenuButton = styled.button`
  display: none;
  background: url(${hamburger});
  width:44px;
  height:44px;
  margin:20px;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const DetailsContainer = styled.div`
padding:20px;
margin:20px;
flex-grow: 1;
background-color: white;
border-radius:5px;
display:flex;
flex-direction:column;
justify-content: space-between;
`;


const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding:20px 0px;
`;



const TitlesContainer = styled.div`
  text-align:center;
  width:100%;
  display:flex;
  align-items:center;
  overflow-x: auto;
  flex-direction:row;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  }
`;

const Links= styled.a`
  margin:0px 40px 10px 0px;
  padding-bottom:20px;
  &:hover {
    border-bottom: 1px solid #6495ED;
  }
`;

const FormContainer = styled.div`
  display:flex;
  flex-grow: 1;
`;

const MenuContainer = styled.div`
  flex-direction: column;
  display:flex;
  @media only screen and (max-width: 768px) {
    display: ${props => props.open ? 'block' : 'none'};
  }
`;

const Vendors = () => {

  const [vendors,setVendors]=useState([])
  const [selectedVendor,setSelectedVendor]=useState()
  const [loading,setLoading]=useState(true)
  const [query, setQuery] = useState('');
  const [auth] = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address:{
      addressLine:'',
      city:'',
      province:'',
      postalCode:''
    },
    type_of_vendor:'',
    rate:0.0,
    mileage_rate:0.0,
    cityAuto:0.0,
      // Add other fields as needed
    });


  const [selectedLink, setSelectedLink] = useState('details');
  const [isOpen, setIsOpen] = useState(false); // State to control menu visibility
  const hasAccessToAutomation =
  auth?.user?.role_id?.name?.toLowerCase() === 'manager' ||
  auth?.user?.role_id?.name?.toLowerCase() === 'supervisor';

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle menu state
  };

  //get 5 recently added vendors
  const getVendors= async()=>{
    try {
      const {data}=await axios.get("/api/v1/vendors", {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      })
      if(data.success){
        setVendors(data.vendors)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      toast.error(error?.response?.data?.message)

    }
  }

  const handleDelete = async (vendorId) => {
    try {
      const {data}=await axios.delete(`/api/v1/vendors/${vendorId}`, {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      });
      if(data.success){
        setVendors(vendors.filter(vendor => vendor._id !== vendorId));
        setSelectedVendor(null);
        toast.success("Vendor Deleted Sucessfully")
      }
    } catch (error) {
      console.error('Error deleting vendor:', error);
      toast.error(error?.response?.data?.message)
    }
  };

  const handleSearch = async () => {
    try {
      const {data} = await axios.get(`/api/v1/vendors/search/${query}`, {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      });

      if(data.success){
        const foundVendor = data.vendor;
        setVendors([foundVendor, ...vendors.filter(vendor => vendor._id !== foundVendor._id)]);
        toast.success(data.message)
      }else{
        toast.error(data.message)
      }
      
    } catch (error) {
      console.error('Error searching vendor:', error);
      toast.error(error?.response?.data?.message)
    }
  };
  
    const handleSubmit = async (e) => {
      const url = `/api/v1/vendors`;
      e.preventDefault();

      try {
          const res = await axios.post(url, {
           formData
          }, {
            headers: {
              'Authorization': `Bearer ${auth?.token}`,
            }
          });
          if (res && res.data.success) {
            console.log(res.data.vendor)
            setVendors([res.data.vendor, ...vendors]);
             setFormData({
               name: '',
               email: '',
               address: {
                addressLine: '',
                city: '',
                province: '',
                postalCode: ''
                },
              type_of_vendor: '',
              rate: 0.0,
              mileage_rate: 0.0,
              cityAuto: 0.0,
              // Add other fields as needed
            });

            toast.success(res.data && res.data.message);
          } else {
            toast.error(JSON.stringify(res.data.message));
          }
        } catch (error) {
          console.log(error);
          toast.error(error?.response?.data?.message)
        }
    };


    //update a contact
    const handleUpdate = async (e) => {
      console.log(formData)
      const url = `/api/v1/vendors/${selectedVendor._id}`;
      e.preventDefault();
      try {
          const res = await axios.put(url, {
           formData
          }, {
            headers: {
              'Authorization': `Bearer ${auth?.token}`,
            }
          });
          if (res && res.data.success) {
            const updatedVendor = res.data.vendor;
            const updatedVendors = vendors.filter(vendor => vendor._id !== updatedVendor._id);
            setVendors([updatedVendor, ...updatedVendors]);
            
            setIsModalOpen(false);

            toast.success(res.data && res.data.message);
          } else {
            toast.error(JSON.stringify(res.data.message));
          }
        } catch (error) {
          console.log(error);
          toast.error(error?.response?.data?.message)
        }
    };

    useEffect(()=>{
      getVendors()
    },[])

    const handleSelectClick= (vendor) => {
      setSelectedVendor(vendor); 
      setSelectedLink('details');
    };

    const confirmDelete = (id) => {
      Modal.confirm({
        title: 'Are you sure you want to delete this vendor?',
        content: 'This action cannot be undone.',
        onOk: () => handleDelete(id),
        okText: 'Yes',
        cancelText: 'No',
      });
    };

    const handleUpdateClick= () => {
      setFormData(selectedVendor)
      setIsModalOpen(true)
    };

    const handleAddClick= () => {
      setSelectedVendor(null)
      setFormData({
        name: '',
        email: '',
        address:{
      addressLine:'',
      city:'',
      province:'',
      postalCode:''
       },
    type_of_vendor:'',
    rate:0.0,
    mileage_rate:0.0,
    cityAuto:0.0,
      // Add other fields as needed
      })
    };



    return (
      <Layout title="Vendors - User">
        {loading?(<>Loading....</>):(<>
          <MainContainer>
            <LeftContainer>
              <div style={{ display: 'flex', padding: '20px', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '30px' }}>Vendors <MenuButton onClick={toggleMenu}/></div>
                <Button onClick={() => {handleAddClick()}}>Add </Button>
              </div>
              <MenuContainer open={isOpen}>
                <SearchBox
                query={query}
                setQuery={setQuery}
                onSearch={handleSearch}
                />
                <List
                 loading={loading}
                 items={vendors}
                 onClick={handleSelectClick}
                />
              </MenuContainer>
            </LeftContainer>
            <RightContainer>
              {selectedVendor? (
                <DetailsContainer>
                  <TopBar>
                  <div style={{display:'flex'}}>
                    <h1 style={{margin:'0'}}>
                      {selectedVendor.name}
                    </h1>
                   <p style={{margin:'0' , marginTop:'15px'}}>
                     {selectedVendor.type_of_vendor}
                    </p> 
                  </div>
                  <div style={{display:'flex'}}> 
                  <Button
                    onClick={()=>handleUpdateClick()}>
                      Update
                  </Button>
                  <Button
                   style={{backgroundColor:'red', marginLeft:'10px'}}
                   onClick={() => confirmDelete(selectedVendor._id)}>
                    Delete
                  </Button>
                  </div>
                  <Modal open={isModalOpen} onCancel={()=>setIsModalOpen(false)} footer={null}>
                      <VendorForm title={`Update Vendor`} handleSubmit={handleUpdate} formData={formData} setFormData={setFormData} />
                    </Modal>
                  </TopBar>
                <TitlesContainer>
                  <Links onClick={() => setSelectedLink('details')}>Details</Links>
                  <Links onClick={() => setSelectedLink('contacts')}>Contacts</Links>
                  <Links onClick={() => setSelectedLink('documents')}>Documents</Links>

                </TitlesContainer>
                <FormContainer>
                  {selectedLink === 'details' &&  <Details vendor={selectedVendor}/>}
                  {selectedLink === 'contacts' && <Contacts vendor={selectedVendor}/>}
                  {selectedLink === 'documents' && <Documents vendor={selectedVendor}/>}

                    </FormContainer>
            </DetailsContainer>
              ):(
                <VendorForm handleSubmit={handleSubmit} formData={formData} setFormData={setFormData} title={'Create New Vendor'}/>
              )}
            </RightContainer>
      </MainContainer>
        </>)}
      </Layout>
    );
  };
  
  export default Vendors;