import React from 'react';
import styled from 'styled-components';
import Button from '../../common/Button';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Label = styled.label`
  margin: 5px 0;
`;

const ContactForm = ({ formData, setFormData, handleSubmit, title }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h2>{title}</h2>
      <Label>Name</Label>
      <Input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      <Label>Email</Label>
      <Input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <Label>Role</Label>
      <Input
        type="text"
        name="role"
        value={formData.role}
        onChange={handleChange}
      />
      <Label>Phone</Label>
      <Input
        type="text"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
      />
      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default ContactForm;
