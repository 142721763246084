import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/auth";

export default function usePolicyTypes() {
  const [auth] = useAuth();
  const [policyTypes, setPolicyTypes] = useState([]);

  //get loss types
  const getPolicyTypes = async () => {
    try {
        const response = await axios.get('/api/v1/policy-types', {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        if(response.data.success){
          setPolicyTypes(response.data.policyTypes)
        }
    } catch (error) {
      console.log(error);
      
    }
  };

  useEffect(() => {
    getPolicyTypes();
  }, []);

  return policyTypes;
}