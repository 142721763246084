import React, { useEffect, useState } from "react";
import styled from "styled-components";
import claim from "../../assets/claims.png";
import day_hour from "../../assets/day_hours.png";
import month_hour from "../../assets/month_hours.png";
import sorter from "../../assets/sorter.png";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";
import { Link, useParams } from "react-router-dom";
import SearchBox from "../../components/common/SearchBox";
import { Col, Row } from "reactstrap";
const Container = styled.div`
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: row;
`;

const TrackContainer = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    gap: 20px;
    margin-bottom: 30px;
    align-items: center;
  }
`;

const OverTaskContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #ffffff;
  margin-bottom: 20px;
`;

const OverTaskTitleContainer = styled.div`
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ComingTaskContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #ffffff;
`;

const DashboardText = styled.div`
  color: #000000;
  font-size: 30px;
  margin-top: 30px;
`;

 

const Input = styled.input`
  width: 270px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

const TrackComponent = styled.div`
  width: 368px;
  height: 96px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  align-items: center;
  padding-left: 20px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const TrackDetailComponent = styled.div`
  width: 91px;
  height: 64px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

const TrackTitleComponent = styled.div`
  height: 24px;
  width: 248px;
  font-size: 16px;
  color: #a5a5a5;
`;

const TrackCountComponent = styled.div`
  height: 32px;
  font-size: 32px;
  color: #000000;
`;

const SubText = styled.div`
  color: #000000;
  font-size: 20px;
  margin: 20px 0px 0px 20px;
`;

const SubNumber = styled.div`
  color: rgba(0, 0, 0, 45%);
  font-size: 14px;
  margin: 20px 0px 0px 20px;
`;

const FilterDropdown = styled.select`
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
`;

const Option = styled.option`
  font-size: 14px;
`;

const TableContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 20px 10px 20px 10px;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Table = styled.table`
  width: auto;
  border-collapse: collapse;
  margin: 10px;
`;

const TableHeader = styled.th`
  padding: 15px;
  position: relative;
  background: #e0e4f3;
  color: black;
  text-align: left;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.20000000298023224px;
`;

const SorterIcon = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 36px;
  margin-left: 5px;
  background-image: url(${sorter}); // Use the sorter icon image
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer; // Add cursor pointer for hover effect
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  color: red;
`;

const TableCell = styled.td`
  text-align: left;
  padding: 8px;
  padding: 15px;
`;

const CommingTableRow = styled.tr`
  background-color: #ffffff;
  color: black;
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
`;

function createData(id, title, assignee, due, modified) {
  return { id, title, assignee, due, modified };
}

const rows = [
  createData(13234, "file name", "title", "stephen", "1/2/2002"),
  createData(13234, "file name", "title", "stephen", "1/2/2002"),
  createData(13234, "file name", "title", "stephen", "1/2/2002"),
  createData(13234, "file name", "title", "stephen", "1/2/2002"),
  createData(13234, "file name", "title", "stephen", "1/2/2002"),
];

// router.get('/getOverdueTasks', claimController.getOverdueTasks);

const Dashboard = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [monthHours, setMonthHours] = useState(0);
  const [todayHours, setTodayHours] = useState(0);
  const [overDueTasks, setOverDueTasks] = useState([]);
  const [upcomingTasks, setUpcomingTasks] = useState([]);
  const [claimCount, setClaimCount] = useState(0);
  const [auth, setAuth] = useAuth();
  
  const [selectedClaim, setSelectedClaim] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedLink, setSelectedLink] = useState("details");
  const {fileNumber} = useParams();
  const [claims, setClaims] = useState([]);
  const [query, setQuery] = useState("");
  useEffect(() => {
    fetchClaimCount();
    getThisMonthHoursBilled();
    getTodayHoursBilled();
    getOverdueTasks();
    getUpcomingTasks();
  }, []);

  const handleFilterChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const fetchClaimCount = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/getClaimsStatusCountForUser?userId=${auth?.user?._id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (data.success) {
        setClaimCount(data.openCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchHoursBilled = async (url, setHours) => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
  
      if (data.success) {
        // Set the rounded total hours using parseFloat to avoid string assignment
        setHours(parseFloat(data.totalHours.toFixed(1)));
      } else {
        console.warn("Failed to fetch hours:", data.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching hours billed:", error);
      // Optionally, add user feedback (e.g., a toast notification)
    }
  };
  
  const getThisMonthHoursBilled = () => {
    const url = `/api/v1/getHoursBilledThisMonth?userId=${auth?.user?._id}`;
    fetchHoursBilled(url, setMonthHours);
  };
  
  const getTodayHoursBilled = () => {
    const url = `/api/v1/getHoursBilledToday?userId=${auth?.user?._id}`;
    fetchHoursBilled(url, setTodayHours);
  };
  

  const getOverdueTasks = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/getOverdueTasks?userId=${auth?.user?._id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
  
      if (data.success) {
        // Sort tasks by dueDate in ascending order
        const sortedTasks = data.tasks.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
        setOverDueTasks(sortedTasks);
      }
    } catch (error) {
      console.error("Error fetching overdue tasks:", error);
    }
  };
  

  const getUpcomingTasks = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/getUpcomingTasks?userId=${auth?.user?._id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      
      console.log("upcomingData", data?.tasks);
      
      if (data.success) {
        setUpcomingTasks(data.tasks);
        
        const fileNaaam = data.tasks.map((item) => {
          return item?.claim_id?.policyDetails?.name;
        });
  
        console.log("filenameee", fileNaaam);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const getClaims = async () => {
    try {
      const { data } = await axios.get("/api/v1/claims", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      const claim = data?.claims?.filter(claim => (
        claim?.fileNumber === fileNumber 
      ));
      if (claim?.length > 0) {
        setSelectedClaim(claim[0]);
        setSelectedLink("details");
      }
      if (data.success) {
        setClaims(data.claims);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  const handleSearch = async () => {
    if (query === "") {
      // getClaims();
    } else {
      try {
        const { data } = await axios.get(`/api/v1/claims/search/${query}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        if (data.success) {
          console.log("awdaw", data);
          // const foundClaim = data.claim;
          // setClaims([
          //   foundClaim,
          //   ...claims.filter((claim) => claim._id !== foundClaim._id),
          // ]);
          setClaims(data?.claim);
          toast.success(data.message);
        } else {
          console.log(data, "Awdwa");
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error searching claim", error);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  return (
    <Layout title="Dashboard - User">
      <Container>
        <Row className="align-items-center my-3 py-3">
          <Col md={12}>
          <h3 className="mt-3">Dashboard</h3>
          </Col>
          {/* <Col md={9}>
          
          <SearchBox
                  query={query}
                  setQuery={setQuery}
                  onSearch={handleSearch}
                />
          </Col> */}
     
        </Row>
        <TrackContainer>
          <TrackComponent>
            <img
              src={claim}
              style={{ width: "64px", height: "64px" }}
              alt="claim"
            />
            <TrackDetailComponent>
              <TrackTitleComponent>Open Claims</TrackTitleComponent>
              <TrackCountComponent>{claimCount}</TrackCountComponent>
            </TrackDetailComponent>
          </TrackComponent>
          <TrackComponent>
            <img
              src={month_hour}
              style={{ width: "64px", height: "64px" }}
              alt="month_hour"
            />
            <TrackDetailComponent>
              <TrackTitleComponent>Hours Billed this Month</TrackTitleComponent>
              <TrackCountComponent>{monthHours}</TrackCountComponent>
            </TrackDetailComponent>
          </TrackComponent>
          <TrackComponent>
            <img
              src={day_hour}
              style={{ width: "64px", height: "64px" }}
              alt="day_hour"
            />
            <TrackDetailComponent>
              <TrackTitleComponent>Hours Billed Today</TrackTitleComponent>
              <TrackCountComponent>{todayHours}</TrackCountComponent>
            </TrackDetailComponent>
          </TrackComponent>
        </TrackContainer>
        <OverTaskContainer>
          <OverTaskTitleContainer>
            <div
              style={{
                width: "250px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SubText>Overdue Tasks</SubText>
              <SubNumber className="mt-4"><strong>{overDueTasks.length}</strong> tasks</SubNumber>
            </div>
            <div
              style={{
                width: "150px",
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <FilterDropdown
                value={selectedOption}
                onChange={handleFilterChange}
              >
                <Option value="">Filter</Option>
                <Option value="option1">Option 1</Option>
                <Option value="option2">Option 2</Option>
                <Option value="option3">Option 3</Option>
              </FilterDropdown>
            </div>
          </OverTaskTitleContainer>
          <TableContainer>
            <Table>
              <thead>
                <TableRow>
                  <TableHeader>
                    File Number
                    <SorterIcon />
                  </TableHeader>
                  <TableHeader>
                    File Name
                    <SorterIcon />
                  </TableHeader>
                  <TableHeader>
                    User Code <SorterIcon />
                  </TableHeader>
                 
                  <TableHeader>
                    Due Date
                    <SorterIcon />
                  </TableHeader>
                  <TableHeader>
                    Description
                    <SorterIcon />
                  </TableHeader>

                </TableRow>
              </thead>
              <tbody>
  {overDueTasks.map((row) => (
    row?.claimStatus && (
      <TableRow key={row._id}>
        <TableCell component="th" scope="row">
          <Link to={`/claims/${row?.claim_id?.fileNumber}`}>
            {row?.claim_id?.fileNumber}
          </Link>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.claim_id?.policyDetails?.name || "N/A"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.userCode || "N/A"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.dueDate}
        </TableCell>
        <TableCell align="right">{row?.description || "N/A"}</TableCell>
      </TableRow>
    )
  ))}
</tbody>

            </Table>
          </TableContainer>
        </OverTaskContainer>

        <ComingTaskContainer>
          <OverTaskTitleContainer>
            <div
              style={{
                width: "250px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SubText>Upcoming Tasks</SubText>
              <SubNumber className="mt-4"> <strong>{upcomingTasks?.length}</strong> tasks</SubNumber>
            </div>
            <div
              style={{
                width: "150px",
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <FilterDropdown>
                <Option value="">Filter</Option>
                <Option value="option1">Option 1</Option>
                <Option value="option2">Option 2</Option>
                <Option value="option3">Option 3</Option>
              </FilterDropdown>
            </div>
          </OverTaskTitleContainer>

          <TableContainer>
            <Table>
              <thead>
                <TableRow>
                  <TableHeader>
                    File Number
                    <SorterIcon />
                  </TableHeader>
                  <TableHeader>
                    File Name
                    <SorterIcon />
                  </TableHeader>
                  <TableHeader>
                    User Code <SorterIcon />
                  </TableHeader>
                  <TableHeader>
                    Due Date
                    <SorterIcon />
                  </TableHeader>
                  <TableHeader>
                    Description
                    <SorterIcon />
                  </TableHeader>
                </TableRow>
              </thead>
              <tbody className="text-dark">
                {upcomingTasks.map((row) => {
                  return (
                    <>
                      {row?.claimStatus && (
                        <TableRow key={row._id} className="text-dark">
                          <TableCell component="th" scope="row">
                            <Link to={`/claims/${row?.claim_id?.fileNumber}`}>
                              {row?.claim_id?.fileNumber}
                            </Link>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            
                              {row?.claim_id?.policyDetails?.name}
                           
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row?.userCode}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row?.dueDate ?? row?.createdAt}
                          </TableCell>
                          <TableCell align="right">
                            {row?.description}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        </ComingTaskContainer>
      </Container>
    </Layout>
  );
};

export default Dashboard;
