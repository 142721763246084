import Layout from "../components/Layout/Layout";
import { useAuth } from "../context/auth";
import { Col, Row, Button } from "reactstrap";
import { NavLink} from "react-router-dom";
import './HomePage.css'; // Import a custom CSS file

function HomePage() {
  const [auth, setAuth] = useAuth();

  return (
    <Layout title="Homepage">
      <div className="homepage-container">
        <Row className="d-flex align-items-center justify-content-center h-100">
          <Col md={8} className="text-center">
            <h1 className="display-3 welcome-text">Welcome</h1>
            <h2 className="lead mb-4">Login to Claim</h2>
            <NavLink to="/login">
              <Button color="primary" size="lg" className="mt-3">Get Started</Button>
            </NavLink>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default HomePage;
