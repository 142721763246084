import Router from "./routes";
import 'bootstrap/dist/css/bootstrap.css';
import { AuthProvider } from "./context/auth";


export default function App() {
  return (
    <div>
      <AuthProvider>
      <Router/>
      </AuthProvider>
    </div>
  );
}