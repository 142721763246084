import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useClaimTypes from "../../../hooks/useClaimTypes";
import useLossTypes from "../../../hooks/useLossTypes";
import useAdjusters from "../../../hooks/useAdjusters";
import Button from "../../common/Button";
import * as Yup from "yup";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;
const FieldContainer = styled.div`
  flex: 0 0 calc(50% - 20px); /* Two fields per row with some space */
  flex-wrap: wrap;
`;

const Field = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding: 10px;
  align-self: flex-start;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

const SelectInput = styled.select`
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
`;

const Option = styled.option`
  font-size: 14px;
`;

const BtnContainer = styled.div`
  margin: 30px;
  display: flex;
  justify-content: space-between;
`;

const ClaimDetails = ({ formData, setFormData, setStep }) => {
  let claimtypes = useClaimTypes();
  let losstypes = useLossTypes();
  const adjusters = useAdjusters();

  // Define Yup validation schema
  const ValidationSchema = Yup.object().shape({
    dateOfLoss: Yup.date().required("Date of loss is required"),
    proscriptionDate: Yup.date().required("Date of loss is required"),
    typeOfClaimId: Yup.string().required("Type of claim is required"),
    typeOfLossId: Yup.string().required("Type of loss is required"),
    lossAddress: Yup.string().required("Loss address is required"),
    lossCity: Yup.string().required("Loss city is required"),
    lossProvince: Yup.string().required("Loss province is required"),
    lossPostalCode: Yup.string().required("Loss postal code is required"),
    lossDetail: Yup.string().required("Loss detail is required"),
    adjusterId: Yup.string().required("Adjuster is required"),
  });

  const [errors, setErrors] = useState({});
  const handleNext = async () => {
    try {
      await ValidationSchema.validate(formData, { abortEarly: false });
      setStep((prevStep) => prevStep + 1);
    } catch (err) {
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };
  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    if (formData.dateOfLoss) {
      const date = new Date(formData.dateOfLoss);
      date.setFullYear(date.getFullYear() + 2);
      setFormData({
        ...formData,
        proscriptionDate: date.toISOString().split("T")[0],
      });
    }
  }, [formData.dateOfLoss]);

  return (
    <>
      <h2>Claim Details</h2>
      <Container>
        <FieldContainer>
          <Field>
            <Label htmlFor="typeOfClaimId">Claim Type</Label>
            <SelectInput
              id="typeOfClaimId"
              name="typeOfClaimId"
              value={formData.typeOfClaimId}
              onChange={(e) =>
                setFormData({ ...formData, typeOfClaimId: e.target.value })
              }
            >
              <Option value="">Select Claim type</Option>
              {claimtypes.map((claimType) => (
                <Option key={claimType._id} value={claimType._id}>
                  {claimType.description}
                </Option>
              ))}
            </SelectInput>
            {errors.typeOfClaimId && <div>{errors.typeOfClaimId}</div>}
          </Field>
          <Field>
            <Label htmlFor="typeOfLossId">Loss Type</Label>
            <SelectInput
              id="typeOfLossId"
              name="typeOfLossId"
              value={formData.typeOfLossId}
              onChange={(e) =>
                setFormData({ ...formData, typeOfLossId: e.target.value })
              }
            >
              <Option value="">Select Loss type</Option>
              {losstypes.map((lossType) => (
                <Option key={lossType._id} value={lossType._id}>
                  {lossType.description}
                </Option>
              ))}
            </SelectInput>
            {errors.typeOfLossId && <div>{errors.typeOfLossId}</div>}
          </Field>
          <Field>
            <Label htmlFor="adjusterId">Adjuster</Label>
            <SelectInput
              id="adjusterId"
              name="adjusterId"
              value={formData.adjusterId}
              onChange={(e) =>
                setFormData({ ...formData, adjusterId: e.target.value })
              }
            >
              <Option value="">Select Adjuster</Option>
              {adjusters.map((adjuster) => (
                <Option key={adjuster._id} value={adjuster._id}>
                  {adjuster.name}
                </Option>
              ))}
            </SelectInput>
            {errors.adjusterId && <div>{errors.adjusterId}</div>}
          </Field>
          <Field>
            <Label>Date of Loss</Label>
            <Input
              type="date"
              name="dateOfLoss"
              value={formData.dateOfLoss}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                const effectiveDate = new Date(formData.policyDetails.effectiveDate);
                const expiryDate = new Date(formData.policyDetails.expiryDate);
                const today = new Date();

                if (selectedDate < effectiveDate || selectedDate > expiryDate) {
                  setErrors({ ...errors, dateOfLoss: "Date of loss must be between the policy's effective and expiry dates" });
                } else if (selectedDate > today) {
                  setErrors({ ...errors, dateOfLoss: "Date of loss cannot be a future date" });
                } else {
                  setErrors({ ...errors, dateOfLoss: null });
                  setFormData({ ...formData, dateOfLoss: e.target.value });
                }
              }}
            />
            {errors.dateOfLoss && <div>{errors.dateOfLoss}</div>}
          </Field>
          <Field>
            <Label>Proscription Date</Label>
            <Input
              type="date"
              name="proscriptionDate"
              value={formData.proscriptionDate}
              onChange={(e) =>
                setFormData({ ...formData, proscriptionDate: e.target.value })
              }
            />
            {errors.proscriptionDate && <div>{errors.proscriptionDate}</div>}
          </Field>
        </FieldContainer>
        <FieldContainer>
          <Field>
            <Label>Loss Address</Label>
            <Input
              type="text"
              name="lossAddress"
              value={formData.lossAddress}
              onChange={(e) =>
                setFormData({ ...formData, lossAddress: e.target.value })
              }
            />
            {errors.lossAddress && <div>{errors.lossAddress}</div>}
          </Field>
          <Field>
            <Label>City</Label>
            <Input
              type="text"
              name="lossVity"
              value={formData.lossCity}
              onChange={(e) =>
                setFormData({ ...formData, lossCity: e.target.value })
              }
            />
            {errors.lossCity && <div>{errors.lossCity}</div>}
          </Field>
          <Field>
            <Label>Province</Label>
            <Input
              type="text"
              name="lossProvince"
              value={formData.lossProvince}
              onChange={(e) =>
                setFormData({ ...formData, lossProvince: e.target.value })
              }
            />
            {errors.lossProvince && <div>{errors.lossProvince}</div>}
          </Field>
          <Field>
            <Label>Postal Code</Label>
            <Input
              type="text"
              name="lossPostalCode"
              value={formData.lossPostalCode}
              onChange={(e) =>
                setFormData({ ...formData, lossPostalCode: e.target.value })
              }
            />
            {errors.lossPostalCode && <div>{errors.lossPostalCode}</div>}
          </Field>
          <Field>
            <Label>Loss Detail</Label>
            <Input
              type="text"
              name="lossDetail"
              value={formData.lossDetail}
              onChange={(e) =>
                setFormData({ ...formData, lossDetail: e.target.value })
              }
            />
            {errors.lossDetail && <div>{errors.lossDetail}</div>}
          </Field>
        </FieldContainer>
      </Container>
      <BtnContainer>
        <Button type="button" onClick={handlePrevious}>
          Previous
        </Button>
        <Button type="button" onClick={handleNext}>
          Next
        </Button>
      </BtnContainer>
    </>
  );
};

export default ClaimDetails;
