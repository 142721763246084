import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";

const Test = () => {
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [newClient, setNewClient] = useState({
    formData: { name: "", email: "" },
  });
  const [editMode, setEditMode] = useState(false);
  const [auth] = useAuth();

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    try {
      const response = await axios.get("/api/v1/clients", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setClients(response.data.clients);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `/api/v1/clients/search?query=${search}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      const foundClient = response.data;
      setClients([
        foundClient,
        ...clients.filter((client) => client._id !== foundClient._id),
      ]);
    } catch (error) {
      console.error("Error searching client:", error);
    }
  };

  const handleDelete = async (clientId) => {
    try {
      await axios.delete(`/api/v1/clients/${clientId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setClients(clients.filter((client) => client._id !== clientId));
      if (selectedClient && selectedClient._id === clientId) {
        setSelectedClient(null);
      }
    } catch (error) {
      console.error("Error deleting client:", error);
    }
  };

  const handleAdd = async () => {
    try {
      const response = await axios.post("/api/v1/clients", newClient, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (response.data.success) {
        setClients([response.data.client, ...clients]);
        toast.success("client created successfully");
        console.log(response.data.client);
        setNewClient({ name: "", email: "" });
      }
      toast.error(response.data.message);
      console.log(response.data.message);
    } catch (error) {
      console.error("Error adding client:", error);
      toast.error("something went wrong");
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/v1/clients/${selectedClient._id}`, selectedClient, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setEditMode(false);
    } catch (error) {
      console.error("Error updating client:", error);
    }
  };

  const handleClientSelect = (client) => {
    setSelectedClient(client);
    setEditMode(false);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  return (
    <div className="container">
      <div className="left-container">
        <input
          type="text"
          placeholder="Search clients"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
        <ul>
          {clients.length ? (
            clients.map((client) => (
              <li key={client._id} onClick={() => handleClientSelect(client)}>
                {client.name}
                <button onClick={() => handleDelete(client._id)}>Delete</button>
              </li>
            ))
          ) : (
            <li>No clients found</li>
          )}
        </ul>
        <button onClick={() => setSelectedClient(null)}>Add Client</button>
      </div>
      <div className="right-container">
        {selectedClient && !editMode ? (
          <>
            <h2>{selectedClient.name}</h2>
            <p>Email: {selectedClient.email}</p>
            <button onClick={handleEdit}>Edit</button>
          </>
        ) : selectedClient && editMode ? (
          <>
            <input
              type="text"
              value={selectedClient.name}
              onChange={(e) =>
                setSelectedClient({ ...selectedClient, name: e.target.value })
              }
            />
            <input
              type="email"
              value={selectedClient.email}
              onChange={(e) =>
                setSelectedClient({ ...selectedClient, email: e.target.value })
              }
            />
            <button onClick={handleUpdate}>Update</button>
          </>
        ) : (
          <>
            <input
              type="text"
              placeholder="Name"
              value={newClient.name}
              onChange={(e) =>
                setNewClient({ ...newClient, name: e.target.value })
              }
            />
            <input
              type="email"
              placeholder="Email"
              value={newClient.email}
              onChange={(e) =>
                setNewClient({ ...newClient, email: e.target.value })
              }
            />
            <button onClick={handleAdd}>Add</button>
          </>
        )}
      </div>
    </div>
  );
};

export default Test;
