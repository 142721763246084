import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width:100%;
  height:100%;
`;


const Item = styled.div`
  flex: 0 0 calc(33% - 20px); /* Two items per row with some space */
  margin: 10px;
`;

const Key = styled.span`
  color: rgba(0, 0, 0, 45%);
  font-size: 14px;
  text-align:left;
  margin-bottom:10px;
`;

const Value = styled.span`
  color : #000000;
  text-align:left;
`;

const KeyValue = styled.div`
  margin-bottom: 20px;
  display:flex;
  flex-direction:column;
`;



const Details = ({vendor}) => {
    
      return (
        <Container>
          <Item>
           <KeyValue><Key>Name</Key> <Value>{vendor.name}</Value>
           <Value>{vendor.address.addressLine}</Value>
           <Value>{vendor.address.city}, {vendor.address.province}   {vendor.address.postalCode}</Value></KeyValue>
           <Item>
           <KeyValue><Key>Email</Key> <Value>{vendor.email}</Value>
           </KeyValue>
           <KeyValue><Key>Phone</Key> <Value>{vendor.phone}</Value>
           </KeyValue>
         </Item>
         </Item>
         <Item>
           <KeyValue><Key>Type of Vendor</Key> <Value>{vendor.type_of_vendor}</Value></KeyValue>

          </Item>
        </Container>
      );
    
}

export default Details
