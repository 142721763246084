import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import toast from "react-hot-toast";
import { useAuth } from "../../../context/auth";
import { Col, Row } from "reactstrap";
import FillBtn from "../../../shared/Buttons/FillBtn";
import OutlineBtn from "../../../shared/Buttons/OutlineBtn";

const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left: 20px;
  height: 40px;
`;

const FieldContainer = styled.div`
  display: flex;
`;

const Field = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding: 10px;
  align-self: flex-start;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;
const FileInput = styled.input`
  display: none;
`;
const TemplateForm = ({ locationId, onClose, setTemplates }) => {
  const [auth] = useAuth();
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (locationId) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("locationId", locationId);
        formData.append("file", file); // Append the file to the form data

        const response = await axios.post("/api/v1/templates", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        if (response.data.success) {
          const newTemplate = response.data.template;
          setTemplates((prevTemplates) => [...prevTemplates, newTemplate]);
          toast.success("success");
          setName("");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error adding template:", error);
      }
    } else {
      toast.error("Select location");
    }
  };

  return (
    <Row>
        <h2 >Add Template</h2>
      <Col md={12} >
      <form onSubmit={handleSubmit}>
        <Row className="my-3">
          <Col md={3}>
          <Field>
          
          <Label>Name</Label>
          <Input
          placeholder="Name"
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Field>
          </Col>
          <Col md={3}>
          <Field>
            <Label>File:</Label>
            <FileInput
              className="form-control"
              type="file"
              onChange={handleFileChange}
            />
            <OutlineBtn  
            className="py-2"
            handleOnClick={() =>
                document.querySelector('input[type="file"]').click()
              } 
              text="Choose File"
              />
         
          </Field>
          </Col>
      
        </Row>
        <Row>
          <Col md={12}>
          {loading ? (
          <>waiting..</>
        ) : (
          <>
          <FillBtn type="submit" text="Add Template"/>
         
          </>
        )} 
        <FillBtn className="ms-2" handleOnClick={() => onClose(false)} text="Cancel"/>
          </Col>
        </Row>
        
      </form>
      </Col>
    
   
    </Row>
  );
};

export default TemplateForm;
