import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/auth";

export default function useLocations() {
  const [auth] = useAuth();
  const [locations, setLocations] = useState([]);

  //get roles
  const getLocations = async () => {
    try {
        const response = await axios.get('/api/v1/locations', {
          headers: {
            'Authorization': `Bearer ${auth?.token}`,
          }
        });
        if(response.data.success){
          setLocations(response.data.locations)
        }
    } catch (error) {
      console.log(error);
      
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  return locations;
}