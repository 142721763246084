import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import deleteIcon from "../../assets/delete.png";
import writeIcon from "../../assets/Write.png";
import toast from "react-hot-toast";
import Layout from "../../components/Layout/Layout";
import { Modal } from "antd";
import LocationForm from "../../components/admin/forms/LocationForm";
import { useAuth } from "../../context/auth";
import { Button, Col, Row } from "reactstrap";
import FillBtn from "../../shared/Buttons/FillBtn";

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  min-height: 100vh;
  width: 300px;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  background: white;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: auto;
  }
`;

const RightContainer = styled.div`
  display: flex;
  padding: 20px;
  margin: 10px;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  flex-grow: 1;
`;

const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left: 20px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 15px;
  text-align: left;
  background: #e0e4f3;
  color: #444444;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  letter-spacing: 0.20000000298023224px;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  &:hover {
    background: #e0e4f3;
    border-radius: 20px;
  }
`;

const TableCell = styled.td`
  text-align: left;
  padding: 15px;
  white-space: nowrap;
  color: #9fa2b4;
`;

const ActionIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${deleteIcon}); // Use the delete icon image
  background-size: cover;
  cursor: pointer;
`;

const EditIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${writeIcon}); // Use the edit icon image
  background-size: cover;
  cursor: pointer;
`;

const Locations = () => {
  const [auth] = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState("");

  const [formData, setFormData] = useState({
    location_name: "",
    office_name: "",
    address: "",
    manager_id: "",
  });

  useEffect(() => {
    fetchLocations();
  }, []);

  const handleCreateButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "/api/v1/locations",
        { formData },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (response.data.success) {
        setLocations([...locations, response.data.location]);
        setFormData({
          location_name: "",
          office_name: "",
          address: "",
          manager_id: "",
        });
        toast.success("Location created successfully");
        setIsModalOpen(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
        console.log(error);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/v1/locations/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setLocations(locations.filter((location) => location._id !== id));
      setSelectedLocation("");
      toast.success("Location deleted successfully");
    } catch (error) {
      toast.error("Failed to delete location");
      console.error(error);
    }
  };

  const handleSelect = (location) => {
    setSelectedLocation(location);
  };

  const fetchLocations = async () => {
    try {
      const { data } = await axios.get("/api/v1/locations", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (data.success) {
        setLocations(data.locations);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error fetching locations");
      console.error(error);
    }
  };

  return (
    <Layout>
      <Container>
        <RightContainer>
          <Row>
            <Col md={12}>
              <Row className="d-flex align-items-center justify-content-between">
                <Col md={9}>
                  <HeaderContainer>
                    <div
                      style={{
                        fontSize: "25px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Locations
                    </div>
                  </HeaderContainer>
                </Col>
                <Col md={3} className="text-end">
               
                  <FillBtn
                    handleOnClick={handleCreateButtonClick}
                    className="w-100" // Change to auto to avoid taking full width
                    text="Create"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <TableContainer>
            {loading ? (
              <>Loading...</>
            ) : (
              <Table>
                <thead>
                  <TableRow>
                    <TableHeader>Location</TableHeader>
                    <TableHeader>Manager</TableHeader>
                    <TableHeader>Action</TableHeader>
                  </TableRow>
                </thead>
                <tbody>
                  {locations.map((location) => (
                    <TableRow
                      key={location._id}
                      onClick={() => handleSelect(location)}
                      style={{
                        background:
                          selectedLocation &&
                          selectedLocation._id === location._id
                            ? "#E0E4F3"
                            : "",
                      }}
                    >
                      <TableCell>{location.location_name}</TableCell>
                      <TableCell>{location.manager_id}</TableCell>
                      <TableCell align="right">
                        <ActionIcon
                          onClick={() => handleDelete(location._id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            )}
          </TableContainer>
          <div>
            <Modal
              title="Add new location"
              open={isModalOpen}
              onCancel={() => setIsModalOpen(false)}
              footer={null}
            >
              <LocationForm
                handleSubmit={handleSubmit}
                formData={formData}
                setFormData={setFormData}
              />
            </Modal>
          </div>
        </RightContainer>
      </Container>
    </Layout>
  );
};

export default Locations;
