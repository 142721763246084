
import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import usePolicyTypes from '../../../hooks/usePolicyTypes';
import usePolicyTypes from "../../hooks/usePolicyTypes";
import * as Yup from "yup";
// import Button from '../../common/Button';
import Button from "../common/Button";
import { FaRegBuilding } from "react-icons/fa";
import axios from "axios";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import { Col, Row } from "reactstrap";
import PolicyDetailsForm from "./policyDetailsForm"; // Import the form component

const Btn = styled.button`
  padding: 3px 3px;
  margin-left: 500px;
  margin-top: 5px;
  background-color: #337ab7;
  border-radius: 3px;
  color: #fff;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #2e6da4;
  }
    &:focus {
    outline: none;
  }
    &:active {
    background-color: #286090;
  }
    &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  `;

const Field = styled.div`
  margin: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  align-self: flex-start;
`;

const Value = styled.div`
  margin-left: 20px;
  
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
`;

const PolicyDetails = ({ claim, claimData }) => {
  const [isEditing, setIsEditing] = useState(false); // State for toggling between view and edit
  const policytypes = usePolicyTypes(); // Fetch policy types
  const [auth] = useAuth();
  const [policyDetails, setPolicyDetails] = useState(claim?.policyDetails || {});
  const [formData, setFormData] = useState({
    policyDetails: {
      policyNumber: claim?.policyDetails?.policyNumber || "",
      typeOfPolicyId: claim?.policyDetails?.typeOfPolicyId?._id || "",
      effectiveDate: claim?.policyDetails?.effectiveDate || "",
      expiryDate: claim?.policyDetails?.expiryDate || "",
      deductible: claim?.policyDetails?.deductible || 0,
      name: claim?.policyDetails?.name || "",
      address: claim?.policyDetails?.address?.addressLine || "",
      city: claim?.policyDetails?.address?.city || "",
      postalCode: claim?.policyDetails?.address?.postalCode || "",
      province: claim?.policyDetails?.address?.province || "",
      brokerId: claim?.brokerId || "",
    },
  });

  const handleUpdateClick = () => {
    setIsEditing(true); // Open the form
  };

  const handleFormClose = () => {
    setIsEditing(false); // Close the form
  };

  const fetchPolicyDetails = async () => {
    try {
      const { data } = await axios.get(`/api/v1/claims/${claim._id}`);
      setPolicyDetails(data.policyDetails);
      setFormData({
        policyDetails: {
          ...data.policyDetails,
          effectiveDate: data.policyDetails.effectiveDate
            ? new Date(data.policyDetails.effectiveDate).toISOString().split("T")[0]
            : "",
          expiryDate: data.policyDetails.expiryDate
            ? new Date(data.policyDetails.expiryDate).toISOString().split("T")[0]
            : "",
        },
      });
    } catch (error) {
      console.error("Failed to fetch policy details:", error);
    }
  };

  const handleSubmit = async (event) => {
    if (event) event.preventDefault(); // Prevent form default behaviour
    try {
      const { data } = await axios.put(
        `/api/v1/claims/${claim?._id}`,
        { formData },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        toast.success(data.message);
        fetchPolicyDetails();
        setIsEditing(false); // Close the form
      }
    } catch (error) {
      console.error(error);
      toast.error("Error updating policy details.");
    }
  };

  return (
    
    <Row>
      <Col>
        <h1>Policy Details</h1>
      </Col>
      
            <Col>
            {!isEditing && <Btn onClick={handleUpdateClick}>Update</Btn>}
      </Col>
      <Col md={12}>
      {isEditing ? (
          <PolicyDetailsForm
            claim={claim}
            formData={formData}
            setFormData={setFormData}
            policyTypes={policytypes} // Pass policy types to form
            onSubmit={handleSubmit}
          />
        ) : (
        <Row className="d-flex align-items-center">
          <Col md={6}>
      <Field>
          <Label>Insured</Label>
          <Value>{claim?.policyDetails?.name || ""}</Value>
          <Value>{claim?.policyDetails?.address?.addressLine || ""}</Value>
          <Value>{claim?.policyDetails?.address?.city || ""} {claim?.policyDetails?.address?.province || ""}, {claim?.policyDetails?.address?.postalCode || ""}</Value>
        </Field>
        
        </Col>
        <Col md={6}>
        <Field>
        <Label>Broker</Label>
               <Value>{claim?.brokerId.name || ""}</Value> 
               <Value>{claim?.brokerId.address.addressLine || ""}</Value>
               <Value>{claim?.brokerId.address.city || ""}, {claim?.brokerId.address.province || ""} {claim?.brokerId.address.postalCode || ""}</Value>
               <Value>
  {claim?.brokerId.email && (
    <a
      href={`mailto:${claim.brokerId.email}?subject=${encodeURIComponent(
        `Insured: ${claim?.policyDetails?.name || ""} / Claim #: ${
          claim?.clients?.[0]?.claimNumber || ""
        } / File #: ${claim?.fileNumber || ""}`
      )}&body=${encodeURIComponent(
        `Insured: ${claim?.policyDetails?.name || ""}
Mailing Address: ${claim?.policyDetails?.address?.addressLine || ""}, ${
          claim?.policyDetails?.address?.city || ""
        }, ${claim?.policyDetails?.address?.province || ""} ${
          claim?.policyDetails?.address?.postalCode || ""
        }
Loss Address: ${claim?.lossAddress || ""}, ${
          claim?.lossCity || ""
        }, ${claim?.lossProvince || ""} ${
          claim?.lossPostalCode || ""
        }
Insurer: ${claim?.clients?.[0]?.client?.name || ""}
Policy Number: ${claim?.policyDetails?.policyNumber || ""}
Claim #: ${claim?.clients?.[0]?.claimNumber || ""}
Our File #: ${claim?.fileNumber || ""}`
      )}`}
      style={{ textDecoration: "none", color: "blue" }}
    >
      {claim.brokerId.email}
    </a>
  )}
</Value>
            </Field>

        </Col>
        
        <Divider />    
        <Col md={6}>
        <Field>
          <Label>Policy #</Label>
          <Value>{claim?.policyDetails?.policyNumber || ""}</Value>
        </Field>
        </Col> 
        <Col md={6}>
        <Field>
          <Label>Policy Type</Label>
          <Value>{claim?.policyDetails?.typeOfPolicyId?.description || ""}</Value>
        </Field>
        </Col>
        <Divider />   

        <Col md={6}>
        <Field>
          <Label>Deductible</Label>
          <Value>${claim?.policyDetails?.deductible || ""}</Value>
        </Field>
        </Col>
        <Divider />
        <Col md={6}>
        <Field>
          <Label>Effective Date</Label>
          <Value>
  {claim?.policyDetails?.effectiveDate
    ? new Date(claim.policyDetails.effectiveDate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : ""}
</Value>
</Field>
        </Col>        
        <Col md={6}>    
        <Field>
          <Label>Expiry Date</Label>
          <Value>{claim?.policyDetails?.expiryDate ? new Date(claim.policyDetails.expiryDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : ""}</Value>
        </Field>
        </Col>  
              

        <Divider />
        </Row>
        )}
      </Col>
    </Row>
  );
};

export default PolicyDetails;
