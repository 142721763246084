import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Layout from "../../components/Layout/Layout";
import TemplateList from "../../components/admin/templatesPageComponents/ResourceTemplateList";
import { useAuth } from "../../context/auth";
import AdminFilesList from "../../components/admin/templatesPageComponents/AdminFolderFilesList";

const Container = styled.div`
  display: flex;
  background: white;
  padding: 20px;
  border-radius: 8px;
  flex-direction: column;
  flex-grow: 1;
`;

const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AdminResourcesFilesList = () => {
  const [auth] = useAuth();
  const [locationId] = useState("");
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(`/api/v1/templates/${locationId}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setTemplates(response.data);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    fetchTemplates();
  }, [auth, locationId]);

  return (
    <Layout>
      <Container>
        <AdminFilesList templates={templates} setTemplates={setTemplates} />
      </Container>
    </Layout>
  );
};

export default AdminResourcesFilesList;
