import React, { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import ClaimDetails from './ClaimDetails';
import PolicyDetails from './PolicyDetails';
import ClientSelector from './ClientSelector';
import ExaminerSelector from './ExaminerSelector';
import BrokerSelector from './BrokerSelector';
import toast from 'react-hot-toast';
import Button from '../../common/Button';
import styled from 'styled-components';
import Layout from '../../Layout/Layout';
import { useAuth } from '../../../context/auth';
import { useNavigate } from 'react-router-dom';

const BtnContainer = styled.div`
  margin: 30px;
  display: flex;
  justify-content:space-between;
`;


const AddClaimForm = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(
    {
        dateOfLoss: '',
        
        proscriptionDate:'',
        typeOfClaimId: '',
        typeOfLossId: '',
        lossAddress: '',
        lossCity: '',
        lossProvince:'',
        lossPostalCode: '',
        clients: [],
        locationId: '',
        adjusterId: '',
        examinerId:'',
        lossDetail: '',
        policyDetails: {
          policyNumber: '',
          typeOfPolicyId:'',
          effectiveDate:'',
          expiryDate:'',
          deductible: '',
          name: '',
          address: '',
          city: '',
          postalCode: '',
          province: '',
        },
        brokerId: '',
        rate: "",
      }
  );

  // Define Yup validation schema
const claimValidationSchema = Yup.object().shape({
  brokerId: Yup.object().required('Broker is required')
});

const [errors, setErrors] = useState({});

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const url = `/api/v1/claims`;
    
    // Validate form data with Yup
    await claimValidationSchema.validate(formData, { abortEarly: false });
    
    // Submit the form data
    const res = await axios.post(url, {
      formData
    }, {
      headers: {
        'Authorization': `Bearer ${auth?.token}`,
      }
    });
    
    // Handle the response
    if (res && res.data.success) {
      toast.success(res.data.message);

      // Redirect to the new claim page if URL is provided
      if (res.data.redirectUrl) {
        navigate(res.data.redirectUrl);
      }

      // Reset form and step after successful submission
      setStep(1);
      setFormData({
        dateOfLoss: '',
        proscriptionDate: '',
        typeOfClaimId: '',
        typeOfLossId: '',
        lossAddress: '',
        lossCity: '',
        lossProvince: '',
        lossPostalCode: '',
        locationId: '',
        clients: [],
        adjusterId: '',
        examinerId: '',
        lossDetail: '',
        policyDetails: {
          policyNumber: '',
          typeOfPolicyId: '',
          effectiveDate: '',
          expiryDate: '',
          deductible: '',
          name: '',
          address: '',
          city: '',
          postalCode: '',
          province: '',
        },
        brokerId: '',
        rate: '',
      });
    } else {
      toast.error(JSON.stringify(res.data.message));
    }
  } catch (err) {
    // Handle validation errors
    const validationErrors = {};
    err?.inner?.forEach((error) => {
      validationErrors[error.path] = error.message;
    });
    setErrors(validationErrors);
    toast.error(err?.response?.data?.message || 'An error occurred');
  }
};


  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };


  return (
    <>
    <Layout>
    <form onSubmit={handleSubmit}>
    <h1>Add a Claim</h1>
    {step === 1 && (
          <div className="input-container">
          <ClientSelector formData={formData} setFormData={setFormData} setStep={setStep}/>
          </div>
        )}
        {step === 3 && (
          <div className="input-container">
          <ClaimDetails formData={formData} setFormData={setFormData} setStep={setStep} />
      </div>
        )}
        {step === 4 && (
           <div className="input-container">
           <ExaminerSelector formData={formData} setFormData={setFormData} setStep={setStep}/>
          
        </div>
        )}

        {step === 2 && (
        <div className="input-container">
        <PolicyDetails formData={formData} setFormData={setFormData}  setStep={setStep}/>
        </div>
        )}
        {step === 5 && (
           <div className="input-container">
           <BrokerSelector formData={formData} setFormData={setFormData} setStep={setStep}/>
           {errors.brokerId && <div>{errors.brokerId}</div>}
           <BtnContainer>
          <Button type="button" onClick={handlePrevious}>
            Previous
          </Button>
          <Button type="submit">Submit</Button>
          </BtnContainer>
        </div>
        )}
    </form>
    </Layout>
    </>
  );
};

export default AddClaimForm;
