import React, { useState } from 'react';
import styled from 'styled-components';
import AdminIcon from '../../assets/icons/admin_icon';
import DashboardIcon from '../../assets/icons/dashboard_icon';
import ClientsIcon from '../../assets/icons/clients_icon';
import ClaimsIcon from '../../assets/icons/claims_icon';
import ResourcesIcon from '../../assets/icons/resources_icon';
import AddClaimIcon from '../../assets/icons/add_claim_icon';
import ReportsIcon from '../../assets/icons/reports_icon';
import logo from '../../assets/logo.png';
import hamburger from '../../assets/hamburger.png';
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { FaUser } from "react-icons/fa";

const SidebarParent = styled.div`
  background: #040848;
  padding: 0% 2%;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Makes the sidebar full height */
  @media only screen and (max-width: 768px) {
    width: auto;
    height: auto; /* Adjust height for mobile view */
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const MenuContainer = styled.div`
  flex-direction: column;
  display: flex;
  @media only screen and (max-width: 768px) {
    display: ${props => props.open ? 'flex' : 'none'};
  }
`;

const MenuButton = styled.button`
  display: none;
  background: url(${hamburger}) no-repeat center center;
  background-size: contain;
  width: 44px;
  height: 44px;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const LogoComponent = styled.div`
  font-size: 24px;
`;

const AddNewClaim = styled.div`
  font-size: 16px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px dashed #0337A4;
  border-radius: 6px;
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #0337A4;
    border-radius: 6px;
  }
`;

const IconWrapper = styled.span`
  margin-right: 10px;
`;

const AdminLinks = () => (
  <>
    <NavLink href="/admin/locations">Locations</NavLink>
    <NavLink href="/admin/roles">Roles</NavLink>
    <NavLink href="/admin/permissions">Permissions</NavLink>
    <NavLink href="/admin/templates">Templates</NavLink>
    <NavLink href="/admin/users">Users</NavLink>
    <NavLink href="/admin/history">History</NavLink>
    <NavLink href="/admin/system-fields">System Fields</NavLink>
    <NavLink href="/admin/resource">Resources</NavLink>
  </>
);

const UserLinks = () => (
  <>
    <NavLink href="/add-new-claim">
      <AddNewClaim>
        <IconWrapper><AddClaimIcon /></IconWrapper>
        <small>Add New Claim</small>
      </AddNewClaim>
    </NavLink>
    <NavLink href="/dashboard/user">
      <IconWrapper><DashboardIcon /></IconWrapper>
      Dashboard
    </NavLink>
    <NavLink href="/claims">
      <IconWrapper><ClaimsIcon /></IconWrapper>
      Claims
    </NavLink>
    <NavLink href="/clients">
      <IconWrapper><ClientsIcon /></IconWrapper>
      Clients
    </NavLink>
    <NavLink href="/vendors">
      <IconWrapper><ClientsIcon /></IconWrapper>
      Vendors
    </NavLink>
    <NavLink href="/reports">
      <IconWrapper><ReportsIcon /></IconWrapper>
      Reports
    </NavLink>
    <NavLink href="/resources">
      <IconWrapper><ResourcesIcon /></IconWrapper>
      Resources
    </NavLink>
  </>
);

const Sidebar = () => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
    navigate(location.state || "/");
  };

  return (
    <SidebarParent>
      <LogoContainer>
        <LogoComponent>
          <img src={logo} alt='logo' />
        </LogoComponent>
        <MenuButton onClick={toggleMenu} />
      </LogoContainer>
      <MenuContainer open={isOpen}>
        {auth?.user ? (
          <>
            {auth?.user.role === 0 ? (
              <>
                <h2 style={{ color: 'white' }}>{auth?.user?.name}</h2>
                <UserLinks />
              </>
            ) : (
              <>
                <NavLink>
                  <span className='d-flex align-items-center gap-1'>
                    <FaUser /><span className='small'>{auth?.user?.name}</span>
                  </span>
                </NavLink>
                <AdminLinks />
              </>
            )}
            <NavLink onClick={handleLogout}>Logout</NavLink>
          </>
        ) : (
          <>
            
            <NavLink href="/login">Login</NavLink>
          </>
        )}
      </MenuContainer>
    </SidebarParent>
  );
};

export default Sidebar;
