import React from 'react';

const ResourcesIcon = () => {
  return (
    <div>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.1264 6.34729L12.8275 3.37821C11.8876 2.53231 11.4176 2.10937 10.841 1.8881L10.8334 4.16684C10.8334 6.13102 10.8334 7.11311 11.4435 7.72331C12.0537 8.3335 13.0358 8.3335 15 8.3335H17.9834C17.6813 7.74665 17.1403 7.25978 16.1264 6.34729Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.6667 18.3334H8.33335C5.19066 18.3334 3.61931 18.3334 2.643 17.3571C1.66669 16.3808 1.66669 14.8094 1.66669 11.6667V8.33341C1.66669 5.19072 1.66669 3.61937 2.643 2.64306C3.61931 1.66675 5.19893 1.66675 8.35818 1.66675C8.86318 1.66675 9.26785 1.66675 9.60836 1.68063C9.59715 1.74724 9.59128 1.81519 9.59105 1.88389L9.58335 4.24589C9.58328 5.16014 9.58321 5.96811 9.67077 6.61938C9.76568 7.32531 9.98361 8.03113 10.5597 8.60719C11.1357 9.18325 11.8415 9.40117 12.5475 9.49608C13.1987 9.58364 14.0067 9.58358 14.921 9.58351L15 9.5835H18.2979C18.3334 10.0287 18.3334 10.5752 18.3334 11.3025V11.6667C18.3334 14.8094 18.3334 16.3808 17.357 17.3571C16.3807 18.3334 14.8094 18.3334 11.6667 18.3334ZM8.74858 12.0329C9.00657 12.2623 9.02981 12.6573 8.80048 12.9153L6.57826 15.4153C6.45966 15.5487 6.28965 15.6251 6.11113 15.6251C5.93261 15.6251 5.7626 15.5487 5.644 15.4153L4.53289 14.1653C4.30357 13.9073 4.3268 13.5123 4.58479 13.2829C4.84278 13.0536 5.23783 13.0769 5.46715 13.3349L6.11113 14.0593L7.86622 12.0849C8.09555 11.8269 8.49059 11.8036 8.74858 12.0329Z" fill="white"/>
        </svg>
    </div>
  );
};

export default ResourcesIcon;