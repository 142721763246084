import styled from 'styled-components';
import React, {useState,useEffect} from 'react';
import axios from 'axios';
import deleteIcon from '../../assets/delete.png';
import writeIcon from '../../assets/Write.png';
import toast from "react-hot-toast";
import CreateClaimTypeForm from './forms/CreateClaimTypeForm';
import { useAuth } from '../../context/auth';
import { Col, Row } from 'reactstrap';
import FillBtn from '../../shared/Buttons/FillBtn';


const TableContainer = styled.div`
  width:auto;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  width: auto;
  border-collapse: collapse;
  margin:10px;
`;

const TableHeader = styled.th`
  padding: 15px;
  text-align: left;
  background: #E0E4F3;
  color:#444444;
  font-family: Poppins;
  font-size: 15px; 
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  letter-spacing: 0.20000000298023224px;
`;


const TableRow = styled.tr`
    border-bottom: 1px solid #ddd;
    &:hover {
      background: #E0E4F3;
      border-radius:20px;
    }
`;


const TableCell = styled.td`
  text-align: left;
  padding: 8px;
  padding:15px;
  color:#9FA2B4;

`;


const HeaderContainer= styled.div`
  height: 5vh;
  padding:10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Btn = styled.button`
  background-color: #6495ED;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left:10px;
`;
const ActionIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${deleteIcon}); // Use the delete icon image
  background-size: cover;
  cursor: pointer;
`;

const EditIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${writeIcon}); // Use the delete icon image
  background-size: cover;
  cursor: pointer;
`;


const ClaimTypes = () => {
  const [auth] = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [claimTypes, setClaimTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const handleCreateButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/v1/claim-types/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setClaimTypes(prevClaimTypes => prevClaimTypes.filter(claimType => claimType._id !== id));
      toast.success('claim type deleted successfully');
    } catch (error) {
      if (error.response) {
        setError('Failed to delete');
      } else {
        setError('Error setting up the request');
      }
    }
  };

  useEffect(() => {
    const fetchClaimTypes = async () => {
      try {
        const response = await axios.get('/api/v1/claim-types', {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        if(response.data.success){
          setClaimTypes(response.data.claimTypes);
        setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error)
      }
    };
    fetchClaimTypes();
  }, []);

  return (
    <Row>
    <Col md={12}>
      <Row className="d-flex align-items-center justify-content-between">
        <Col md={9}>
          <HeaderContainer>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                alignItems: "center",
              }}
            >
          Claim Types
            </div>
          </HeaderContainer>
        </Col>
        <Col md={3} className="text-end">
        <div>
        <FillBtn
            handleOnClick={handleCreateButtonClick}
            className="w-100" // Change to auto to avoid taking full width
            text="Create"
          />
               <CreateClaimTypeForm isOpen={isModalOpen} onClose={handleCloseModal} setClaimTypes={setClaimTypes}/>
          </div>

         
        </Col>
        <Row>
          <Col md={12}>
          <TableContainer>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {!loading && !error && (
        <Table>
          <thead>
            <TableRow>
               <TableHeader>Code</TableHeader>
               <TableHeader>Description</TableHeader>
              <TableHeader>Action</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {claimTypes.map(claimType => (
              <TableRow key={claimType._id}>
                <TableCell>{claimType.code}</TableCell>
                <TableCell>{claimType.description}</TableCell>
                <TableCell align="right">
                    <ActionIcon onClick={()=>handleDelete(claimType._id)}/>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      )}
        </TableContainer>
          </Col>
        </Row>
      </Row>
    </Col>
  </Row>





  
  )
}

export default ClaimTypes
