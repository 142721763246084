import React, { useRef } from "react";
import axios from "axios";
import "./AdminTable.css";
import toast from "react-hot-toast";
import styled from "styled-components";
import { MdDelete } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useAuth } from "../../../context/auth";
import writeIcon from "../../../assets/Write.png";
import { IoFolderOpenOutline } from "react-icons/io5";
import { MdOutlineCloudUpload } from "react-icons/md";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  Button,
  Col,
  Input,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

const EditIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${writeIcon}); // Use the edit icon image
  background-size: cover;
  cursor: pointer;
`;
const SubText = styled.div`
  color: #000000d9;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
`;
const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AdminFilesList = ({ templates, setTemplates, claimId }) => {
  const [auth] = useAuth();
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [folderId, setFolderId] = useState(null);
  const [allFolders, setAllFolders] = useState([]);
  const [newFolderName, setNewFolderName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // New state for modal toggle

  const navigate = useNavigate();

  const toggleModal = () => setModalOpen(!modalOpen);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const location = useLocation();
  const receivedData = location?.state;
  console.log("receivedData",receivedData)
  const fileInputRef = useRef(null); 

  const handleSaveClick = async () => {
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }
  
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("name", selectedFile.name); // Use the actual file name
    formData.append("type", receivedData?.type);
    formData.append("resourcesfolderId", receivedData?._id);
  
    try {
      const { data } = await axios.post(
        `/api/v1/createResourseDocument`,
        formData
      );
      if (data.success) {
        handleFolderOnClick(folderId);
        setSelectedFile(null);
        toggleModal();
        navigate("/admin/resource");
        toast.success("File uploaded successfully");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while uploading the file.");
    } finally {
      setLoading(false);
    }
  };
  

  const handleFolderOnClick = (folderId) => {
    setFolderId(folderId);
    toggleModal();
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`/api/v1/deleteResoursedocument?resourcesdocId=${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setTemplates((prevTemplates) =>
        prevTemplates.filter((template) => template._id !== id)
      );
      toast.success("Template deleted successfully");
      navigate("/admin/resource" );
    } catch (error) {
      toast.error("Something went wrong while deleting the template.");
    }
  };

  const handleDownloadClick = (url) => {
    window.open(url, "_blank");
  };

 

  return (
    <Row>
      <Col md={12}>
    <Row  className="d-flex align-items-center justify-content-between">
    <Col md={6}>
        <h6 className=" ">All Files</h6>
      </Col>
      <Col md={4}>
      
          <div className="d-flex gap-2 mb-3">
            <Input
              type="file"
              onChange={handleFileChange}
              ref={fileInputRef} 
             accept=".pdf, .doc, .docx"
            />
            <Button onClick={handleSaveClick} disabled={loading}>
              {loading ? "Uploading..." : "Upload"}
            </Button>
          </div>
       
      </Col>
    </Row>
    
   
      <Col md={12}>
        <Table className="stripedTable">
          <Thead>
            <Tr>
              <Th>Document Name</Th>
              <Th>Date Added</Th>
              <Th> Date Updated</Th>
              <Th> Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {receivedData?.documents.map((row) => (
              <Tr key={row._id}>
                <Td> {row.name}</Td>
                <Td>{row.createdAt}</Td>
                <Td>{row.updatedAt}</Td>
                <Td align="right">
                  <div className="d-flex align-items-center gap-2">
            
                  <MdDelete size={24} className="cursorPointer" onClick={() => handleDeleteClick(row._id)} />
                  </div>
              
                  
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Col>
      {/* <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Upload Document</ModalHeader>
        <ModalBody>
          <Input
            type="file"
            onChange={handleFileChange}
            className="w-100"
            accept=".docx"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveClick} disabled={loading}>
            {loading ? "Uploading..." : "Upload"}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal> */}
      </Col>
    </Row>
  );
};

export default AdminFilesList;
