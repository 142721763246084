import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "../context/auth";

export default function useRoles() {
  const [auth] = useAuth();
  const [roles, setRoles] = useState([]);

  //get roles
  const getRoles = async () => {
    try {
        const {data} = await axios.get('/api/v1/roles', {
          headers: {
            'Authorization': `Bearer ${auth?.token}`,
          }
        });
        if(data.success){
          setRoles(data.roles)
        }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong fetching roles")
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return roles;
}