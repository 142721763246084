import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import axios from 'axios';
import ContactTable from './ContactTable';
import ContactForm from './ContactForm';
import Button from '../../common/Button'
import Modal from '../../common/Modal';
import { useAuth } from '../../../context/auth';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Contacts = ({ client }) => {
  const [auth] = useAuth();
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: '',
    phone: '',
  });

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const { data } = await axios.get(`/api/v1/client/contacts/${client._id}`,{
          headers: {
            'Authorization': `Bearer ${auth?.token}`,
          }
        });
        if (data.success) {
          setContacts(data.contacts);
        } else {
          toast.error('Failed to fetch contacts');
        }
      } catch (error) {
        console.error('Error fetching contacts:', error);
        toast.error('Something went wrong while fetching contacts');
      } finally {
        setLoading(false);
      }
    };
    fetchContacts();
  }, [client._id]);

  const handleAddContact = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`/api/v1/contacts/${client._id}`, {formData}, {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      });

      if (data.success) {
        setContacts([data.contact, ...contacts]);
        setFormData({ name: '', email: '', role: '', phone: '' });
        toast.success('Contact added successfully');
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error adding contact:', error);
      toast.error('Something went wrong while adding the contact');
    }
  };

  const handleUpdateContact = async (e) => {
    e.preventDefault();
    try {
      const {data} = await axios.put(`/api/v1/contacts/${selectedContact._id}`, {formData}, {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      });
      if (data.success) {
        const updatedContacts = contacts.map(contact =>
          contact._id === selectedContact._id ? data.contact : contact
        );
        setContacts(updatedContacts);
        setSelectedContact(null);
        setIsModalOpen(false);
        setFormData({ name: '', email: '', role: '', phone: '' });
        toast.success('Contact updated successfully');
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error updating contact:', error);
      toast.error('Something went wrong while updating the contact');
    }
  };

  const handleDeleteContact = async (id) => {
    try {
      const { data } = await axios.delete(`/api/v1/contacts/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      });
      if (data.success) {
        setContacts(contacts.filter(contact => contact._id !== id));
        toast.success('Contact deleted successfully');
      } else {
        toast.error('Failed to delete contact');
      }
    } catch (error) {
      console.error('Error deleting contact:', error);
      toast.error('Something went wrong while deleting the contact');
    }
  };

  const handleEditContact = (contact) => {
    setSelectedContact(contact);
    setFormData({ name: contact.name, email: contact.email, role: contact.role, phone: contact.phone });
    setIsModalOpen(true);
  };

  return (
    <Container>
      <HeaderContainer>
        <div style={{ marginLeft: 'auto' }}>
          <Button onClick={() => setIsModalOpen(true)}>Add</Button>
        </div>
      </HeaderContainer>
      <ContactTable
        loading={loading}
        contacts={contacts}
        onEdit={handleEditContact}
        onDelete={handleDeleteContact}
      />
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ContactForm
          formData={formData}
          setFormData={setFormData}
          handleSubmit={selectedContact ? handleUpdateContact : handleAddContact}
          title={selectedContact ? 'Update Contact' : 'Add Contact'}
        />
      </Modal>
    </Container>
  );
};

export default Contacts;
