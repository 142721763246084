import React, { useEffect, useState } from "react";
import styled from "styled-components";
import sorter from "../../assets/sorter.png";
import axios from "axios";
import toast from "react-hot-toast";
import Modal from "../common/Modal";
import writeIcon from "../../assets/Write.png";
import AddTaskForm from "./AddTaskForm";
import { useAuth } from "../../context/auth";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { MenuOutlined } from "@mui/icons-material";
import { FaEllipsisV } from "react-icons/fa";
import FillBtn from "../../shared/Buttons/FillBtn";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left: 20px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;


const TableHeader = styled.th`
  padding: 15px;
  position: relative;
  text-align: left;
  background: #e0e4f3;
  color: #444444;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  letter-spacing: 0.20000000298023224px;
`;

const SorterIcon = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 36px;
  margin-left: 5px;
  background-image: url(${sorter}); // Use the sorter icon image
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer; // Add cursor pointer for hover effect
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
  text-align: left;
  padding: 8px;
  padding: 15px;
  white-space: nowrap;
  color: #9fa2b4;
`;

function createData(date, use_code, description) {
  return { date, use_code, description };
}

const Tasks = ({ claimId, userCode }) => {
  useEffect(() => {
    fetchTasks();
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [formData, setFormData] = useState({
    dueDate: "",
    description: "",
    userCode: "",
  });
  const [auth] = useAuth();

  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const fetchTasks = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/getopenCloseClaimTask?claimId=${claimId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        setTasks(data?.claimTasks);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while getting the notes");
    }
  };

  // const handleAddSubmit = async (event, resetForm) => {
  //   event.preventDefault();
  //   try {
  //     const { data } = await axios.post(
  //       `/api/v1/createClaimTask`,
  //       {
  //         ...formData,
  //         claim_id: claimId,
  //         // userCode,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${auth?.token}`,
  //         },
  //       }
  //     );

  //     if (data.success) {
  //       resetForm();
  //       fetchTasks();
  //       setIsModalOpen(false);
  //       toast.success("Task added successfully!");
  //     } else {
  //       toast.error(data.message);
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong while getting the notes");
  //   }
  // };

  const handleAddSubmit = async (event, resetForm) => {
    event.preventDefault();

    // Function to calculate days difference
    const calculateDaysDifference = (dueDate) => {
      const currentDate = new Date();
      const dueDateObj = new Date(dueDate);

      const timeDifference = dueDateObj - currentDate;
      return Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert ms to days
    };

    // Calculate days until due date using the function
    const daysUntilDue = calculateDaysDifference(formData.dueDate);

    try {
      const { data } = await axios.post(
        `/api/v1/createClaimTask`,
        {
          ...formData,
          claim_id: claimId,
          dueDate: daysUntilDue, // Include calculated days in the payload
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        resetForm();
        fetchTasks();
        setIsModalOpen(false);
        toast.success("Task added successfully!");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while getting the notes");
    }
  };

  const handleEditSubmit = async (event, resetForm) => {
    event.preventDefault();
    try {
      const { data } = await axios.put(
        `/api/v1/updateClaimTask/${taskId}`,
        {
          ...formData,
          claim_id: claimId,
          // userCode,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        resetForm();
        fetchTasks();
        setIsModalOpen(false);
        setTaskId(null);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while getting the notes");
    }
  };

  const handleMarkAsClose = async (taskId) => {
    try {
      const { data } = await axios.put(
        `/api/v1/updateTaskStatus/${taskId}`,
        {
          claimStatus: false,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        fetchTasks();
        toast.success("Status changed");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while getting the notes");
    }
  };

  const handleMarkAsOpen = async (taskId) => {
    try {
      const { data } = await axios.put(
        `/api/v1/updateTaskStatus/${taskId}`,
        {
          claimStatus: true,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        fetchTasks();
        toast.success("Status changed");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while getting the notes");
    }
  };

  const handleEditClick = (row) => {
    setFormData({
      ...row,
    });
    setTaskId(row?._id);
    setIsModalOpen(true);
  };

  return (
    <Container>
      <HeaderContainer className="my-2">
        <div>Open Tasks</div>
        <div>
          <FillBtn
            className="mb-5"
            text="Add"
            handleOnClick={() => setIsModalOpen(true)}
          />
        </div>
      </HeaderContainer>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>
                Due Date
                <SorterIcon />
              </TableHeader>
              <TableHeader>
                User Code
                <SorterIcon />
              </TableHeader>
              <TableHeader>
                Description
                <SorterIcon />
              </TableHeader>
              <TableHeader>
                Action
                <SorterIcon />
              </TableHeader>
            </TableRow>
          </thead>
          <tbody>
  {tasks.map((row, index) => {
    return (
      row?.claimStatus && (
        <TableRow key={row._id}>
          <TableCell component="th" scope="row">
            {row?.dueDate ?? row?.createdAt}
          </TableCell>
          <TableCell align="right">{row?.userCode}</TableCell>
          <TableCell align="right">{row?.description}</TableCell>
          <TableCell align="right">
            <Dropdown
              isOpen={openDropdown === index}
              toggle={() => toggleDropdown(index)}
            >
              <DropdownToggle className="bg-transparent border-0">
                <FaEllipsisV color="black" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => handleMarkAsClose(row?._id)}
                >
                  Mark As Closed
                </DropdownItem>
                <DropdownItem onClick={() => handleEditClick(row)}>
                  Edit
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </TableCell>
        </TableRow>
      )
    );
  })}
</tbody>
        </Table>
      </TableContainer>
      <HeaderContainer>
        <div>Closed Tasks</div>
      </HeaderContainer>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>
                Due Date
                <SorterIcon />
              </TableHeader>
              <TableHeader>
                User Code
                <SorterIcon />
              </TableHeader>
              <TableHeader>
                Description
                <SorterIcon />
              </TableHeader>
              <TableHeader>
                Action
                <SorterIcon />
              </TableHeader>
            </TableRow>
          </thead>
          <tbody>
  {tasks.map((row, index) => {
    return (
      !row?.claimStatus && (
        <TableRow key={row._id}>
          <TableCell component="th" scope="row">
            {row?.dueDate ?? row?.createdAt}
          </TableCell>
          <TableCell align="right">{row?.userCode}</TableCell>
          <TableCell align="right">{row?.description}</TableCell>
          <TableCell align="right">
            <Dropdown
              isOpen={openDropdown === index}
              toggle={() => toggleDropdown(index)}
            >
              <DropdownToggle className="bg-transparent border-0">
                <FaEllipsisV color="black" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => handleMarkAsOpen(row?._id)}
                >
                  Re-Open
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </TableCell>
        </TableRow>
      )
    );
  })}
</tbody>

        </Table>
      </TableContainer>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddTaskForm
          formData={formData}
          setFormData={setFormData}
          handleSubmit={taskId ? handleEditSubmit : handleAddSubmit}
          title={taskId ? "Edit Task" : "Add Task"}
        />
      </Modal>
    </Container>
  );
};

export default Tasks;
