import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify"; // Assuming you're using toast for notifications
import { useAuth } from "../../context/auth";
import * as Yup from "yup";
import { Col, Row } from "reactstrap";
import FillBtn from "../../shared/Buttons/FillBtn";
// Styled components
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

const FieldContainer = styled.div`
  flex: 0 0 calc(50% - 20px); /* Two fields per row with some space */
  flex-wrap: wrap;
  margin: 10px;
`;

const Field = styled.div`
  margin: 20px 10px 10px 20px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding: 10px;
  align-self: flex-start;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

// Yup validation schema
const claimValidationSchema = Yup.object().shape({
  clients: Yup.string(),
  claimNumber: Yup.string(),
  dateOfLoss: Yup.date(),
  typeOfClaimId: Yup.string(),
  lossAddress: Yup.string(),
  lossPostalCode: Yup.string(),
  claimStatus: Yup.string(),
  examinerId: Yup.string(),
  typeOfLossId: Yup.string(),
  proscriptionDate: Yup.date(),
  adjusterId: Yup.string(),
  lossCity: Yup.string(),
  lossProvince: Yup.string(),
  lossDetail: Yup.string(),
});

const Details = ({ claim }) => {
  console.log("myClaimIdDetailComp",claim)
  const [auth] = useAuth();
  const [errors, setErrors] = useState({});
  const [clients, setClients] = useState([]);
  const [examiners, setExaminers] = useState([]);
  const [adjusters, setAdjusters] = useState([]);
  const [claimTypes, setClaimTypes] = useState([]);
  const [lossTypes, setLossTypes] = useState([]);
const [clientSearch, setClientSearch] = useState('');
const [filteredClients, setFilteredClients] = useState([]);
const [submissionSuccess, setSubmissionSuccess] = useState(false);

const [selectedAdjuster, setSelectedAdjuster] = useState("");
const [selectedClaimType, setSelectedClaimType] = useState("");
const [selectedLossType, setSelectedLossType] = useState("");
const [selectedExaminer, setSelectedExaminer] = useState("");


const [formData, setFormData] = useState({
  fileNumber: '',
  clientsData: [{ clientId: '', claimNumber: '', sharePercentage: '' }],
  dateOfLoss: '',
  typeOfClaimId: '',
  lossAddress: '',
  lossPostalCode: '',
  claimStatus: '',
  examinerId: '',
  typeOfLossId: '',
  proscriptionDate: '',
  adjusterId: '',
  lossCity: '',
  lossProvince: '',
  lossDetail: '',
});
useEffect(() => {
  if (claim) {
    setFormData({
      fileNumber: claim.fileNumber,
      clientsData: claim.clients.map(c => ({
        clientId: c.client._id,
        claimNumber: c.claimNumber,
        sharePercentage: c.sharePercentage
      })),
      dateOfLoss: claim.dateOfLoss?.substr(0, 10) || '',
      typeOfClaimId: claim.typeOfClaimId?._id || '',
      lossAddress: claim.lossAddress || '',
      lossPostalCode: claim.lossPostalCode || '',
      claimStatus: claim.claimStatus || '',
      examinerId: claim.examinerId?._id || '',
      typeOfLossId: claim.typeOfLossId?._id || '',
      proscriptionDate: claim.proscriptionDate?.substr(0, 10) || '',
      adjusterId: claim.adjusterId?._id || '',
      lossCity: claim.lossCity || '',
      lossProvince: claim.lossProvince || '',
      lossDetail: claim.lossDetail || '',
    });
  }
}, [claim]);

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({ ...prevData, [name]: value }));
};


const handleSubmit = async () => {
  console.log("awdawda", formData);

  try {
    const { data } = await axios.put(
      `/api/v1/claims/${claim?._id}`,
      {
        formData,
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    );

    if (data.success) {
      toast.success(data.message);
      window.location.reload();
    }
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong while updating policy details");
  }
};


  // Fetch adjusters on component mount
  useEffect(() => {
    const fetchAdjusters = async () => {
      try {
        const response = await axios.get("/api/v1/users/adjusters", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setAdjusters(response.data.adjusters); // Assuming the API response has a key "adjusters"
      } catch (error) {
        console.error("Error fetching adjusters:", error);
      }
    };

    fetchAdjusters();
  }, [auth?.token]);

  useEffect(() => {
    const fetchClaimTypes = async () => {
      try {
        const response = await axios.get("/api/v1/claim-types", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setClaimTypes(response.data.claimTypes);
      } catch (error) {
        console.error("Error fetching claim types:", error);
      }
    };

    fetchClaimTypes();
  }, [auth?.token]);

  useEffect(() => {
    const fetchLossTypes = async () => {
      try {
        const response = await axios.get("/api/v1/loss-types", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setLossTypes(response.data.lossTypes); // Assuming the API response has a key "lossTypes"
      } catch (error) {
        console.error("Error fetching loss types:", error);
      }
    };

    fetchLossTypes();
  }, [auth?.token]);
  // Fetch examiners using PUT method
  useEffect(() => {
    if (claim && claim.clients) {
      const clientIds = claim.clients.map((item) => item.client._id);
      const fetchExaminers = async () => {
        try {
          const response = await axios.post(
            "/api/v1/clients/contacts/examiners",
            { clientIds },
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
              },
            }
          );

          console.log("examinersUseEffect response:", response);

          if (response.data && response.data.examiners) {
            console.log("Examiners Data:", response.data.examiners);
            setExaminers(response.data.examiners);  
          } else {
            console.log("No examiners data found");
          }
        } catch (error) {
          console.error("Error fetching examiners:", error);
        }
      };

      fetchExaminers();
    }
  }, [claim, auth?.token]);

  useEffect(() => {

    const fetchClients = async () => {
      const url = `/api/v1/clients`;
      try {
        const res = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${auth?.token}`,
          }
        });
        if (res && res.data.success) {
          setClients(res.data.clients);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message)
      }
    } 

    fetchClients();
  }, [auth?.token]);
useEffect(() => {
  setFilteredClients(clients);
}, [clients]);

  const handleAdjusterChange = (e) => {
    const selectedAdjusterId = e.target.value;
    setSelectedAdjuster(selectedAdjusterId);
    setFormData({ ...formData, adjusterId: selectedAdjusterId });
  };

  const handleClaimTypeChange = (e) => {
    const selectedClaimTypeId = e.target.value;
    setSelectedClaimType(selectedClaimTypeId);
    setFormData({ ...formData, typeOfClaimId: selectedClaimTypeId });
  };

  const handleLossTypeChange = (e) => {
    const selectedLossTypeId = e.target.value;
    setSelectedLossType(selectedLossTypeId);
    setFormData({ ...formData, typeOfLossId: selectedLossTypeId });
  };

  const handleExaminerChange = (e) => {
    const selectedExaminerId = e.target.value;
    setSelectedExaminer(selectedExaminerId);
    setFormData({ ...formData, examinerId: selectedExaminerId });
  };
const addClient = () => {
  setFormData(prevData => ({
    ...prevData,
    clientsData: [...prevData.clientsData, { clientId: '', claimNumber: '', sharePercentage: '' }]
  }));
};

const removeClient = (index) => {
  if (formData.clientsData.length > 1) {
    setFormData(prevData => ({
      ...prevData,
      clientsData: prevData.clientsData.filter((_, i) => i !== index)
    }));
  }
};

const handleClientChange = (index, field, value) => {
  setFormData((prevData) => ({
    ...prevData,
    clientsData: prevData.clientsData.map((client, i) =>
      i === index ? { ...client, [field]: value } : client
    ),
  }));
  if (field === 'clientId') {
    const selectedClient = clients.find(c => c._id === value);
    if (selectedClient) {
      setClientSearch(selectedClient.name);
    }
  }
};
const handleClientSearch = (index, searchTerm) => {
  setClientSearch(searchTerm);
  if (searchTerm.length > 0) {
    const filtered = clients.filter(client => 
      client.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredClients(filtered);
  } else {
    setFilteredClients([]);
  }

  // Update the clientId in formData
  setFormData((prevData) => ({
    ...prevData,
    clientsData: prevData.clientsData.map((client, i) =>
      i === index ? { ...client, clientId: searchTerm } : client
    ),
  }));
};

const FillBtn = ({ type = "button", onClick, text, className }) => (
  <button type={type} onClick={onClick} className={className}>
    {text}
  </button>
);


  return (
    <Row>
      <Col md={12}>
        <form onSubmit={handleSubmit}>
          <Row className="d-flex align-items-center">
            <Col md={12}>
            {Array.isArray(formData.clientsData) && formData.clientsData.map((client, index) => (
                <Row key={index}>
                 <Col md={4}>
  <Field>
    <Label>Client {index + 1}</Label>
    <Input
      type="text"
      list={`clients-${index}`}
      value={clients.find(c => c._id === client.clientId)?.name || client.clientId}
      onChange={(e) => {
        const searchTerm = e.target.value;
        handleClientSearch(index, searchTerm);
      }}
      placeholder="Search for a client..."
    />
    {filteredClients.length > 0 && (
      <datalist id={`clients-${index}`}>
        {filteredClients.map((c) => (
          <option key={c._id} value={c.name} />
        ))}
      </datalist>
    )}
  </Field>
</Col>
                  <Col md={3}>
                    <Field>
                      <Label>Claim #</Label>
                      <Input
                        type="text"
                        value={client.claimNumber}
                        onChange={(e) => handleClientChange(index, 'claimNumber', e.target.value)}
                      />
                    </Field>
                  </Col>
                  <Col md={3}>
                    <Field>
                      <Label>Share %</Label>
                      <Input
                        type="number"
                        value={client.sharePercentage}
                        onChange={(e) => handleClientChange(index, 'sharePercentage', e.target.value)}
                      />
                    </Field>
                  </Col>
                  <Col md={2}>
                    <Field>
                      <Label>&nbsp;</Label>
                      {index === 0 ? (
                        <FillBtn type="button" onClick={addClient} text="+" />

                      ) : (
                        <FillBtn type="button" onClick={() => removeClient(index)} text="-" />
                      )}
                    </Field>
                  </Col>
                </Row>
              ))}
            </Col>

            

            <Col md={6}>
              <Field>
                <Label>Date of Loss</Label>
                <Input
                  type="date"
                  name="dateOfLoss"
                  value={formData.dateOfLoss}
                  onChange={handleChange}
                />
                {errors.dateOfLoss && <p>{errors.dateOfLoss}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Select Claim Type:</Label>
                <select
                  name="typeOfClaimId"
                  value={formData.typeOfClaimId} // Use the form data value here
                  onChange={handleClaimTypeChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                >
                  <option value="">Select Claim Type</option>
                  {claimTypes.map((claimType) => (
                    <option key={claimType._id} value={claimType?._id}>
                      {claimType?.description}
                    </option>
                  ))}
                </select>
                {errors.typeOfClaimId && <p>{errors.typeOfClaimId}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Loss Address</Label>
                <Input
                  type="text"
                  name="lossAddress"
                  value={formData.lossAddress}
                  onChange={handleChange}
                />
                {errors.lossAddress && <p>{errors.lossAddress}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Loss City</Label>
                <Input
                  type="text"
                  name="lossCity"
                  value={formData.lossCity}
                  onChange={handleChange}
                />
                {errors.lossCity && <p>{errors.lossCity}</p>}
              </Field>
            </Col>

            <Col md={6}>
              <Field>
                <Label>Loss Province</Label>
                <Input
                  type="text"
                  name="lossProvince"
                  value={formData.lossProvince}
                  onChange={handleChange}
                />
                {errors.lossProvince && <p>{errors.lossProvince}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Loss Detail</Label>
                <Input
                  type="text"
                  name="lossDetail"
                  value={formData.lossDetail}
                  onChange={handleChange}
                />
                {errors.lossDetail && <p>{errors.lossDetail}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Postal Code</Label>
                <Input
                  type="text"
                  name="lossPostalCode"
                  value={formData.lossPostalCode}
                  onChange={handleChange}
                />
                {errors.lossPostalCode && <p>{errors.lossPostalCode}</p>}
              </Field>
            </Col>

            <Col md={6}>
              <Field>
                <Label>Claim Status</Label>
                <select
                  name="claimStatus"
                  value={formData.claimStatus}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                >
                  <option value={1}>Open</option>
                  <option value={0}>Closed</option>
                </select>
                {errors.claimStatus && <p>{errors.claimStatus}</p>}
              </Field>
            </Col>

            <Col md={6}>
              <Field>
                <label htmlFor="examiner">Select Examiner:</label>
                <select
                  name="examinerId"
                  value={formData.examinerId} // This should be set from formData
                  onChange={handleExaminerChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                >
                  <option value="">Select Examiner</option>
                  {examiners.map((examiner) => (
                    <option key={examiner._id} value={examiner._id}>
                      {examiner.name}
                    </option>
                  ))}
                </select>

                {errors.examinerId && <p>{errors.examinerId}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <label className="mt-2" htmlFor="lossType">
                Select Loss Type:
              </label>
              <select
                className="mt-1"
                name="typeOfLossId"
                value={formData.typeOfLossId} // This should be set from formData
                onChange={handleLossTypeChange}
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "6px",
                  outline: "none",
                }}
              >
                <option value="">Select Loss Type</option>
                {lossTypes.map((lossType) => (
                  <option key={lossType._id} value={lossType._id}>
                    {lossType.description}
                  </option>
                ))}
              </select>

              {errors.typeOfLossId && <p>{errors.typeOfLossId}</p>}
            </Col>
            <Col md={6}>
              <Field>
                <Label>Proscription Date</Label>
                <Input
                  type="date"
                  name="proscriptionDate"
                  value={formData.proscriptionDate}
                  onChange={handleChange}
                />
                {errors.proscriptionDate && <p>{errors.proscriptionDate}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Select Adjuster:</Label>
                <select
                  name="adjusterId"
                  value={formData.adjusterId} // Use the form data value here
                  onChange={handleAdjusterChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                >
                  <option value="">Select Adjuster</option>
                  {adjusters.map((adjuster) => (
                    <option key={adjuster._id} value={adjuster?._id}>
                      {adjuster?.name}
                    </option>
                  ))}
                </select>
                {errors.adjusterId && <p>{errors.adjusterId}</p>}
              </Field>
            </Col>
         
          </Row>
          <Row>
            <Col md={4}>
              <FillBtn className="w-100" type="submit" text="Submit" />
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default Details;
