import React from "react";
import styled from "styled-components";
import searchIcon from "../../assets/search.png";
import { Search } from "@mui/icons-material";

const Box = styled.div`
  display: flex;
  height: 40px;
  width: auto;
`;

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  padding: 10px;
`;

const SeacrchBtn = styled.span`
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
`;

const SearchBox = ({ query, setQuery, onSearch }) => {
  return (
    <>
      <Box className="d-flex justify-content-start align-items-center">
        <Input
          type="text"
          placeholder="Search..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Search onClick={() => onSearch()} />
      </Box>
    </>
  );
};

export default SearchBox;
