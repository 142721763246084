// import React from 'react';
// import styled ,{css} from 'styled-components';
// import file from '../../assets/file.png';
// import Layout from '../../components/Layout/Layout';

// const Container = styled.div`
//   display: flex;
//   flex-direction:column;

// `;
// const InnerContainer = styled.div`
//   display: flex;
//   background-color: white;
//   border-radius:8px;
//   flex-direction:column;
// `;

// const HeaderContainer= styled.div`
//   height: 5vh;
//   padding:10px;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
// `;

// const FoldersContainer = styled.div`
//   width:auto;
//   height:auto;
//   margin:15px;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: flex-start; /* Align icons to the start of the container */
//   gap: 20px; /* Adjust the gap between icons */
// `;

// const Icon = styled.div`
// background: #F8F7FC;
// display: flex;
// align-items: center;
// justify-content: center;
// width: calc(33.33% - 20px);
// height: 56px; /* Fixed height */
// border-radius: 6px;
// gap: 16px;
// ${({ newIcon }) =>
//     newIcon &&
//     css`
//       background: transparent;
//       outline: 2px dotted grey; /* Change outline to dotted */
//     `}
// @media (max-width: 768px) {
//   width: calc(100% - 20px);
// }
// `;
// const SubText = styled.div`
//   width: 187px;
//   height: 22px;
//   opacity: 0.5px;
//   color: #000000D9;
//   font-family: Roboto;
//   font-size: 16px;
//   font-weight: 400;
//   line-height: 22px;
//   letter-spacing: 0em;
//   text-align: left;
// `;

// const Resources = () => {

//     return (
//       <Layout title="Resources - User">
//       <Container>
//         <div style={{margin:'40px ', fontSize:'40px'}}>Resources</div>
//         <InnerContainer>
//         <HeaderContainer>
//         <h4>Folders</h4>
//         </HeaderContainer>
//         <FoldersContainer>
//         <Icon newIcon={true}><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
//         <Icon><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
//         <Icon><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
//         <Icon><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
//         <Icon><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
//         </FoldersContainer>
//         <HeaderContainer>
//         <h4>Documents</h4>
//         </HeaderContainer>
//         <FoldersContainer>
//         <Icon><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
//         <Icon><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
//         <Icon><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
//         <Icon><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
//         </FoldersContainer>
//         </InnerContainer>
//       </Container>
//       </Layout>
//     );
//   };

//   export default Resources;
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Layout from "../../components/Layout/Layout";

import { useAuth } from "../../context/auth";

import UserTemplateList from "./UserResourceTemplateList";

const Container = styled.div`
  display: flex;
  background: white;
  padding: 20px;
  border-radius: 8px;
  flex-direction: column;
  flex-grow: 1;
`;
const MainContainer = styled.main`
  background: #eeeeee;
  flex-grow: 1;
  padding: 20px;
  border: 3px solid red;
`;
const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Resources = () => {
  const [auth] = useAuth();
  const [locationId] = useState("");
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(`/api/v1/templates/${locationId}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setTemplates(response.data);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    fetchTemplates();
  }, [auth, locationId]);

  return (
    <Layout title="Resources - User">
      <Container>
        <HeaderContainer>
          <div
            style={{ fontSize: "25px", display: "flex", alignItems: "center" }}
          >
            Resources
          </div>
        </HeaderContainer>
        <UserTemplateList templates={templates} setTemplates={setTemplates} />
      </Container>
    </Layout>
  );
};

export default Resources;
