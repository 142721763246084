import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth";
import { Col, Row, Input as ReactstrapInput } from "reactstrap";
import Button from "../common/Button";
import * as Yup from "yup";

const BtnContainer = styled.div`
  margin: 30px;
  display: flex;
  justify-content: space-between;
`;

const Field = styled.div`
  margin: 30px 10px 10px 30px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding: 10px;
  align-self: flex-start;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

const claimValidationSchema = Yup.object().shape({
  clients: Yup.array()
    .of(
      Yup.object().shape({
        client: Yup.string().required("Client ID is required"),
        claimNumber: Yup.string().required("Claim number is required for each client"),
      })
    )
    .required("At least one client is required"),
  fileNumber: Yup.string(),
  dateOfLoss: Yup.date(),
  lossAddress: Yup.string(),
  lossCity: Yup.string(),
  lossProvince: Yup.string(),
  lossPostalCode: Yup.string(),
  claimStatus: Yup.string(),
  examinerId: Yup.string(),
});

const UpdateClaimsForm = ({ title, claim }) => {
  const [auth] = useAuth();
  const [errors, setErrors] = useState({});
  const [clients, setClients] = useState([]);
  const [formData, setFormData] = useState({
    fileNumber: claim?.fileNumber || "",
    clients: claim?.clients.map((c) => ({
      client: c?.client?._id || "",
      claimNumber: c?.claimNumber || "",
    })) || [],
    dateOfLoss: claim?.dateOfLoss?.substr(0, 10) || "",
    lossAddress: claim?.lossAddress || "",
    lossCity: claim?.lossCity || "",
    lossProvince: claim?.lossProvince || "",
    lossPostalCode: claim?.lossPostalCode || "",
    claimStatus: claim?.claimStatus || "",
    examinerId: claim?.examinerId?._id || "",
  });

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const res = await axios.get("/api/v1/clients", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        if (res.data.success) {
          setClients(res.data.clients);
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
        toast.error("Failed to load clients.");
      }
    };
    fetchClients();
  }, [auth?.token]);

  const handleClientClaimNumberChange = (index, field, value) => {
    setFormData((prevData) => {
      const updatedClients = [...prevData.clients];
      updatedClients[index][field] = value;
      return { ...prevData, clients: updatedClients };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      // Validate the data using Yup schema
      await claimValidationSchema.validate(formData, { abortEarly: false });

      const { data } = await axios.put(
        `/api/v1/claims/${claim?._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        toast.success("Claim updated successfully.");
        window.location.reload();
      }
    } catch (error) {
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
        toast.error("Validation errors. Please fix and resubmit.");
      } else {
        console.error("Error updating claim:", error);
        toast.error("Failed to update the claim.");
      }
    }
  };

  return (
    <Row>
      <h1>{title}</h1>
      <Col md={12}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Row className="d-flex align-items-center">
            <Col md={6}>
              <Field>
                <Label>File Number</Label>
                <Input
                  type="text"
                  name="fileNumber"
                  value={formData.fileNumber}
                  onChange={handleChange}
                />
                {errors.fileNumber && <p>{errors.fileNumber}</p>}
              </Field>
            </Col>

            {formData.clients.map((client, index) => (
              <React.Fragment key={index}>
                <Col md={6}>
                  <Field>
                    <Label>Client</Label>
                    <select
                      name={`clients[${index}].client`}
                      value={client.client}
                      onChange={(e) =>
                        handleClientClaimNumberChange(index, "client", e.target.value)
                      }
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "6px",
                      }}
                    >
                      <option value="">Select Client</option>
                      {clients.map((c) => (
                        <option key={c._id} value={c._id}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                    {errors[`clients[${index}].client`] && (
                      <p>{errors[`clients[${index}].client`]}</p>
                    )}
                  </Field>
                </Col>

                <Col md={6}>
                  <Field>
                    <Label>Claim Number</Label>
                    <Input
                      type="text"
                      name={`clients[${index}].claimNumber`}
                      value={client.claimNumber}
                      onChange={(e) =>
                        handleClientClaimNumberChange(index, "claimNumber", e.target.value)
                      }
                    />
                    {errors[`clients[${index}].claimNumber`] && (
                      <p>{errors[`clients[${index}].claimNumber`]}</p>
                    )}
                  </Field>
                </Col>
              </React.Fragment>
            ))}

            <Col md={6}>
              <Field>
                <Label>Date of Loss</Label>
                <Input
                  type="date"
                  name="dateOfLoss"
                  value={formData.dateOfLoss}
                  onChange={handleChange}
                />
                {errors.dateOfLoss && <p>{errors.dateOfLoss}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Loss Address</Label>
                <Input
                  type="text"
                  name="lossAddress"
                  value={formData.lossAddress}
                  onChange={handleChange}
                />
                {errors.lossAddress && <p>{errors.lossAddress}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Loss City</Label>
                <Input
                  type="text"
                  name="lossCity"
                  value={formData.lossCity}
                  onChange={handleChange}
                />
                {errors.lossCity && <p>{errors.lossCity}</p>}
              </Field>
            </Col>

            <BtnContainer>
              <Button type="button" onClick={handleSubmit}>
                Submit
              </Button>
            </BtnContainer>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default UpdateClaimsForm;
