import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Details from "../../components/clients/details";
import Contacts from "../../components/clients/contacts/Contacts";
import Documents from "../../components/clients/documents";
import Automation from "../../components/clients/automation";
import hamburger from "../../assets/hamburger.png";
import Layout from "../../components/Layout/Layout";
import ClientForm from "../../components/clients/ClientForm";
import toast from "react-hot-toast";
import axios from "axios";
import { Modal } from "antd";
import List from "../../components/common/List";
import SearchBox from "../../components/common/SearchBox";
import Button from "../../components/common/Button";
import { useAuth } from "../../context/auth";

const MainContainer = styled.div`
  display: flex;
  min-height: 100vh;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  width: 250px;
  padding: 20px;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: url(${hamburger});
  width: 44px;
  height: 44px;
  margin: 20px;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const DetailsContainer = styled.div`
  padding: 20px;
  margin: 20px;
  flex-grow: 1;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 20px 0px;
`;

const TitlesContainer = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Links = styled.a`
  margin: 0px 40px 10px 0px;
  padding-bottom: 20px;
  &:hover {
    border-bottom: 1px solid #6495ed;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const MenuContainer = styled.div`
  flex-direction: column;
  display: flex;
  @media only screen and (max-width: 768px) {
    display: ${(props) => (props.open ? "block" : "none")};
  }
`;

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [auth] = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: {
      addressLine: "",
      city: "",
      province: "",
      postalCode: "",
    },
    type_of_client: "",
    rate: 0.0,
    mileage_rate: 0.0,
    cityAuto: 0.0,
    // Add other fields as needed
  });

  const [selectedLink, setSelectedLink] = useState("details");
  const [isOpen, setIsOpen] = useState(false); // State to control menu visibility
  const hasAccessToAutomation =
    auth?.user?.role_id?.name?.toLowerCase() === "manager" ||
    auth?.user?.role_id?.name?.toLowerCase() === "office admin" ||
    auth?.user?.role_id?.name?.toLowerCase() === "supervisor";

  const canUpdate = ["office admin", "manager", "supervisor"];

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle menu state
  };

  //get 5 recently added clients
  const getClients = async () => {
    try {
      const { data } = await axios.get("/api/v1/clients", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (data.success) {
        setClients(data.clients);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleDelete = async (clientId) => {
    try {
      const { data } = await axios.delete(`/api/v1/clients/${clientId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (data.success) {
        setClients(clients.filter((client) => client._id !== clientId));
        setSelectedClient(null);
        toast.success("Client Deleted Sucessfully");
      }
    } catch (error) {
      console.error("Error deleting client:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSearch = async () => {
    try {
      const { data } = await axios.get(`/api/v1/search/${query}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      if (data.success && data.clients) {
        setClients(data.clients);
        toast.success(data.message);
      } else {
        setClients([]);
        toast.error(data.message || "No clients or contacts found.");
      }
    } catch (error) {
      console.error("Error searching clients and contacts:", error);
      setClients([]);
      toast.error(
        error?.response?.data?.message ||
          "An error occurred while searching for clients."
      );
    }
  };

  const handleSubmit = async (e) => {
    const url = `/api/v1/clients`;
    e.preventDefault();

    try {
      const res = await axios.post(
        url,
        {
          formData,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (res && res.data.success) {
        console.log(res.data.client);
        setClients([res.data.client, ...clients]);
        setFormData({
          name: "",
          email: "",
          address: {
            addressLine: "",
            city: "",
            province: "",
            postalCode: "",
          },
          type_of_client: "",
          rate: 0.0,
          mileage_rate: 0.0,
          cityAuto: 0.0,
          // Add other fields as needed
        });

        toast.success(res.data && res.data.message);
      } else {
        toast.error(JSON.stringify(res.data.message));
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  //update a contact
  const handleUpdate = async (e) => {
    console.log(formData);
    const url = `/api/v1/clients/${selectedClient._id}`;
    e.preventDefault();
    try {
      const res = await axios.put(
        url,
        {
          formData,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (res && res.data.success) {
        const updatedClient = res.data.client;
        const updatedClients = clients.filter(
          (client) => client._id !== updatedClient._id
        );
        setClients([updatedClient, ...updatedClients]);

        setIsModalOpen(false);

        toast.success(res.data && res.data.message);
      } else {
        toast.error(JSON.stringify(res.data.message));
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const handleSelectClick = (client) => {
    setSelectedClient(client);
    setSelectedLink("details");
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this client?",
      content: "This action cannot be undone.",
      onOk: () => handleDelete(id),
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleUpdateClick = () => {
    setFormData(selectedClient);
    setIsModalOpen(true);
  };

  const handleAddClick = () => {
    setSelectedClient(null);
    setFormData({
      name: "",
      email: "",
      address: {
        addressLine: "",
        city: "",
        province: "",
        postalCode: "",
      },
      type_of_client: "",
      rate: 0.0,
      mileage_rate: 0.0,
      cityAuto: 0.0,
      // Add other fields as needed
    });
  };

  return (
    <Layout title="Clients - User">
      {loading ? (
        <>Loading....</>
      ) : (
        <>
          <MainContainer>
            <LeftContainer>
              <div
                style={{
                  display: "flex",
                  padding: "20px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "30px",
                  }}
                >
                  Clients <MenuButton onClick={toggleMenu} />
                </div>
                {canUpdate.includes(
                  auth?.user?.role_id?.name?.toLowerCase()
                ) && (
                  <Button
                    onClick={() => {
                      handleAddClick();
                    }}
                  >
                    Add{" "}
                  </Button>
                )}
              </div>
              <MenuContainer open={isOpen}>
                <SearchBox
                  query={query}
                  setQuery={setQuery}
                  onSearch={handleSearch}
                />
                <List
                  loading={loading}
                  items={clients}
                  renderItem={(client) => (
                    <div>
                      <h3>{client.name}</h3>
                      <p>{client.type_of_client}</p>
                      <div>
                        {client.contacts && client.contacts.length > 0 && (
                          <ul>
                            {client.contacts.map((contact) => (
                              <li key={contact._id}>
                                {contact.name} - {contact.email}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
                  onClick={handleSelectClick}
                />
              </MenuContainer>
            </LeftContainer>
            <RightContainer>
              {selectedClient ? (
                <DetailsContainer>
                  <TopBar>
                    
                      <h3 style={{ margin: "0" }}>{selectedClient.name}</h3>

                    
                    <div style={{ display: "flex" }}>
                      {canUpdate.includes(
                        auth?.user?.role_id?.name?.toLowerCase()
                      ) && (
                        <>
                          <Button onClick={() => handleUpdateClick()}>
                            Update
                          </Button>
                          <Button
                            style={{
                              backgroundColor: "red",
                              marginLeft: "10px",
                            }}
                            onClick={() => confirmDelete(selectedClient._id)}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                    </div>

                    <Modal
                      open={isModalOpen}
                      onCancel={() => setIsModalOpen(false)}
                      footer={null}
                    >
                      <ClientForm
                        title={`Update Client`}
                        handleSubmit={handleUpdate}
                        formData={formData}
                        setFormData={setFormData}
                      />
                    </Modal>
                  </TopBar>
                  {selectedClient.type_of_client}
                  <br />
                  <TitlesContainer>
                    <Links onClick={() => setSelectedLink("details")}>
                      Details
                    </Links>
                    <Links onClick={() => setSelectedLink("contacts")}>
                      Contacts
                    </Links>
                    <Links onClick={() => setSelectedLink("documents")}>
                      Documents
                    </Links>
                    {hasAccessToAutomation && (
                      <Links onClick={() => setSelectedLink("automation")}>
                        Automation
                      </Links>
                    )}
                  </TitlesContainer>
                  <FormContainer>
                    {selectedLink === "details" && (
                      <Details client={selectedClient} />
                    )}
                    {selectedLink === "contacts" && (
                      <Contacts client={selectedClient} />
                    )}
                    {selectedLink === "documents" && (
                      <Documents client={selectedClient} />
                    )}
                    {selectedLink === "automation" && hasAccessToAutomation && (
                      <Automation client={selectedClient} />
                    )}
                  </FormContainer>
                </DetailsContainer>
              ) : (
                <ClientForm
                  handleSubmit={handleSubmit}
                  formData={formData}
                  setFormData={setFormData}
                  title={"Create New Client"}
                />
              )}
            </RightContainer>
          </MainContainer>
        </>
      )}
    </Layout>
  );
};

export default Clients;
