import React from 'react';
import styled ,{css} from 'styled-components';
import file from '../../assets/file.png';


const Container = styled.div`
  display: flex;
  width:100%;
  flex-direction:column;
`;


const HeaderContainer= styled.div`
  height: 5vh;
  padding:10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Btn = styled.button`
  background-color: #6495ED;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left:20px;
`;



const SearchBox = styled.div`
  display: flex;
  height:40px;
  width:auto;
`;

const Input = styled.input`
  width: 180px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  padding: 10px;
`;

const FoldersContainer = styled.div`
  width:auto;
  height:auto;
  margin:15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align icons to the start of the container */
  gap: 20px; /* Adjust the gap between icons */
`;

const Icon = styled.div`
background: #F8F7FC;
display: flex;
align-items: center;
justify-content: center;
width: calc(33.33% - 20px); 
height: 56px; /* Fixed height */
border-radius: 6px;
gap: 16px;
${({ newIcon }) =>
    newIcon &&
    css`
      background: transparent;
      outline: 3px dotted grey; /* Change outline to dotted */
    `}
@media (max-width: 768px) {
  width: calc(100% - 20px); 
}
`;
const SubText = styled.div`
  width: 187px;
  height: 22px;
  opacity: 0.5px;
  color: #000000D9;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

const Documents = () => {
  
    return (
      
      <Container>
        <HeaderContainer>
        <div><SearchBox><Input type="text" placeholder="Search" /></SearchBox></div>
          <div>
            <Btn>Upload</Btn>
          </div>
        </HeaderContainer>
        <FoldersContainer>
        <Icon><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
        <Icon><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
        <Icon><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
        <Icon><img src={file} style={{ width: '18px', height: '16px' }} alt='storagefile'/><SubText>New Employee</SubText></Icon>
        </FoldersContainer>
      </Container>
    );
  };
  
  export default Documents;