import React, { useEffect, useState } from "react";
import styled from "styled-components";
import sorter from "../../assets/sorter.png";
import { useAuth } from "../../context/auth";
import axios from "axios";
import Modal from "../common/Modal";
import toast from "react-hot-toast";
import AddNoteForm from "./AddNoteForm";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LeftButtonContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const RightButtonContainer = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
`;

const Btn = styled.button`
  padding: 10px;
  background: #6495ed;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
  color: #ffffff;
  align-items: center;
  border: none;
  cursor: pointer;
  &:hover {
    background: #e0e4f3;
  } 
  '}`;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 15px;
  position: relative;
  text-align: left;
  background: #e0e4f3;
  color: #444444;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  letter-spacing: 0.20000000298023224px;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
  text-align: left;
  padding: 15px; /* Padding for consistent spacing */
  color: #9fa2b4;
  vertical-align: top; /* Align content at the top of the cell */
  white-space: nowrap; /* Prevent text wrapping in the cell */
`;

const CellContent = styled.div`
  max-height: 150px; /* Restrict the content height */
  overflow-y: auto; /* Allow vertical scrolling for overflowing content */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  text-overflow: ellipsis; /* Handle overflow text */
  word-wrap: break-word; /* Break long words */
  white-space: normal; /* Allow text wrapping */

  /* Optional scrollbar styling */
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;




const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const RowsPerPageSelect = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Notes = ({ claimId }) => {
  const [notes, setNotes] = useState([]);
  const [auth, setAuth] = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalNotes, setTotalNotes] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({ description: "", userCode: "" });

  useEffect(() => {
    fetchNotes();
  }, []);

  useEffect(() => {
    // Recalculate total pages whenever notes or rowsPerPage change
    setTotalPages(Math.ceil(notes.length / rowsPerPage));
  }, [notes, rowsPerPage]);


  const fetchNotes = async () => {
    try {
      const { data } = await axios.get(`/api/v1/getNotes?claim_id=${claimId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
  
      if (data.success) {
        const sortedNotes = data.party.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setNotes(sortedNotes);
        setTotalNotes(data.totalNotes); // Assuming API provides total notes
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Error fetching notes.");
    }
  };
  
  useEffect(() => {
    setTotalPages(Math.ceil(totalNotes / rowsPerPage)); // Use totalNotes
  }, [totalNotes, rowsPerPage]);
  

  const handleAddNote = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `/api/v1/createNotes`,
        {
          ...formData,
          userCode: auth?.user?.user_code,
          claim_id: claimId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        fetchNotes();
        setFormData({ description: "", userCode: "" });
        setIsModalOpen(false);
        toast.success("Note added successfully.");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Error adding note.");
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page
  };

  const handlePageInputChange = (e) => {
    const page = Number(e.target.value);
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const displayedNotes = notes.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const downloadPDF = () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    let y = 10;
  
    doc.setFontSize(12);
    doc.text("Notes List", 10, y);
    y += 10;
  
    notes.forEach((note, index) => {
      const text = `Date: ${note.createdAt}\nCode: ${note.UserCode}\nDescription: ${note.description}`;
      const lines = doc.splitTextToSize(text, 180);
      lines.forEach((line) => {
        if (y > pageHeight - 10) {
          doc.addPage();
          y = 10;
        }
        doc.text(line, 10, y);
        y += 10;
      });
    });
  
    doc.save("notes.pdf");
  };
  
  

  // Excel Download Function
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      notes.map((note) => ({
        UserCode: note.UserCode,
        Date: note.createdAt,
        Description: note.description,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Notes");
    XLSX.writeFile(workbook, "notes.xlsx");
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    return date.toLocaleString(); // Format as 'MM/DD/YYYY, HH:MM AM/PM'
  };

  return (
    <Container>
      <div>Notes</div>
      <HeaderContainer>
        <LeftButtonContainer>
          <Btn onClick={() => setIsModalOpen(true)}>Add</Btn>
        </LeftButtonContainer>
        <RightButtonContainer>
          <Btn
            onClick={() => {
              if (
                window.confirm("Are you sure you want to download the PDF?")
              ) {
                downloadPDF();
              }
            }}
          >
            Download PDF
          </Btn>

          <Btn
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to download the Excel file?"
                )
              ) {
                downloadExcel();
              }
            }}
          >
            Download Excel
          </Btn>
        </RightButtonContainer>
      </HeaderContainer>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>User Code</TableHeader>
              <TableHeader>Date</TableHeader>
              <TableHeader>Description</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {displayedNotes.map((note) => (
              <TableRow key={note._id}>
                <TableCell><CellContent>{note.UserCode}</CellContent></TableCell>
                <TableCell><CellContent>{formatDate(note.createdAt)}</CellContent></TableCell>
                <TableCell><CellContent>{note.description}</CellContent></TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <PaginationContainer>
        <div>
          Rows per page:
          <RowsPerPageSelect
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
          >
            {[10, 20, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </RowsPerPageSelect>
        </div>
        <div>
          <PageButton
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </PageButton>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <PageButton
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </PageButton>
        </div>
      </PaginationContainer>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddNoteForm
          formData={formData}
          setFormData={setFormData}
          handleSubmit={handleAddNote}
          title={"Add Note"}
        />
      </Modal>
    </Container>
  );
};

export default Notes;
